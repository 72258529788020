import React, { useEffect, useMemo, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { ChevronUp, Location } from "react-iconly";
import { RootStateOrAny, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import Bathroom from "../../../images/icons/bain.png";
import Bathroom2 from "../../../images/icons/bathtub.png";
import Bed2 from "../../../images/icons/bed.png";
import Kicthen from "../../../images/icons/eat.png";
import Kitchen2 from "../../../images/icons/kitchen.png";
import Bed from "../../../images/icons/lit.png";
import { ViewItem } from "../../../redux/ListPropertyToFront/listPropertyToFrontAction";
import { useCurrency } from "../../../redux/store";
import DatasListings from "../../../repositories/user/listings/DatasListings";
import { GoogleMap } from "../../admin/ListingAdmin/GoogleMap";
import FrontFooter from "../../frontFooter/FrontFooter";
import Header from "../../header/Header";
import { Gallery, useScrollToTop } from "../projets/DetailsProjet";
import PlayerVideo from "../shared/PlayerVideo";
import { Amineties } from "./AmnetieItem";
import FeaturedProperty from "./FeaturedProperty";
// import Review from "./Review";
import { displayPriceType } from "../../../Http/global-vars";
import { getPrice } from "../front-home/CardAppartItem";
import FormAddReview from "./FormAddReview";
import FormSendMessage from "./FormSendMessage";
import "./Listing.css";

const ListingItem: React.FC = () => {
  const currency = useCurrency();
  const location = useLocation();
  useScrollToTop();
  const params = useParams<any>();

  const [detailPropertiesDatas, setDetailPropertiesDatas] = useState<any>([]);

  const list_properties_front = useSelector(
    (state: RootStateOrAny) => state.list_properties_front
  );

  useEffect(() => {
    const item =
      list_properties_front.data?.length > 0
        ? list_properties_front.data.find((x: any) => x.slug === params.slug)
        : null;
    if (item) {
      setDetailPropertiesDatas(item);
    } else {
    }
  }, [list_properties_front, location.pathname]);

  const property_types_front = useSelector(
    (state: RootStateOrAny) => state.property_types_front
  );
  const res =
    property_types_front.data.length &&
    property_types_front?.data.find(
      (x: any) => x.id === detailPropertiesDatas?.property_type
    );

  React.useEffect(() => {
    if (detailPropertiesDatas?.id) {
      ViewItem.property(detailPropertiesDatas?.id);
    }
  }, [detailPropertiesDatas?.id]);

  const featuresPropertiesList = useMemo(
    () =>
      list_properties_front.data.length > 0 &&
      list_properties_front.data
        .filter(
          (x: any) =>
            x.id !== detailPropertiesDatas.id &&
            detailPropertiesDatas.id &&
            x.is_featured
        )
        .map((featureProperty: DatasListings, index) => (
          <FeaturedProperty
            property
            featureProperty={featureProperty}
            key={index}
          />
        )),
    [detailPropertiesDatas.id, list_properties_front.data, location.pathname]
  );

  const defaultProps = {
    center: {
      lat: parseFloat(detailPropertiesDatas?.latitude || 0),
      lng: parseFloat(detailPropertiesDatas?.longitude || 0),
    },
    zoom: 11,
  };

  const pictures =
    detailPropertiesDatas.pictures?.length > 0
      ? detailPropertiesDatas.pictures
      : [];
  return (
    <div className="component-front-home">
      <Header />
      <div className="p-t-150 m-b-100 container-page-about-home">
        <div className="container-taf">
          <div className="flex-sb align-items-md-center flex-column flex-md-row">
            <div className="flex-fill">
              <div className="mb-2 pt-md-4 pt-lg-0 d-md-flex justify-content-between">
                <h1 className="listing-title">
                  {detailPropertiesDatas?.property_name}
                  {res?.name ? ", " + res.name : ""}
                </h1>
                <h6 className="listing-cost text-red-100">
                  {getPrice(detailPropertiesDatas)}
                  {displayPriceType(detailPropertiesDatas?.type_price)}
                </h6>
              </div>
              <div className="d-flex flex-column flex-md-row justify-content-between">
                <div className="listings-details-container itm-center">
                  <div className="listing-detail-item">
                    <img src={Bed} alt="bed-icon" className="detail-item-img" />
                    <span className="listing-detail-text fs-14">
                      {`${detailPropertiesDatas?.bedroom ?? ""} beds`}
                    </span>
                  </div>
                  <div className="listing-detail-item">
                    <img
                      src={Kicthen}
                      alt="kitchen-icon"
                      className="detail-item-img"
                    />
                    <span className="listing-detail-text fs-14">
                      {`${detailPropertiesDatas?.kitchen ?? ""}`} kitchens
                    </span>
                  </div>
                  <div className="listing-detail-item">
                    <img
                      src={Bathroom}
                      alt="bathroom-icon"
                      className="detail-item-img"
                    />
                    <span className="listing-detail-text fs-14">
                      {`${detailPropertiesDatas?.bathroom ?? ""} bathroom`}
                    </span>
                  </div>
                </div>
                <div className="quice-container d-flex mt-2 mt-md-0">
                  <Location primaryColor="#A1ABC9" />
                  <h6 className="listing-detail-text fs-16">
                    {`${detailPropertiesDatas?.location_id?.name ?? ""}`},{" "}
                    {`${detailPropertiesDatas?.country_id?.title ?? ""}`}{" "}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          {/* Gallery Listing */}
          <Gallery
            pictures={pictures}
            image={detailPropertiesDatas?.image}
            name={detailPropertiesDatas?.property_name}
          />
          <div className="row mt-3">
            <div className="col-lg-7 pb-3">
              {/* Details && Features */}
              <div className="card-listing p-3">
                <h2 className="mid-listing-title">Detail & features</h2>
                <div className="det-feat-container mb-3">
                  <div className="det-feat-item">
                    <img src={Bed2} alt="bed" />
                    <h6 className="det-feat-title">{`${
                      detailPropertiesDatas?.bedroom ?? ""
                    } BEDROOM`}</h6>
                  </div>
                  <div className="det-feat-item">
                    <img src={Bathroom2} alt="bathroom-img" />
                    <h6 className="det-feat-title">{`${
                      detailPropertiesDatas.bathroom ?? ""
                    } BATHROOM`}</h6>
                  </div>
                  <div className="det-feat-item">
                    <img src={Kitchen2} alt="bathroom-img" />
                    <h6 className="det-feat-title">{`${
                      detailPropertiesDatas.kitchen ?? ""
                    } KITCHEN`}</h6>
                  </div>
                </div>
              </div>
              {/* Description */}
              <div className="card-listing mt-4 p-3">
                <h2 className="mid-listing-title">Description</h2>
                <div className="card-listing-body mt-3">
                  <div
                    className="card-listing-text"
                    dangerouslySetInnerHTML={{
                      __html: detailPropertiesDatas?.description,
                    }}
                  />
                </div>
              </div>
              {/* Amenities */}
              <div className="card-listing mt-4 p-3">
                <h2 className="mid-listing-title">Amenities</h2>
                <Amineties amneties={detailPropertiesDatas?.ameneties} />
              </div>
              {/* Propertie video */}
              {!!detailPropertiesDatas?.video_presentation && (
                <div className="card-listing mt-4 p-3">
                  <h2 className="mid-listing-title mb-3">Property video</h2>
                  <PlayerVideo
                    url={detailPropertiesDatas?.video_presentation}
                    showControls={false}
                  />
                </div>
              )}
              {/* Map */}
              <div className="card-listing mt-4 p-3">
                <h2 className="mid-listing-title mb-3">Map</h2>
                <GoogleMap
                  style={{
                    height: "55vh",
                    width: "100%",
                  }}
                  className="px-1"
                  defaultProps={defaultProps}
                />
              </div>
              {/* Virtual visit*/}
              {detailPropertiesDatas?.virtual_video && (
                <div className="card-listing mt-3 p-3">
                  <div className="flex-sb">
                    <h2 className="mid-listing-title mb-3">Virtual visit</h2>
                    <ChevronUp primaryColor="#200E32" />
                  </div>
                  <PlayerVideo
                    url={detailPropertiesDatas?.virtual_video}
                    showControls={false}
                  />
                </div>
              )}
              <FormAddReview
                type={"property"}
                id={detailPropertiesDatas.id && detailPropertiesDatas.id}
              />
            </div>
            <div className="col-lg-5">
              <FormSendMessage
                type={"property"}
                id={detailPropertiesDatas.id && detailPropertiesDatas.id}
              />
              {/* Featured properties */}
              <div className="featured-properties-container mt-5">
                <h2 className="mid-listing-title mb-4">Featured properties</h2>
                <div className="featured-properties">
                  {featuresPropertiesList}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default ListingItem;
