import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as yup from "yup";
import ImgBackground from "../../images/background.png";
import Header from "../header/Header";
import "./Login.css";
// import { useHistory } from "react-router";
import Swal from "sweetalert2";
import { fetchMe } from "../../redux/user/userActions";
import LoginFormData from "../../repositories/user/LoginFormData";
import AuthServices from "../../services/AuthServices";

const Login: React.FC = () => {
  const dispatch = useDispatch();

  const [isSuccessfullySubmittedForm, setIsSuccessfullySubmittedForm] =
    React.useState(false);

  // const [isFormRequestError, setIsFormRequestError] = React.useState(false);

  // const history = useHistory();

  const validationSchema = yup.object().shape({
    email: yup.string().email("Enter a valid email").required(),
    password: yup.string().required("Password is required."),
  });

  const { register, handleSubmit, reset, formState } = useForm<LoginFormData>({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });
  const { errors } = formState;

  const onSubmit = async (data: LoginFormData) => {
    setIsSuccessfullySubmittedForm(true);

    const request = AuthServices.onLogin(data);

    await request
      .then(async (resp) => {
        // console.log("resp", resp?.token);
        const config = {
          headers: {
            Authorization: `Bearer ${resp?.token}`,
          },
        };
        // console.log("resp", resp);
        const requestMe = AuthServices.onMe(config);
        await requestMe
          .then(async (res) => {
            // console.log("me", res?.id);
            if (res?.id && res?.user_type === "usager") {
              window.localStorage.setItem("userID", res?.id);
              window.localStorage.setItem("userType", res?.user_type);
              window.localStorage.setItem("userToken", resp?.token);
              await dispatch(fetchMe());
              await Swal.fire({
                icon: "success",
                title: "Login successfully!",
                iconColor: "#df5656",
                showCancelButton: false,
                confirmButtonColor: "#df5656",
                cancelButtonColor: "#d33",
                confirmButtonText: "OK",
                showConfirmButton: true,
                allowOutsideClick: false,

                // timer: 1500,
              }).then(() => {
                Swal.fire({
                  icon: "success",
                  iconColor: "#df5656",
                  showCancelButton: false,
                  confirmButtonColor: "#df5656",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "OK",
                  title: "No dashboard yet!",
                  showConfirmButton: true,
                  allowOutsideClick: false,
                  // timer: 1500,
                });
                reset();
                // window.location = "/dashboard/editer-mon-profile";
              });
            } else if (res?.id && res?.user_type === "admin") {
              // window.localStorage.setItem("userID", res?.id);
              window.localStorage.setItem("userType", res?.user_type);
              window.localStorage.setItem("userToken", resp?.token);
              await dispatch(fetchMe());
              await Swal.fire({
                icon: "success",
                iconColor: "#df5656",
                showCancelButton: false,
                confirmButtonColor: "#df5656",
                cancelButtonColor: "#d33",
                confirmButtonText: "OK",
                title: "Login successfully!",
                showConfirmButton: true,
                allowOutsideClick: false,

                // timer: 1500,
              }).then(() => {
                window.location.href = "/dashboard";
              });
            } else if (res?.id && res?.user_type === "owner") {
              window.localStorage.setItem("userID", res?.id);
              window.localStorage.setItem("userType", res?.user_type);
              window.localStorage.setItem("userToken", resp?.token);
              await dispatch(fetchMe());
              await Swal.fire({
                icon: "success",
                iconColor: "#df5656",
                showCancelButton: false,
                confirmButtonColor: "#df5656",
                cancelButtonColor: "#d33",
                confirmButtonText: "OK",
                title: "Login successfully!",
                showConfirmButton: true,
                allowOutsideClick: false,
                // timer: 1500,
              }).then(() => {
                Swal.fire({
                  icon: "success",
                  iconColor: "#df5656",
                  showCancelButton: false,
                  confirmButtonColor: "#df5656",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "OK",
                  title: "No dashboard yet!",
                  showConfirmButton: true,
                  allowOutsideClick: false,
                  // timer: 1500,
                });
                reset();
                // window.location = "/admin/dashboard";
              });
            } else if (res?.id && res?.user_type === "superadmin") {
              window.localStorage.setItem("userID", res?.id);
              window.localStorage.setItem("userType", res?.user_type);
              window.localStorage.setItem("userToken", resp?.token);
              await dispatch(fetchMe());
              await Swal.fire({
                icon: "success",
                iconColor: "#df5656",
                showCancelButton: false,
                confirmButtonColor: "#df5656",
                cancelButtonColor: "#d33",
                confirmButtonText: "OK",
                title: "Login successfully!",
                showConfirmButton: true,
                allowOutsideClick: false,
                // timer: 1500,
              }).then(() => {
                window.location.href = "/dashboard";
              });
            } else {
              Swal.fire({
                icon: "error",
                iconColor: "#e62a1d",
                showCancelButton: false,
                confirmButtonColor: "#e62a1d",
                cancelButtonColor: "#d33",
                confirmButtonText: "OK",
                title: "Connection not authorized!",
                showConfirmButton: true,
                allowOutsideClick: false,
                // timer: 3000,
              });
            }
            setIsSuccessfullySubmittedForm(false);
          })
          .catch((err) => {
            setIsSuccessfullySubmittedForm(false);
            // console.log("err", err, err?.response);
            if (err?.response === undefined) {
              Swal.fire({
                icon: "error",
                iconColor: "#df5656",
                showCancelButton: false,
                confirmButtonColor: "#df5656",
                cancelButtonColor: "#d33",
                confirmButtonText: "OK",
                title: "Connection not authorized! !",
                showConfirmButton: true,
                allowOutsideClick: false,
                // timer: 3000,
              });
            }
          });
      })
      .catch((error) => {
        // console.error("error", error, error?.response);
        if (error?.response?.data?.non_field_errors) {
          //   toast.error("Incorrect email or password.");
          Swal.fire({
            icon: "error",
            iconColor: "#df5656",
            showCancelButton: false,
            confirmButtonColor: "#df5656",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            title: "Incorrect email or password.",
            showConfirmButton: true,
            allowOutsideClick: false,
            // timer: 3000,
          });
        } else {
          Swal.fire({
            icon: "error",
            iconColor: "#df5656",
            showCancelButton: false,
            confirmButtonColor: "#df5656",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            title: "Please check your internet connection.",
            showConfirmButton: true,
            allowOutsideClick: false,
            // timer: 3000,
          });
        }
        setIsSuccessfullySubmittedForm(false);
      });
  };

  return (
    <div className="login-component container-login-component">
      <Header />
      <div className="p-t-100">
        <div className="login-component-container">
          <div className="row">
            <div className="col-xl-5 col-lg-5 col-md-5">
              <div>
                <img
                  src={ImgBackground}
                  className="w-full"
                  alt="background login"
                  style={{ height: "100vh" }}
                />
              </div>
            </div>
            <div className="col-xl-7 col-lg-7 col-md-7">
              <div className="card-connexion py-5">
                {/* <div className="p-b-20 text-right text-right-mobile">
                  <p className="label-input">
                    Don’t have an account ?{" "}
                    <NavLink to="/register" className="mdpOublie">
                      Sign up
                    </NavLink>
                  </p>
                </div> */}
                <p className="titre-connexion p-t-20">Sign in</p>

                <form id="login-form" onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group">
                    <label className="label-input">Mail adress</label>
                    <input
                      ref={register}
                      name="email"
                      id="email"
                      type="email"
                      className="form-control input-with-icon"
                      data-testid="emailId"
                    />
                  </div>
                  {errors.email && (
                    <div className="alert alert-danger sia-alert-danger closer">
                      {errors.email?.message}
                    </div>
                  )}
                  <div className="dis-flex justify-content-end m-t-20">
                    <Link to="/modifierpassword" className="mdpOublie">
                      Forgot your password?
                    </Link>
                  </div>
                  <div className="form-group">
                    <label className="label-input">Password</label>
                    <input
                      ref={register}
                      name="password"
                      id="password"
                      type="password"
                      className="form-control input-with-icon"
                      data-testid="passwordId"
                    />
                  </div>

                  {errors.password && (
                    <div className="alert alert-danger sia-alert-danger closer">
                      {errors.password?.message}
                    </div>
                  )}

                  {!isSuccessfullySubmittedForm && (
                    <div className="col-md-4 offset-md-4 py-3 px-0">
                      <button
                        type="submit"
                        className="btn-connexion"
                        data-testid="buttonId"
                      >
                        Sign in
                      </button>
                    </div>
                  )}

                  {isSuccessfullySubmittedForm && (
                    <div className="col-md-4 offset-md-4 py-3 px-0">
                      <button
                        type="submit"
                        className="btn-connexion"
                        data-testid="buttonId"
                      >
                        <i className="fas fa-spin fa-spinner"></i>&nbsp;&nbsp;In
                        Progress
                      </button>
                    </div>
                  )}
                </form>
                {/* <SingUpGoogle loginPage={true} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
