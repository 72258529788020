import Axios from "axios";
import api from "../../Http/global-vars";
import ProjetDatas from "../../repositories/user/projets/DatasProjets";
import { FETCH_PROJECTS, UPDATE_PROJECT } from "../types";

export const fetchProjects = () => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const url = api + "project/";
    await fetch(url, config)
      .then(async (res) => {
        const data = await res.json();
        // console.log("data categories", data)
        dispatch({
          type: FETCH_PROJECTS,
          payload: data.results,
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};

export const updateProject = (id: ProjetDatas) => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const token = window.localStorage.getItem("userToken");
    const url = api + "project/" + id;
    if (token) {
      await fetch(url, config)
        .then(async (res) => {
          const data = await res.json();
          // console.log("data results idididid", data)
          dispatch({
            type: UPDATE_PROJECT,
            payload: data,
            // ,
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  };
};

export const listProjectsDataToTest = async () => {
  try {
    return await Axios.get(`${api}project`);
  } catch (e) {
    return [];
  }
};

export const listProjectsDataFrontToTest = async () => {
  try {
    return await Axios.get(`${api}projectbyvisitor`);
  } catch (e) {
    return [];
  }
};
