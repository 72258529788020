import api from "../../Http/global-vars";
import { FETCH_PROPERTIES_TYPE_TO_FRONT } from "../types";

export const listPropertiesToFront = () => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    const url = api + "listingbyvisitor/";
    await fetch(url)
      .then(async (res) => {
        const data = await res.json();
        dispatch({
          type: FETCH_PROPERTIES_TYPE_TO_FRONT,
          payload: data.results.filter(
            (x: any) => x.statut_publication === "publier"
          ),
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};

export const listPropertiesToFrontGET = (data: any[]) => {
  return async (dispatch: any) => {
    dispatch({
      type: FETCH_PROPERTIES_TYPE_TO_FRONT,
      payload: data,
    });
  };
};

export const ViewItem = {
  async property(id: number) {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const url = api + `increasevulisting/${id}/`;
    fetch(url, config)
      .then(async (res) => {
        console.log({ ViewedProperty: id });
      })
      .catch((error) => console.log({ ViewedErrorProperty: id }));
  },
  async project(id: number) {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const url = api + `increasevuproject/${id}/`;
    fetch(url, config)
      .then(async (res) => {
        console.log({ ViewedProject: id });
      })
      .catch((error) => console.log({ ViewedErrorProject: id }));
  },
};
