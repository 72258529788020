import { yupResolver } from "@hookform/resolvers/yup";
import Axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import * as yup from "yup";
import Lazy from "yup/lib/Lazy";
import api, { recaptcha } from "../../../Http/global-vars";
import { listReservations } from "../../../redux/Reservations/reservationAction";
import SendMessageData from "../../../repositories/user/listing/SendMessageData";
import { useSuccessMessage } from "../contact/Contact";

interface Props {
  type: string;
  id: number;
}

const FormSendMessage: React.FC<Props> = ({ type, id }) => {
  const [isSuccessfullySubmittedForm, setIsSuccessfullySubmittedForm] =
    React.useState(false);
  const [isValid, setIsValid] = React.useState<boolean>(false);

  const dispatch = useDispatch();

  // const history = useHistory();
  const phoneRegExp =
    /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
  let validationSchema: yup.AnyObjectSchema | Lazy<any, any>;
  if (type === "property") {
    validationSchema = yup.object().shape({
      email: yup.string().email("Enter a valid email").required(),
      first_name: yup.string().required("This field is required"),
      last_name: yup.string().required("This field is required"),
      date_souhaite: yup.string().required("This field is required"),
      phone: yup
        .string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("This Field is required"),
    });
  } else {
    validationSchema = yup.object().shape({
      email: yup.string().email("Enter a valid email").required(),
      first_name: yup.string().required("This field is required"),
      last_name: yup.string().required("This field is required"),
      phone: yup
        .string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("This Field is required"),
    });
  }

  const { register, handleSubmit, reset, formState, setError } =
    useForm<SendMessageData>({
      mode: "onBlur",
      resolver: yupResolver(validationSchema),
    });

  const { errors } = formState;

  const onChange = (value) => {
    if (value !== undefined || value !== null) {
      console.log(value);
      setIsValid(true);
      setError("recaptcha", { message: "" });
    }
  };

  const onSubmitFormInterets = async (data: SendMessageData) => {
    if (!isValid) {
      return setError("recaptcha", { message: "This Field is required" });
    }
    setIsSuccessfullySubmittedForm(true);
    if (type === "project") {
      data["project_id"] = id;
      data["date_souhaite"] = moment().format("YYYY-MM-DD");
    }
    if (type === "property") {
      data["property_id"] = id;
    }
    const request = Axios.post(`${api}interet/${id}/`, data);
    await request
      .then(async (resp) => {
        console.log("resp", resp);
        Swal.fire({
          icon: "success",
          title: "Success",
          html: "Request sent successfully!",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            reset();
          }
        });
        dispatch(listReservations());
        setIsSuccessfullySubmittedForm(false);
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        setIsSuccessfullySubmittedForm(false);
      });
  };

  return (
    <div className="form-add-comment-container card-listing py-3 mt-4 mt-md-0">
      <form
        className="px-sm-4 pt-4"
        onSubmit={handleSubmit(onSubmitFormInterets)}
      >
        <div className="form-group-container row mb-3">
          <div className="form-group col-12">
            <label className="form-custom-label">Last name</label>
            <input
              type="text"
              className="form-custom-control px-3"
              placeholder="Your last name"
              name="last_name"
              data-testid="lastNameId"
              id="last_name"
              ref={register}
            />
            <small className="text-danger">{errors.last_name?.message}</small>
          </div>
        </div>
        <div className="form-group-container row mb-3">
          <div className="form-group col-12">
            <label className="form-custom-label">First name</label>
            <input
              type="text"
              className="form-custom-control px-3"
              placeholder="Your first name"
              name="first_name"
              data-testid="firstNameId"
              id="first_name"
              ref={register}
            />
            <small className="text-danger">{errors.first_name?.message}</small>
          </div>
        </div>
        <div className="form-group-container row mb-3">
          <div className="form-group col-12">
            <label className="form-custom-label">Email</label>
            <input
              type="email"
              className="form-custom-control px-3"
              placeholder="Your Email"
              data-testid="emailId"
              name="email"
              id="email"
              ref={register}
            />
            <small className="text-danger">{errors.email?.message}</small>
          </div>
        </div>
        <div className="form-group-container row mb-3">
          <div className="form-group col-12">
            <label className="form-custom-label">Phone No.</label>
            <input
              type="tel"
              className="form-custom-control px-3"
              placeholder="Your phone"
              name="phone"
              data-testid="phoneId"
              id="phone"
              ref={register}
            />
            <small className="text-danger">{errors.phone?.message}</small>
          </div>
        </div>
        {type === "property" && (
          <div className="form-group-container row mb-3">
            <div className="form-group col-12">
              <label className="form-custom-label">Desired date</label>
              <input
                type="date"
                className="form-custom-control px-3"
                name="date_souhaite"
                data-testid="dateId"
                id="date_souhaite"
                ref={register}
              />
              <small className="text-danger">
                {errors.date_souhaite?.message}
              </small>
            </div>
          </div>
        )}

        <div className="form-textarea-group-container row mb-3">
          <div className="form-group col-12">
            <label htmlFor="message" className="form-custom-label">
              Message
            </label>
            <textarea
              name="message"
              id="message"
              className="form-custom-control-textarea px-3 pt-3"
              placeholder="I’m interested in this property"
              ref={register}
            />
            <small className="text-danger">{errors.message?.message}</small>
          </div>
        </div>
        <ReCAPTCHA
          sitekey={recaptcha.key}
          hl={recaptcha.lang}
          onChange={onChange}
        />
        <small className="text-danger">{errors.recaptcha?.message}</small>
        <div className="d-flex justify-content-center mt-4 mb-3 px-2">
          {!isSuccessfullySubmittedForm ? (
            <button className="submit-comment d-block col-12 py-4">
              Send Interest
            </button>
          ) : (
            <span className="submit-comment d-block col-12 py-4">
              <i className="fa fa-spin fa-spinner"></i>&nbsp;&nbsp; In Progress
            </span>
          )}
        </div>
      </form>
    </div>
  );
};

export default FormSendMessage;

export const FormSendMessageSaleForce = ({ type, name }) => {
  useSuccessMessage("Interest send successfully!");
  const [valid, setValid] = useState(false);

  const inputs = [
    { label: "First Name", type: "text", name: "first_name", required: true },
    { label: "Last Name", type: "text", name: "last_name", required: true },
    { label: "Email", type: "email", name: "email", required: true },
    { label: "Phone No.", name: "phone", type: "phone", required: true },
    {
      label: "Message",
      name: "message",
      type: "textarea",
      required: true,
      placeholder: "I’m interested in this property",
    },
  ];
  if (type === "property") {
    inputs.splice(3, 0, {
      label: "Desired date",
      name: "date_souhaite",
      type: "date",
      required: true,
    });
  }

  let retUrl = window.location.href;
  if (retUrl.includes("?")) retUrl = `${retUrl}&status=success`;
  else retUrl = `${retUrl}?status=success`;

  return (
    <div className="form-add-comment-container card-listing py-3 mt-4 mt-md-0">
      <form
        method="POST"
        action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
        className="px-sm-4 pt-4"
      >
        <input
          type="hidden"
          name="captcha_settings"
          value='{"keyname":"TAF_V2_Key_Pair","fallback":"true","orgId":"00DDn000008jLoQ","ts":""}'
        />
        <input type="hidden" name="oid" value="00DDn000008jLoQ" />
        <input type="hidden" name="country" value="gambia" />
        <input type="hidden" name="retURL" value={retUrl} />
        <input id="lead_source" name="lead_source" type="hidden" value={name} />
        {/* <input type="hidden" name="debug" value={1} />
        <input type="hidden" name="debugEmail" value="test-taf@yopmail.com" /> */}
        {inputs.map((i) => {
          if (i.type === "textarea") {
            return (
              <div
                key={i.name}
                className="form-textarea-group-container row mb-3"
              >
                <div className="form-group col-12">
                  <label htmlFor={i.name} className="form-custom-label">
                    {i.label}
                  </label>
                  <textarea
                    name={i.name}
                    id={i.name}
                    required={i.required}
                    className="form-custom-control-textarea px-3 pt-3"
                    placeholder={i.placeholder ?? i.label}
                  />
                </div>
              </div>
            );
          }
          return (
            <div key={i.name} className="form-group-container row mb-3">
              <div className="form-group col-12">
                <label htmlFor={i.name} className="form-custom-label">
                  {i.label}
                </label>
                <input
                  type="text"
                  className="form-custom-control px-3"
                  placeholder={i.label}
                  name={i.name}
                  required={i.required}
                  id={i.name}
                />
              </div>
            </div>
          );
        })}

        <div className="form-textarea-group-container row mb-3">
          <div className="form-group col-12">
            <ReCAPTCHA
              sitekey={recaptcha.key}
              hl={recaptcha.lang}
              onChange={(value) => setValid(!!value)}
            />
            {!valid && (
              <small className="text-danger">
                Please validate the reCAPTCHA
              </small>
            )}
          </div>
        </div>

        <div className="d-flex justify-content-center mt-4 mb-3 px-2">
          <button
            type="submit"
            disabled={!valid}
            onClick={(e) => {
              if (!valid) {
                e.preventDefault();
              }
            }}
            className="submit-comment d-block col-12 py-4"
          >
            Send Interest
          </button>
        </div>
      </form>
    </div>
  );
};
