import api from "../../Http/global-vars";
import NewsletterData from "../../repositories/NewsletterData";
import { FETCH_NEWSLETTER_ADMIN } from "../types";

export const fetchNewsletterAdmin = () => {
  return async (
    dispatch: (arg0: { type: string; payload: NewsletterData }) => void
  ) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const token = window.localStorage.getItem("userToken");

    const url = api + "newsletters/";

    if (token) {
      await fetch(url, config)
        .then(async (res) => {
          const data = await res.json();
          dispatch({
            type: FETCH_NEWSLETTER_ADMIN,
            payload: data.results,
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  };
};
