// import Axios from "axios";
import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import api from "../../../Http/global-vars";
import CategoryInterface from "../../../repositories/user/blog/CategoryInterface";

interface Props {
  categories: CategoryInterface[];
}

const CategoriesPost: React.FC<Props> = ({}) => {
  const category_article = useSelector(
    (state: RootStateOrAny) => state.category_article
  );

  return (
    <div className="categories-posts-container">
      <div className="categories-lists-container card-blog p-3">
        <h2 className="category-title">Categories</h2>
        <ul className="list-group list-group-flush list-categories mt-4">
          {category_article.data.map((category, index) => {
            return (
			<li className="list-group-item" key={index}>
				<Link
					style={{ color: "inherit", cursor: "pointer" }}
					to={`/blog/category/${category.name}`}
				>
					<span>{category.name}</span>
				</Link>
			</li>
		);
          })}
        </ul>
      </div>
    </div>
  );
};

export default CategoriesPost;
