import _ from "lodash";
import React, { useState } from "react";
import { Location, Search, Show } from "react-iconly";
import { RootStateOrAny, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { RangeSlider } from "rsuite";
import baseUrl from "../../../Http/backend-base-url";
import { displayPriceType } from "../../../Http/global-vars";
import PaginationComponent from "../../../modules/Pagination/Pagination";
import { RootState, useCurrency } from "../../../redux/store";
import ProjetDatas from "../../../repositories/user/projets/DatasProjets";
import { getNumberSelectOptions } from "../../../services/Endpoints";
import { formatAmout } from "../../../utils/convert_currency";
import FrontFooter from "../../frontFooter/FrontFooter";
import Header from "../../header/Header";
import { getPrice } from "../front-home/CardAppartItem";
import { useScrollToTop } from "../projets/DetailsProjet";
import "./Listing.css";

const Listing: React.FC = () => {
  const { state } = useLocation<any>();
  const currency = useCurrency();
  const {
    location: locationData,
    max,
    min,
    propertyType,
    typeObj,
  } = state || {};

  const [per_page] = useState(10);
  useScrollToTop();
  const [search, setSearch] = React.useState("");

  const [selectedVilles, setSelectedVilles] = React.useState<string[]>(
    locationData ? [locationData] : []
  );
  const [minMax, setMinMax] = React.useState({ min: 0, max: 50000 });
  const [prices, setPrices] = React.useState([
    min || minMax.min,
    max || minMax.max,
  ]);
  const [rooms] = React.useState(getNumberSelectOptions());
  const [selectedRooms, setSelectedRooms] = React.useState<string[]>(
    typeObj ? [typeObj] : []
  );
  const [selectedAmineties, setSelectedAmineties] = React.useState<any[]>([]);

  const [types, setTypes] = React.useState<any[]>(
    propertyType ? [propertyType] : []
  );
  const [current_page, setCurrent_page] = useState<any>(1);
  const [total_data, setTotal_data] = useState(0);
  const [currentList, setCurrentList] = useState<ProjetDatas[]>([]);
  const [filtred, setFiltred] = useState<ProjetDatas[]>([]);
  const [lastIndex, setLastIndex] = useState<any>(0);

  React.useEffect(() => {
    setMinMax({
      min: 0,
      max: max && max > minMax.max ? max : minMax.max,
    });
  }, []);
  const list_properties_front = useSelector(
    (state: RootState) => state.list_properties_front
  );
  const locations_front = useSelector(
    (state: RootStateOrAny) => state.locations_front
  );
  const amineties = useSelector((state: RootState) => state.amenetiesToFront);
  const property_types_front = useSelector(
    (state: RootState) => state.property_types_front
  );

  React.useEffect(() => {
    const prices = list_properties_front.data.map((r) =>
      parseInt(r?.newPrice || r.price, 10)
    );
    const min2 = _.min(prices) || minMax.min;
    const max2 = _.max(prices) || minMax.max;
    setPrices([min || min2, max || max2]);
    setMinMax({ min: 0, max: max2 > (max || 0) ? max2 : max });
  }, [list_properties_front.data, min, max]);

  const formatData = (Array: ProjetDatas[], cp?: number) => {
    setTotal_data(Array.length);
    const cup = cp || current_page;
    const indexOfLastPost = cup * per_page;
    const indexOfFirstPage = indexOfLastPost - per_page;

    const currentList = Array.slice(indexOfFirstPage, indexOfLastPost);
    setLastIndex(indexOfLastPost);
    setCurrentList(currentList);
  };
  React.useEffect(() => {
    formatData(filtred, current_page);
  }, [filtred, current_page]);

  const handleClick = (number: number) => {
    setCurrent_page(number);
  };

  React.useEffect(() => {
    const data = [...list_properties_front.data];

    const filtred = data.filter((f: any) => {
      if (
        search &&
        !f.property_name.toLowerCase().includes(search.toLowerCase())
      ) {
        return false;
      }
      if (
        selectedVilles.length > 0 &&
        !selectedVilles.includes(f.location_id?.name)
      ) {
        return false;
      }
      if (selectedRooms.length > 0) {
        let ok = false;
        for (const r of selectedRooms) {
          ok = String(f.bedroom) === String(r);
          if (ok) break;
        }
        if (!ok) {
          return false;
        }
      }
      if (selectedAmineties.length > 0) {
        if (
          f.ameneties.findIndex(
            (f) => selectedAmineties.filter((f2) => f2.id === f).length > 0
          ) === -1
        ) {
          return false;
        }
      }
      if (types.length > 0) {
        if (!types.includes(f.property_type?.name)) {
          return false;
        }
      }
      if (
        !(
          parseInt(f.newPrice || f.price, 10) >= prices[0] &&
          parseInt(f.newPrice || f.price, 10) <= prices[1]
        )
      ) {
        return false;
      }
      return true;
    });
    handleClick(1);
    setFiltred(filtred);
  }, [
    search,
    selectedVilles,
    selectedRooms,
    selectedAmineties,
    types,
    prices,
    list_properties_front.data,
  ]);

  return (
    <div className="component-front-home">
      <Header />
      <div className="container-listing">
        <div className="text-center border-bottom pb-3">
          <h1 className="titre-page-content">Listings</h1>
          <p className="sous-titre-page-content">Property listings</p>
        </div>
        <div>
          <div className="container-taf pt-4">
            <div className="row">
              <div className="col-xl-4">
                <div className="bloc-collapse">
                  <div className="py-3">
                    <form>
                      <div className="col-sm-12 my-1">
                        <div className="input-group input-group-search">
                          <div className="input-group-prepend">
                            <div className="input-group-text input-group-text-search">
                              <Search set="light" primaryColor="#CCCCCC" />
                            </div>
                          </div>
                          <input
                            type="text"
                            value={search}
                            onChange={(e) => {
                              setSearch(e.currentTarget.value);
                            }}
                            className="form-control input-search-space-name"
                            id="inlineFormInputGroupUsername"
                            placeholder="Search by space name..."
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                  <div>
                    <div id="main-page" className="limited">
                      <Collapse
                        title="WHERE"
                        description={selectedVilles.join(",")}
                      >
                        <ul className="pl-0">
                          {locations_front.data.map((v) => {
                            const selected = selectedVilles.includes(v.name);
                            return (
                              <li key={`--${v}`} className="list-li-collapse">
                                <div className="list-collapse">
                                  <div className="text-list-collapse">
                                    <p>{v.name}</p>
                                  </div>
                                  <div>
                                    <label className="checkbox">
                                      <input
                                        className="checkbox-input"
                                        onClick={() => {
                                          if (selected) {
                                            setSelectedVilles((old) =>
                                              old.filter((o) => o !== v.name)
                                            );
                                          } else {
                                            setSelectedVilles((old) => [
                                              ...old,
                                              v.name,
                                            ]);
                                          }
                                        }}
                                        checked={selected}
                                        type="checkbox"
                                      />
                                      <span className="checkbox-checkmark-box">
                                        <span className="checkbox-checkmark"></span>
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </Collapse>
                      <Collapse
                        title="PROPERTY TYPES"
                        description={types.map((t) => t).join(",")}
                      >
                        <ul className="pl-0">
                          {property_types_front?.data?.map((t: any) => {
                            const checked = types.includes(t.name);
                            return (
                              <li key={t.id} className="list-li-collapse">
                                <div className="list-collapse">
                                  <div className="text-list-collapse">
                                    <p>{t.name}</p>
                                  </div>
                                  <div>
                                    <label className="checkbox">
                                      <input
                                        className="checkbox-input"
                                        checked={checked}
                                        onClick={() => {
                                          if (checked) {
                                            setTypes((old) =>
                                              old.filter((o) => o !== t.name)
                                            );
                                          } else {
                                            setTypes((old) => [...old, t.name]);
                                          }
                                        }}
                                        type="checkbox"
                                      />
                                      <span className="checkbox-checkmark-box">
                                        <span className="checkbox-checkmark"></span>
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </Collapse>
                      <Collapse
                        title="BEDROOMS"
                        description={selectedRooms
                          .map((r) => `${r}beds`)
                          .join(",")}
                      >
                        <ul className="pl-0">
                          {rooms.map((v) => (
                            <li key={`++${v}`} className="list-li-collapse">
                              <div className="list-collapse">
                                <div className="text-list-collapse">
                                  <p>{v} beds</p>
                                </div>
                                <div>
                                  <label className="checkbox">
                                    <input
                                      className="checkbox-input"
                                      checked={selectedRooms.includes(v)}
                                      onClick={() => {
                                        if (selectedRooms.includes(v)) {
                                          setSelectedRooms((old) =>
                                            old.filter((o) => o !== v)
                                          );
                                        } else {
                                          setSelectedRooms((old) => [
                                            ...old,
                                            v,
                                          ]);
                                        }
                                      }}
                                      type="checkbox"
                                    />
                                    <span className="checkbox-checkmark-box">
                                      <span className="checkbox-checkmark"></span>
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </Collapse>
                      <Collapse
                        title="PRICE RANGE"
                        descriptionStyle={{
                          fontSize: 12,
                          marginTop: 10,
                        }}
                        // description={`${formatAmout(
                        //   prices[0],
                        //   currency
                        // )} to ${formatAmout(prices[1], currency)}`}
                      >
                        <RanderSlider
                          {...minMax}
                          values={prices}
                          setValues={setPrices}
                        />
                      </Collapse>

                      <Collapse
                        title="AMENITIES"
                        description={selectedAmineties
                          .map((s) => s.name)
                          .join(",")}
                      >
                        <ul className="pl-0">
                          {amineties?.data?.map((t) => {
                            const checked =
                              selectedAmineties.findIndex(
                                (t2) => t2.id === t.id
                              ) !== -1;
                            return (
                              <li key={t.id} className="list-li-collapse">
                                <div className="list-collapse">
                                  <div className="text-list-collapse">
                                    <p>{t.name}</p>
                                  </div>
                                  <div>
                                    <label className="checkbox">
                                      <input
                                        className="checkbox-input"
                                        onClick={() => {
                                          if (checked) {
                                            setSelectedAmineties((old) =>
                                              old.filter((o) => o.id !== t.id)
                                            );
                                          } else {
                                            setSelectedAmineties((old) => [
                                              ...old,
                                              t,
                                            ]);
                                          }
                                        }}
                                        type="checkbox"
                                      />
                                      <span className="checkbox-checkmark-box">
                                        <span className="checkbox-checkmark"></span>
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </Collapse>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 mt-4 mt-lg-0">
                <div>
                  <div className="bloc-entete-right">
                    <div className="">
                      <p className="found-search">
                        Found {currentList?.length} of{" "}
                        {list_properties_front.data.length} results
                      </p>
                    </div>
                  </div>
                  <div>
                    {currentList?.length
                      ? currentList.map((item: any, index) => {
                          return (
                            <NavLink
                              to={`/listings/${item?.slug}`}
                              className="no-link"
                            >
                              <div
                                className="card mb-4  card-item-listing card-item-listing-mobile"
                                key={`currentList${index}`}
                              >
                                <img
                                  src={
                                    item.image === "" ||
                                    item.image === undefined ||
                                    item.image === null
                                      ? `https://ui-avatars.com/api/?name=${item?.property_name}`
                                      : `${baseUrl + item?.image}`
                                  }
                                  className="card-img-top img-listing-card-item"
                                  alt="Listing"
                                />
                                <div className="card-body position-relative">
                                  <div className="d-flex justify-content-between">
                                    <h5 className="card-title title-card-item-listing m-0">
                                      {item.property_name && item.property_name}
                                      ,
                                      {item.property_type &&
                                        item.property_type?.name}
                                    </h5>
                                    <p className="card-text span-for-sale-item">
                                      For{" "}
                                      {item.statut_listing &&
                                        item.statut_listing}
                                    </p>
                                  </div>
                                  <p className="text-prix-card-item-listing m-0">
                                    {getPrice(item)}
                                    {displayPriceType(item?.type_price)}
                                  </p>
                                  <div className="d-flex  align-items-center py-4 display-block-image-responsive">
                                    <div className="d-flex align-items-center">
                                      <span className="icon-bed-2 icon__listing"></span>
                                      <p className="para-text-div-border pl-2">
                                        {item.bedroom && item.bedroom}{" "}
                                        <span className="d-m-none">beds</span>
                                      </p>
                                    </div>
                                    <div className="d-flex mx-none-responsive align-items-center">
                                      <span className="icon-outline-soup-kitchen icon__listing"></span>
                                      <p className="para-text-div-border  pl-2">
                                        {item.kitchen && item.kitchen}{" "}
                                        <span className="d-m-none">
                                          kitchen
                                        </span>
                                      </p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <span className="icon-bathtub-2 icon__listing"></span>
                                      <p className="para-text-div-border  pl-2">
                                        {item.bathroom && item.bathroom}{" "}
                                        <span className="d-m-none">
                                          bathroom
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="mt-3">
                                    <div className="bottom-card-item-listing border-top">
                                      <div className="d-flex">
                                        <Location
                                          set="light"
                                          primaryColor="#A1ABC9"
                                        />
                                        <p className="text-market-card-item-listing lh-25">
                                          {item?.location_id?.name &&
                                            item?.location_id?.name}
                                          ,{" "}
                                          {item.country_id &&
                                            item.country_id?.title}
                                        </p>
                                      </div>
                                      <div>
                                        <Show
                                          set="bold"
                                          primaryColor="#666666"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </NavLink>
                          );
                        })
                      : "No Datas"}
                  </div>
                  {currentList?.length ? (
                    <PaginationComponent
                      per_page={per_page}
                      current_page={current_page}
                      total_data={total_data}
                      handleClick={handleClick}
                      lastIndex={lastIndex}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FrontFooter />
    </div>
  );
};

export default Listing;

function Collapse(props) {
  const onTitleClick = (e) => {
    if (e) {
      const ele = e.target.closest(".collapse");
      if (ele.classList.contains("open")) {
        ele.classList.add("close");
        // let the the close animation finish before we remove the open class making display none
        setTimeout(() => {
          ele.classList.remove("open");
        }, 200);
      } else {
        ele.classList.add("open");
        ele.classList.remove("close");
      }
    }
  };

  return (
    <div className="collapse">
      <div className="collapse-title" onClick={onTitleClick}>
        <h3 className="h3-theme-collapse">{props.title}</h3>
        <p
          className="p-theme-collapse p-theme-collapse-position"
          style={props.descriptionStyle}
          dangerouslySetInnerHTML={{
            __html: props?.description,
          }}
        />
        <span className="collapse-title-symbol" />
      </div>
      <div className="collapse-content" style={{ backgroundColor: "#FFF" }}>
        {props.children}
      </div>
    </div>
  );
}

export function RanderSlider({ values, setValues, min, max }) {
  return (
    <div>
      <RangeSlider
        progress
        max={max}
        min={min}
        style={{ marginTop: 16 }}
        value={values}
        onChange={setValues}
      />
      <div className="row mt-2">
        <div className="col-6">
          <PriceItem price={values[0]} />
        </div>
        <div className="col-6 text-right">
          <PriceItem price={values[1]} />
        </div>
      </div>
    </div>
  );
}

const PriceItem = ({ price }) => {
  const currency = useCurrency();
  return (
    <span
      style={{
        padding: "5px 10px",
        borderRadius: 10,
        backgroundColor: "#eee",
        fontSize: 12,
      }}
    >
      {formatAmout(price, currency)}
    </span>
  );
};
