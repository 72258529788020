import Axios from "axios";
import React from "react";
import { ChevronUp } from "react-iconly";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import api from "../../../Http/global-vars";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import AddReviewData from "../../../repositories/user/listing/AddReviewData";
import { fetchReviews } from "../../../redux/reviews/reviewsAction";
interface Props {
	article: any
}

const FormAddComment: React.FC<Props> = ({ article }) => {


	const dispatch = useDispatch();

	const [isSuccessfullySubmittedForm, setIsSuccessfullySubmittedForm] = React.useState(false);


	const validationSchema = yup.object().shape({
		email: yup.string().email("Enter a valid email").required(),
		message: yup.string().required("This field is required"),
		name: yup.string().required("This field is required"),
	});

	const {
		register,
		handleSubmit,
		reset,
		formState,
	} = useForm<AddReviewData>({
		mode: "onBlur",
		resolver: yupResolver(validationSchema)
	});

	const { errors } = formState;

	const onSubmitFormReview = async (data: any) => {

		setIsSuccessfullySubmittedForm(true);

		data['article'] = article?.id;

		const request = Axios.post(`${api}review/`, data);
		await request
			.then(async (resp) => {
				// console.log("resp", resp);
				Swal.fire({
					icon: 'success',
					title: "Success",
					html: "Comment sent successfully",
					iconColor: '#df5656',
					showCancelButton: false,
					confirmButtonColor: '#df5656',
					cancelButtonColor: '#d33',
					confirmButtonText: 'OK',
					allowOutsideClick: false,

				}).then((result) => {
					if (result.isConfirmed) {
						reset();
					}
				})
				dispatch(fetchReviews())

				setIsSuccessfullySubmittedForm(false);
			})
			.catch((error) => {
				console.error("error", error, error?.response);
				setIsSuccessfullySubmittedForm(false);
			});
	}

	return (
		<div className="form-add-comment-container card-blog mt-3 py-3">
			<div className="px-3 write-comment-container mb-3">
				<h2 className="write-comment-text">Write a comment</h2>
				<ChevronUp primaryColor="#200E32" />
			</div>
			<form className="px-3" onSubmit={handleSubmit(onSubmitFormReview)}>
				<div className="form-group-container row mb-2">
					<div className="form-group col-md-6">
						<input
							type="text"
							className="form-control-perso px-3"
							placeholder="Your name"
							name="name"
							id="name"
							ref={register}
						/>
						{
							errors.name && <small className="text-danger">{errors.name?.message}</small>
						}
					</div>
					<div className="form-group col-md-6">
						<input
							type="text"
							className="form-control-perso px-3"
							placeholder="Your email"
							name="email"
							id="email"
							ref={register}
						/>
						{
							errors.email && <small className="text-danger">{errors.email?.message}</small>
						}
					</div>
				</div>
				<div className="form-textarea-group-container row">
					<div className="form-group col-12">
						<textarea
							name="message"
							id="message"
							className="form-control-textarea px-3 pt-3"
							placeholder="Message"
							ref={register}
						/>
						{
							errors.message && <small className="text-danger">{errors.message?.message}</small>
						}
					</div>
				</div>
				<div className="d-flex justify-content-center mt-3">
					{
						!isSuccessfullySubmittedForm ? (
							<button className="submit-comment">
								Submit comment
							</button>
						) :
							(
								<span className="submit-comment">
									<i className="fa fa-spin fa-spinner"></i>&nbsp;&nbsp;In Progress
								</span>
							)
					}
				</div>
			</form>
		</div>
	);
};

export default FormAddComment;
