import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import * as yup from "yup";
import { fetchAbouts } from "../../../../redux/AboutAdmin/AboutAction";
import { fetchTafdata } from "../../../../redux/tafDataAdmin/tafDataAction";
import { LocationFormData } from "../../../../repositories/user/LocationFormData";
import About from "../../../../services/About";
import tafData from "../../../../services/tafData";
import { ContentEditor } from "../../ListingAdmin/ListingDetailsDeBaseSecondStep";

function Modalaboutpersonnalisation(props) {
  const typeAbout = props.type;
  const aboutDatas = props.datas;

  const [isSuccessfullySubmittedForm, setIsSuccessfullySubmittedForm] =
    React.useState(false);
  const [submitedForm, setSubmitedForm] = React.useState(false);
  const dispatch = useDispatch();
  // const about = useSelector((state: RootStateOrAny) => state.about);

  const validationSchema = yup.object().shape({
    title: yup.string().required("This field is required"),
    messsage: yup.string(),
  });

  const token = window.localStorage.getItem("userToken");
  const userID = window.localStorage.getItem("userID");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { register, setValue, handleSubmit, reset, formState, watch } = useForm(
    {
      mode: "onBlur",
      resolver: yupResolver(validationSchema),
    }
  );
  const { errors } = formState;
  useEffect(() => {
    register("message");
  }, []);
  useEffect(() => {
    if (aboutDatas?.id && typeAbout === "update") {
      setValue("title", aboutDatas?.title);
      setValue("message", aboutDatas?.message);
    }
  }, [
    setValue,
    aboutDatas?.title,
    aboutDatas?.message,
    aboutDatas?.id,
    typeAbout,
  ]);

  const { message } = watch(["message"]);

  const onSubmitAbout = async (data: LocationFormData) => {
    setSubmitedForm(true);
    setIsSuccessfullySubmittedForm(true);

    const fd = new FormData();
    fd.append("title", data.title);
    fd.append("message", data.message);
    fd.append("created_by", userID ? userID : "null");

    const messageSuccesBytypeAbout =
      aboutDatas?.id && typeAbout === "update"
        ? "About updated successfully."
        : "About added successfully.";

    const request =
      aboutDatas?.id && typeAbout === "update"
        ? About.onEditAbout(aboutDatas?.id, fd, config)
        : About.onAddAbout(fd, config);

    request
      .then((res) => {
        if (res.status === 201 || 200) {
          Swal.fire({
            icon: "success",
            iconColor: "#df5656",
            showCancelButton: false,
            confirmButtonColor: "#df5656",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            title: messageSuccesBytypeAbout,
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              $(".modal").modal("hide");
            }
          });
          dispatch(fetchAbouts());
          setIsSuccessfullySubmittedForm(false);
          if (!aboutDatas?.id) {
            setValue("title", "");
            setValue("message", "");
          }
        }
      })
      .catch((err) => {
        console.log("error", err.response, "datas", data);
        Swal.fire({
          icon: "error",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          title: "Check your entered datas.",
          showConfirmButton: true,
          allowOutsideClick: false,
        });
        setIsSuccessfullySubmittedForm(false);
        setSubmitedForm(false);
      });
  };

  return (
    <div>
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content faq-modal-content">
          <div className="modal-header modal-faq-header modal-faq-header-add-member">
            <h5
              className="modal-title titre-question-reponse"
              id="AboutModal"
              data-testid={submitedForm === true ? "FormSubmittedDone" : ""}
            >
              {aboutDatas?.id ? "Update" : " Add"}
              &nbsp;&nbsp;About{" "}
            </h5>
            <button
              type="button"
              data-testid={submitedForm === false ? "FormSubmittedFail" : ""}
              className="close close-icon"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form
            className="form-input-profile-modal"
            onSubmit={handleSubmit(onSubmitAbout)}
          >
            <div className="modal-body">
              <div className="container-input-address-modal">
                <div className="row">
                  <div className="col-md-11">
                    <div className="infos-perso-input-modal">
                      <div className="form-label-text text-label-modal">
                        Title
                      </div>
                      <input
                        className="form-control-profil text-dark"
                        placeholder="Title"
                        name="title"
                        data-testid="titleId"
                        ref={register}
                      />
                      {errors.title && (
                        <div className="alert alert-danger sia-alert-danger closer mt-2">
                          {errors.title?.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-11">
                    <div className="infos-perso-input-modal">
                      <div className="form-label-text text-label-modal">
                        Message
                      </div>
                      <ContentEditor
                        setValue={setValue}
                        data={message}
                        name="message"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer faq-modal-footer modal-faq-footer-add-member">
              <button
                type="button"
                className="btn btn-retour-faq-modal"
                data-dismiss="modal"
              >
                Back
              </button>

              <div className="d-flex justify-content-end">
                {!isSuccessfullySubmittedForm ? (
                  <button
                    className="btn-taf-primary"
                    type="submit"
                    data-testid="sendBtnId"
                  >
                    Send
                  </button>
                ) : (
                  <span className="btn-taf-primary">
                    <i className="fa fa-spin fa-spinner"></i>&nbsp;&nbsp;In
                    Progress...
                  </span>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Modalaboutpersonnalisation;

export function ContentField({ title, fieldName }) {
  const tafData = useSelector((state: RootStateOrAny) => state.tafData);
  const { data } = tafData ?? {};
  return (
    <ContentFieldComponent
      fieldName={fieldName}
      id={data?.id}
      title={title}
      value={data ? data[fieldName] || "" : ""}
    />
  );
}

function ContentFieldComponent({ title, fieldName, value, id }) {
  const [isSuccessfullySubmittedForm, setIsSuccessfullySubmittedForm] =
    React.useState(false);
  const dispatch = useDispatch();
  // const about = useSelector((state: RootStateOrAny) => state.about);

  const validationSchema = yup.object().shape({
    [fieldName]: yup.string().required("The description is required"),
  });

  const token = window.localStorage.getItem("userToken");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { register, setValue, handleSubmit, reset, formState, watch } = useForm(
    {
      mode: "onBlur",
      resolver: yupResolver(validationSchema),
    }
  );
  const { errors } = formState;
  useEffect(() => {
    register(fieldName);
  }, []);
  useEffect(() => {
    setValue(fieldName, value);
  }, [value]);

  const watched = watch([fieldName]);
  const message = watched[fieldName];
  const onSubmitAbout = async (data: any) => {
    setIsSuccessfullySubmittedForm(true);
    const fd = new FormData();
    fd.append(fieldName, data[fieldName]);

    const messageSuccesBytypeAbout = "Data updated successfully.";

    const request = id
      ? tafData.onEdit(id, fd, config)
      : tafData.onAdd(fd, config);

    request
      .then((res) => {
        if (res.status === 201 || 200) {
          Swal.fire({
            icon: "success",
            iconColor: "#df5656",
            showCancelButton: false,
            confirmButtonColor: "#df5656",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            title: messageSuccesBytypeAbout,
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
          dispatch(fetchTafdata());
          setIsSuccessfullySubmittedForm(false);
        }
      })
      .catch((err) => {
        console.log("error", err.response, "datas", data);
        Swal.fire({
          icon: "error",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          title: "Check your entered datas.",
          showConfirmButton: true,
          allowOutsideClick: false,
        });
        setIsSuccessfullySubmittedForm(false);
      });
  };

  return (
    <form
      className="form-input-profile-modal"
      onSubmit={handleSubmit(onSubmitAbout)}
    >
      <div className="row align-items-center">
        <div className="col-md-10">
          <div className="modal-body">
            <div className="container-input-address-modal">
              <div className="row">
                <div className="col-md-12">
                  <div className="infos-perso-input-modal">
                    <div className="form-label-text text-label-modal">
                      {title}
                    </div>
                    <ContentEditor
                      setValue={setValue}
                      data={message}
                      name={fieldName}
                    />
                    {errors[fieldName] && (
                      <div className="alert alert-danger sia-alert-danger closer mt-2">
                        {errors[fieldName].message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-2">
          {!isSuccessfullySubmittedForm ? (
            <button
              className="btn-taf-primary"
              type="submit"
              data-testid="sendBtnId"
            >
              Update
            </button>
          ) : (
            <button type="button" className="btn-taf-primary">
              <i className="fa fa-spin fa-spinner"></i>
              Updating...
            </button>
          )}
        </div>
      </div>
    </form>
  );
}
