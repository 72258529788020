import { yupResolver } from "@hookform/resolvers/yup";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import React, { useEffect } from "react";
import Dropzone from "react-dropzone-uploader";
import { useForm } from "react-hook-form";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import * as yup from "yup";
import baseUrl from "../../../Http/backend-base-url";
import groupphoto from "../../../images/Groupphoto.png";
import { fetchShowOf } from "../../../redux/ShowOf/ShowOfAction";
import { ShowOfDatas } from "../../../repositories/user/ShowOfDatas";
import ShowOfService from "../../../services/ShowOf";

const ShowOff: React.FC = () => {
  const dispatch = useDispatch();
  const showof = useSelector((state: RootStateOrAny) => state.showof);

  // console.log("showof?.data?.id)",showof?.data[0]?.id);
  // const [submitedForm, setSubmitedForm] = React.useState(false);

  const [isSuccessfullySubmittedForm, setIsSuccessfullySubmittedForm] =
    React.useState(false);
  const [imageExistence, setImageExistence] = React.useState<any>();
  const [imageCountry, setImageCountry] = React.useState<any>();
  const [imageState, setImageState] = React.useState<any>();
  const [imageHome, setImageHome] = React.useState<any>();
  const [imageExistenceDefault, setImageExistenceDefault] =
    React.useState<any>();
  const [imageCountryDefault, setImageCountryDefault] = React.useState<any>();
  const [imageStateDefault, setImageStateDefault] = React.useState<any>();
  const [imageHomeDefault, setImageHomeDefault] = React.useState<any>();
  const [hideImageExperienceDefault, setHideImageExperienceDefault] =
    React.useState<boolean>();
  const [hideImageCountryDefault, setHideImageCountryDefault] =
    React.useState<boolean>();
  const [hideImageStateDefault, setHideImageStateDefault] =
    React.useState<boolean>();
  const [hideImageHomeDefault, setHideImageHomeDefault] =
    React.useState<boolean>();

  const validationSchema = yup.object().shape({});

  const { register, handleSubmit, setValue, formState, clearErrors, reset } =
    useForm<any>({
      mode: "onBlur",
      resolver: yupResolver(validationSchema),
    });
  // const { errors } = formState;

  const getFilesFromEvent = async (e) => {
    const chosenFiles = await getDroppedOrSelectedFiles(e);
    console.log(
      "chosenFiles.map(f => f.fileObject)",
      chosenFiles.map((f) => f.fileObject)
    );
    return chosenFiles.map((f) => f.fileObject);
  };

  const InputChooseFileExistence = ({
    accept,
    onFiles,
    files,
    getFilesFromEvent,
  }) => {
    const text = files.length === 0 && (
      // 'Ajouter  des photos' :
      <div className="start-label-input-choose-file">
        <div className="label-img-container pt-3">
          <img src={groupphoto} className="label-image" alt="icone-label" />
        </div>
        <span className="first_label-input-choose-file">
          Déposez une image ici ou sélectionnez un fichier.
        </span>
        <span className="second-label-input-choose-file">
          Format acceptés : JPG, PNG, GIF, TIFF ou BMP, inférieurs à 200Mo.
        </span>
      </div>
    );
    let buttonStartStyle: any = {
      background: "#rgb(243, 243, 243);",
      cursor: "pointer",
      borderRadius: 10,
      marginTop: 10,
      textAlign: "center",
      maxWidth: "100%",
      color: "#fff",
    };

    if (files.length <= 0) {
      buttonStartStyle = {
        // color: '#ff9800',
        cursor: "pointer",
        padding: 15,
        borderRadius: 30,
        marginTop: 10,
        textAlign: "center",
      };
    }

    return (
      <div className="upload-dropzone-row">
        <div style={buttonStartStyle} className="col-md-11">
          {/* {text} */}
          <label htmlFor="dropzoneImageExistence">{text}</label>
          <input
            style={{ opacity: 0, cursor: "pointer" }}
            className="file-input"
            type="file"
            name="image"
            multiple
            accept={accept}
            onChange={async (e) => {
              const chosenFiles: File[] = await getFilesFromEvent(e);
              // console.log("chosenFiles", chosenFiles[0]);
              if (chosenFiles[0]) {
                setHideImageExperienceDefault(true);
              } else {
                setHideImageExperienceDefault(false);
              }
              setImageExistence(chosenFiles[0]);
              onFiles(chosenFiles);
            }}
            id="dropzoneImageExistence"
          />
        </div>
      </div>
    );
  };
  const InputChooseFileCountry = ({
    accept,
    onFiles,
    files,
    getFilesFromEvent,
  }) => {
    const text = files.length === 0 && (
      // 'Ajouter  des photos' :
      <div className="start-label-input-choose-file">
        <div className="label-img-container pt-3">
          <img src={groupphoto} className="label-image" alt="icone-label" />
        </div>
        <span className="first_label-input-choose-file">
          Déposez une image ici ou sélectionnez un fichier.
        </span>
        <span className="second-label-input-choose-file">
          Format acceptés : JPG, PNG, GIF, TIFF ou BMP, inférieurs à 200Mo.
        </span>
      </div>
    );
    let buttonStartStyle: any = {
      background: "#rgb(243, 243, 243);",
      cursor: "pointer",
      borderRadius: 10,
      marginTop: 10,
      textAlign: "center",
      maxWidth: "100%",
      color: "#fff",
    };

    if (files.length <= 0) {
      buttonStartStyle = {
        // color: '#ff9800',
        cursor: "pointer",
        padding: 15,
        borderRadius: 30,
        marginTop: 10,
        textAlign: "center",
      };
    }

    return (
      <div className="upload-dropzone-row">
        <div style={buttonStartStyle} className="col-md-11">
          {/* {text} */}
          <label htmlFor="dropzoneImageCountry">{text}</label>
          <input
            style={{ opacity: 0, cursor: "pointer" }}
            className="file-input"
            type="file"
            name="image"
            multiple
            accept={accept}
            onChange={async (e) => {
              const chosenFiles: File[] = await getFilesFromEvent(e);
              // console.log("chosenFiles", chosenFiles[0]);
              if (chosenFiles[0]) {
                setHideImageCountryDefault(true);
              } else {
                setHideImageCountryDefault(false);
              }
              setImageCountry(chosenFiles[0]);
              onFiles(chosenFiles);
            }}
            id="dropzoneImageCountry"
          />
        </div>
      </div>
    );
  };
  const InputChooseFileState = ({
    accept,
    onFiles,
    files,
    getFilesFromEvent,
  }) => {
    const text = files.length === 0 && (
      // 'Ajouter  des photos' :
      <div className="start-label-input-choose-file">
        <div className="label-img-container pt-3">
          <img src={groupphoto} className="label-image" alt="icone-label" />
        </div>
        <span className="first_label-input-choose-file">
          Déposez une image ici ou sélectionnez un fichier.
        </span>
        <span className="second-label-input-choose-file">
          Format acceptés : JPG, PNG, GIF, TIFF ou BMP, inférieurs à 200Mo.
        </span>
      </div>
    );
    let buttonStartStyle: any = {
      background: "#rgb(243, 243, 243);",
      cursor: "pointer",
      borderRadius: 10,
      marginTop: 10,
      textAlign: "center",
      maxWidth: "100%",
      color: "#fff",
    };

    if (files.length <= 0) {
      buttonStartStyle = {
        // color: '#ff9800',
        cursor: "pointer",
        padding: 15,
        borderRadius: 30,
        marginTop: 10,
        textAlign: "center",
      };
    }

    return (
      <div className="upload-dropzone-row">
        <div style={buttonStartStyle} className="col-md-11">
          {/* {text} */}
          <label htmlFor="dropzoneImageState">{text}</label>
          <input
            style={{ opacity: 0, cursor: "pointer" }}
            className="file-input"
            type="file"
            name="image"
            multiple
            accept={accept}
            onChange={async (e) => {
              const chosenFiles: File[] = await getFilesFromEvent(e);
              // console.log("chosenFiles", chosenFiles[0]);
              if (chosenFiles[0]) {
                setHideImageStateDefault(true);
              } else {
                setHideImageStateDefault(false);
              }
              setImageState(chosenFiles[0]);
              onFiles(chosenFiles);
            }}
            id="dropzoneImageState"
          />
        </div>
      </div>
    );
  };
  const InputChooseFileHome = ({
    accept,
    onFiles,
    files,
    getFilesFromEvent,
  }) => {
    const text = files.length === 0 && (
      // 'Ajouter  des photos' :
      <div className="start-label-input-choose-file">
        <div className="label-img-container pt-3">
          <img src={groupphoto} className="label-image" alt="icone-label" />
        </div>
        <span className="first_label-input-choose-file">
          Déposez une image ici ou sélectionnez un fichier.
        </span>
        <span className="second-label-input-choose-file">
          Format acceptés : JPG, PNG, GIF, TIFF ou BMP, inférieurs à 200Mo.
        </span>
      </div>
    );
    let buttonStartStyle: any = {
      background: "#rgb(243, 243, 243);",
      cursor: "pointer",
      borderRadius: 10,
      marginTop: 10,
      textAlign: "center",
      maxWidth: "100%",
      color: "#fff",
    };

    if (files.length <= 0) {
      buttonStartStyle = {
        // color: '#ff9800',
        cursor: "pointer",
        padding: 15,
        borderRadius: 30,
        marginTop: 10,
        textAlign: "center",
      };
    }

    return (
      <div className="upload-dropzone-row">
        <div style={buttonStartStyle} className="col-md-11">
          {/* {text} */}
          <label htmlFor="dropzoneImageHome">{text}</label>
          <input
            style={{ opacity: 0, cursor: "pointer" }}
            className="file-input"
            type="file"
            name="image"
            multiple
            accept={accept}
            onChange={async (e) => {
              const chosenFiles: File[] = await getFilesFromEvent(e);
              // console.log("chosenFiles", chosenFiles[0]);
              if (chosenFiles[0]) {
                setHideImageHomeDefault(true);
              } else {
                setHideImageHomeDefault(false);
              }
              setImageHome(chosenFiles[0]);
              onFiles(chosenFiles);
            }}
            id="dropzoneImageHome"
          />
        </div>
      </div>
    );
  };

  const token = window.localStorage.getItem("userToken");
  const userID = window.localStorage.getItem("userID");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    console.log(showof?.data[0]);
    if (showof?.data[0]?.id) {
      setValue("year_existence", showof?.data[0]?.year_existence);
      setValue("number_country", showof?.data[0]?.number_country);
      setValue("number_state", showof?.data[0]?.number_state);
      setValue("number_home", showof?.data[0]?.number_home);
      setImageExistenceDefault(showof?.data[0]?.image_year_existence);
      setImageCountryDefault(showof?.data[0]?.image_number_country);
      setImageStateDefault(showof?.data[0]?.image_number_state);
      setImageHomeDefault(showof?.data[0]?.image_number_home);
    }
  }, [setValue, showof?.data]);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(() => {
          return clearErrors();
        });
      }, 3000);
    }
  }, [formState, clearErrors]);

  const onSubmitShowOf = (data: ShowOfDatas) => {
		setIsSuccessfullySubmittedForm(true);

		const fd = new FormData();
		if (imageExistence) {
			fd.append("image_year_existence", imageExistence);
		}
		if (imageCountry) {
			fd.append("image_number_country", imageCountry);
		}
		if (imageState) {
			fd.append("image_number_state", imageState);
		}
		if (imageHome) {
			fd.append("image_number_home", imageHome);
		}
		fd.append("year_existence", data.year_existence);
		fd.append("number_country", data.number_country);
		fd.append("number_state", data.number_state);
		fd.append("number_home", data.number_home);
		fd.append("link_year", data.link_year);
		fd.append("link_country", data.link_country);
		fd.append("link_state", data.link_state);
		fd.append("link_home", data.link_home);

		if (userID) {
			fd.append("created_by", userID);
		}

		const request = showof?.data[0]?.id
			? ShowOfService.onEditShowOf(showof?.data[0]?.id, fd, config)
			: ShowOfService.onAddShowOf(fd, config);
		request
			.then((res) => {
				if (res.status === 201 || 200) {
					Swal.fire({
						icon: "success",
						title: "Success",
						html: showof?.data[0]?.id
							? "ShowOf updated successfully!"
							: "ShowOf saved successfully!",
						iconColor: "#df5656",
						showCancelButton: false,
						confirmButtonColor: "#df5656",
						cancelButtonColor: "#d33",
						confirmButtonText: "OK",
						allowOutsideClick: false,
					}).then((result) => {
						if (result.isConfirmed) {
							$(".modal").modal("hide");
							reset();
							dispatch(fetchShowOf());
							setHideImageExperienceDefault(false);
							setHideImageCountryDefault(false);
							setHideImageStateDefault(false);
							setHideImageHomeDefault(false);
						}
					});
					dispatch(fetchShowOf());
					setIsSuccessfullySubmittedForm(false);
				}
			})
			.catch((error) => {
				Swal.fire({
					icon: "error",
					title: "Errorr",
					html: showof?.data[0]?.id
						? "ShowOf not updated successfully!,Please review the data entered"
						: "ShowOf not saved successfully!,Please review the data entered",
					iconColor: "#df5656",
					showCancelButton: false,
					confirmButtonColor: "#df5656",
					cancelButtonColor: "#d33",
					confirmButtonText: "OK",
					allowOutsideClick: false,
				});
				setIsSuccessfullySubmittedForm(false);
				reset();
			});
  };

  return (
		<>
			<div className="profile-containers-param mt-5">
				<form onSubmit={handleSubmit(onSubmitShowOf)}>
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="infos-perso-input">
								<label className="form-label-text">
									Year of existence
								</label>
								<input
									type="text"
									name="year_existence"
									data-testid="yearExistenceId"
									className="form-control-profil form-control-showoff"
									placeholder="31"
									ref={register}
								/>
							</div>
							<div className="infos-perso-input">
								<label
									className="form-label-text"
									htmlFor="link-year"
								>
									Link
								</label>
								<input
									type="url"
									name="link_year"
									data-testid="link-year"
									className="form-control-profil form-control-showoff"
									placeholder="Link"
									ref={register}
									defaultValue={showof?.data[0]?.link_year}
								/>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="form-add-project-group form-group-add-project-dropzone-container pl-3">
								<label
									htmlFor="projectImage"
									className="form-add-project-label"
								>
									Teaser image
								</label>
								<Dropzone
									getUploadParams={() => ({
										url: "https://httpbin.org/post",
									})}
									InputComponent={InputChooseFileExistence}
									getFilesFromEvent={getFilesFromEvent}
									accept="image/*"
									maxFiles={100}
									styles={{
										dropzone: {
											minHeight: 150,
											maxHeight: 300,
											border: "2px dashed #d9d9d9",
											background: "#F3F3F3",
											overflow: "hidden",
											opacity: 1,
										},
									}}
								/>
								{!hideImageExperienceDefault && (
									<img
										src={
											imageExistenceDefault &&
											baseUrl + imageExistenceDefault
										}
										alt=""
										height="120"
										width="120"
										className="my-2"
									/>
								)}
							</div>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="infos-perso-input">
								<label className="form-label-text">
									Number of country
								</label>
								<input
									type="text"
									className="form-control-profil form-control-showoff"
									placeholder="31"
									name="number_country"
									ref={register}
									data-testid="numberCountryId"
								/>
							</div>
							<div className="infos-perso-input">
								<label
									className="form-label-text"
									htmlFor="link-country"
								>
									Link
								</label>
								<input
									type="url"
									name="link_country"
									data-testid="link-country"
									className="form-control-profil form-control-showoff"
									placeholder="Link"
									ref={register}
									defaultValue={showof?.data[0]?.link_country}
								/>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="form-add-project-group form-group-add-project-dropzone-container pl-3">
								<label
									htmlFor="projectImage"
									className="form-add-project-label"
								>
									Teaser image
								</label>
								<Dropzone
									getUploadParams={() => ({
										url: "https://httpbin.org/post",
									})}
									InputComponent={InputChooseFileCountry}
									getFilesFromEvent={getFilesFromEvent}
									accept="image/*"
									maxFiles={100}
									styles={{
										dropzone: {
											minHeight: 150,
											maxHeight: 300,
											border: "2px dashed #d9d9d9",
											background: "#F3F3F3",
											overflow: "hidden",
											opacity: 1,
										},
									}}
								/>
								{!hideImageCountryDefault && (
									<img
										src={
											imageCountryDefault &&
											baseUrl + imageCountryDefault
										}
										alt=""
										height="120"
										width="120"
										className="my-2"
									/>
								)}
							</div>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="infos-perso-input">
								<label className="form-label-text">
									Number of estates
								</label>
								<input
									type="text"
									className="form-control-profil form-control-showoff"
									placeholder="8"
									ref={register}
									name="number_state"
									data-testid="numberStateId"
								/>
							</div>
							<div className="infos-perso-input">
								<label
									className="form-label-text"
									htmlFor="link-state"
								>
									Link
								</label>
								<input
									type="url"
									name="link_state"
									data-testid="link-state"
									className="form-control-profil form-control-showoff"
									placeholder="Link"
									ref={register}
									defaultValue={showof?.data[0]?.link_state}
								/>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="form-add-project-group form-group-add-project-dropzone-container pl-3">
								<label
									htmlFor="projectImage"
									className="form-add-project-label"
								>
									Teaser image
								</label>
								<Dropzone
									getUploadParams={() => ({
										url: "https://httpbin.org/post",
									})}
									InputComponent={InputChooseFileState}
									getFilesFromEvent={getFilesFromEvent}
									accept="image/*"
									maxFiles={100}
									styles={{
										dropzone: {
											minHeight: 150,
											maxHeight: 300,
											border: "2px dashed #d9d9d9",
											background: "#F3F3F3",
											overflow: "hidden",
											opacity: 1,
										},
									}}
								/>
								{!hideImageStateDefault && (
									<img
										src={
											imageStateDefault &&
											baseUrl + imageStateDefault
										}
										alt=""
										height="120"
										width="120"
										className="my-2"
									/>
								)}
							</div>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="infos-perso-input">
								<label className="form-label-text">
									Number of home
								</label>
								<input
									type="text"
									className="form-control-profil form-control-showoff"
									placeholder="+5000"
									name="number_home"
									ref={register}
									data-testid="numberHomeId"
								/>
							</div>
							<div className="infos-perso-input">
								<label
									className="form-label-text"
									htmlFor="link-home"
								>
									Link
								</label>
								<input
									type="url"
									name="link_home"
									data-testid="link-home"
									className="form-control-profil form-control-showoff"
									placeholder="Link"
									ref={register}
									defaultValue={showof?.data[0]?.link_home}
								/>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="form-add-project-group form-group-add-project-dropzone-container pl-3">
								<label
									htmlFor="projectImage"
									className="form-add-project-label"
								>
									Teaser image
								</label>
								<Dropzone
									getUploadParams={() => ({
										url: "https://httpbin.org/post",
									})}
									InputComponent={InputChooseFileHome}
									getFilesFromEvent={getFilesFromEvent}
									accept="image/*"
									maxFiles={100}
									styles={{
										dropzone: {
											minHeight: 150,
											maxHeight: 300,
											border: "2px dashed #d9d9d9",
											background: "#F3F3F3",
											overflow: "hidden",
											opacity: 1,
										},
									}}
								/>
								{!hideImageHomeDefault && (
									<img
										src={
											imageHomeDefault &&
											baseUrl + imageHomeDefault
										}
										alt=""
										height="120"
										width="120"
										className="my-2"
									/>
								)}
							</div>
						</div>
					</div>
					{!isSuccessfullySubmittedForm ? (
						<button
							className="btn-taf-primary"
							data-testid="saveBtnId"
							type="submit"
						>
							{showof?.data[0]?.id ? "Update" : "Save"}
						</button>
					) : (
						<button className="btn-taf-primary">
							<i className="fas fa-spin fa-spinner"></i>&nbsp;&nbsp;In
							Progress
						</button>
					)}
				</form>
			</div>
		</>
  );
};

export default ShowOff;
