import React, { useMemo } from 'react'
import Carousel from 'react-elastic-carousel'
import { RootStateOrAny, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import baseUrl from '../../../Http/backend-base-url'
import FacebookIcon from '../../../images/icons/facebook.png'
import InstagramIcon from '../../../images/icons/instagram.png'
import TwitterIcon from '../../../images/icons/twitter.png'
import { SettingAddMemberData } from '../../../repositories/user/SettingAddMemberData'

const CarouselTeam: React.FC = () => {
  const our_team_member_front = useSelector(
    (state: RootStateOrAny) => state.our_team_member_front,
  )
  const renerItems = useMemo(() => {
    return our_team_member_front.data ? (
      our_team_member_front.data.map((item: SettingAddMemberData) => {
        return (
          <div
            className="bloc-card-team-item-hover"
            key={`meet-our-team-${item.id}`}
          >
            <div className="card card-team-item">
              {/* <img src={CardTeam1} /> */}
              <img
                width="300"
                height="250"
                src={
                  item.image === undefined || item.image === null
                    ? `https://ui-avatars.com/api/?name=${item?.full_name}`
                    : `${baseUrl + item?.image}`
                }
                className="card-img-top card-img-carousel-team"
                alt="Team"
              />
              <div className="card-body text-center height-card-body-team">
                <h5 className="card-title titre-theme-card">
                  {item?.full_name ?? ""}
                </h5>
                <p className="card-text text-name-card text-capitalize">
                  {item?.title ?? ""}
                </p>
              </div>
            </div>
            <div className="hover-card-team-item">
              <div className="bloc-icon-social-team">
                <a href={item?.facebook || "#"} target="_blank">
                  <img src={FacebookIcon} alt="Facebook" />
                </a>
                <a
                  href={item?.twitter || "#"}
                  className="mx-3 no-link btn"
                  target="_blank"
                >
                  <img src={TwitterIcon} alt="Facebook" />
                </a>
                <a href={item?.instagram || "#"} target="_blank">
                  <img src={InstagramIcon} alt="Facebook" />
                </a>
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <h1 className="text-center text-dark">No Member</h1>
    )
  }, [our_team_member_front.data])
  // console.log("our_team_member_front", our_team_member_front.data);

  return (
    <div className="">
      <div className="no-view-mobile">
        {/* @ts-ignore */}
        <Carousel
          isRTL={false}
          enableAutoPlay
          autoPlaySpeed={1500}
          itemsToShow={3}
          itemsToScroll={1}
        >
          {renerItems}
        </Carousel>
      </div>
      <div className="no-view-desktop">
        {/* @ts-ignore */}
        <Carousel
          isRTL
          enableAutoPlay
          autoPlaySpeed={1500}
          itemsToShow={1}
          itemsToScroll={1}
        >
          {renerItems}
        </Carousel>
      </div>
    </div>
  )
}

export default CarouselTeam
