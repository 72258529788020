import {
  Delete,
  Edit,
} from "react-iconly";
import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import "react-bootstrap-table/css/react-bootstrap-table.css";
import {
  BootstrapTable,
  TableHeaderColumn,
} from "react-bootstrap-table";
import { NavLink } from "react-router-dom";
import ChampsSkeleton from "../ChampsSkeleton";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import ProjectTypeDataForm from "../../../../repositories/user/ProjectTypeDataForm";
import Swal from "sweetalert2";
import { fetchProjectTypes } from "../../../../redux/ProjectTypes/ProjectTypesAction";
import ProjectType from "../../../../services/ProjectType";
import ModalPropriete from "../LesModals/ModalProperty";

const ProprieteTable =() => {

    const project_types = useSelector((state: RootStateOrAny) => state.project_types);
    const dispatch = useDispatch();
    const [typeForm,setTypeForm]= useState("")
    const [typeFormDatas,setTypeFormDatas]= useState<ProjectTypeDataForm>()
    const token = window.localStorage.getItem('userToken');
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const [
      isPageLoading,
      setIsPageLoading,
    ] =
      React.useState(
        true
      );

    React.useEffect(() => {
      setTimeout(
        () => {
          setIsPageLoading(
            false
          );
        },
        3000
      );
    }, []);

    const handleChangeTypeForm=(data: ProjectTypeDataForm)=>{
      setTypeForm("update")
      setTypeFormDatas(data);

    }

    const actionFormatter =
      (
        cell,
        row
      ) => {
        return (
          <div className="parametre-actions-container">
            <NavLink
              to="#"
              style={{
                color:
                  "linear-gradient(180deg, #06640C 0%, #061A07 100%)",
              }}
              onClick={() =>handleChangeTypeForm(row)}
              data-toggle="modal" data-target="#UpdateProjectModal"
              title="Edit this project type"
            >
              <Edit
                set="bold"
                primaryColor="linear-gradient(180deg, #06640C 0%, #061A07 100%)"
                style={{
                  fontSize:
                    "20px",
                }}
              />
            </NavLink>

            <NavLink
              to="#"
              style={{
                color:
                  "#E53E3E",
              }}
              onClick={() => onDeleteProjectType(row?.id)}
              title="Delete this project type"
            >
              <Delete
                set="bold"
                primaryColor="#E53E3E"
                style={{
                  fontSize:
                    "16px",
                  width:
                    "20px",
                  marginLeft:
                    " 10px",
                }}
              />
            </NavLink>
          </div>
        );
      };

    const onDeleteProjectType = (id: ProjectTypeDataForm) => {
      Swal.fire({
        title: "Do you want to delete this project?",
        // showDenyButton: true,
        icon: 'warning',
        confirmButtonText: `YES`,
        cancelButtonText: `NO`,
        iconColor: "#df5656",
        showCancelButton: true,
        confirmButtonColor: "#df5656",
        cancelButtonColor: "#6c757d",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const addRequest = ProjectType.onDeleteProjectType(id, config)
          addRequest
            .then((response) => {
              dispatch(fetchProjectTypes());
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Project type deleted successfully",
                showConfirmButton: true,
                iconColor: "#df5656",
                confirmButtonColor: "#df5656",
                allowOutsideClick: false,
              });
            })
            .catch((error) => {
              console.log(error);
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Oops! An error occurred while deleting",
                showConfirmButton: true,
                iconColor: "#df5656",
                confirmButtonColor: "#df5656",
                allowOutsideClick: false,
              });
            });
        } else {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Deletion was successfully undone",
            showConfirmButton: true,
            iconColor: "#df5656",
            confirmButtonColor: "#df5656",
            allowOutsideClick: false,
          });
        }
      });
    };

    return (
      <div className="infos-perso-input">
        <>
          {isPageLoading && <ChampsSkeleton />}
          {!isPageLoading && (
            <BootstrapTable
              data={project_types?.data}
              striped={true}
              hover={true}
              condensed={false}
              multiColumnSort={2}
              search={false}
              version="4"
              bordered={false}
              pagination
            >
              <TableHeaderColumn
                width="50"
                dataField="id"
                isKey={true}
                bordered={false}
                thStyle={{
                  fontWeight: 600,
                  fontSize: 14,
                  color: "#BABEC6",
                }}
                tdStyle={{
                  fontWeight: 500,
                  fontSize: 16,
                  color: "rgba(0, 0, 0, 0.8)",
                  padding: 20,
                }}
                hidden
              >
                N°
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="name"
                bordered={false}
                thStyle={{
                  fontWeight: 600,
                  fontSize: 14,
                  color: "#BABEC6",
                }}
                tdStyle={{
                  fontWeight: 500,
                  fontSize: 16,
                  color: "rgba(0, 0, 0, 0.7)",
                  padding: 20,
                }}
              >
                Name
              </TableHeaderColumn>

              <TableHeaderColumn
                bordered={false}
                thStyle={{
                  fontWeight: 600,
                  fontSize: 14,
                  color: "#BABEC6",
                }}
                tdStyle={{
                  fontWeight: 500,
                  fontSize: 16,
                  color: "rgba(0, 0, 0, 0.7)",
                  padding: 20,
                }}
                dataField="description"
              >
                Description
              </TableHeaderColumn>
              <TableHeaderColumn
                bordered={false}
                thStyle={{
                  fontWeight: 600,
                  fontSize: 18,
                  color: "#BABEC6",
                }}
                tdStyle={{
                  fontWeight: 500,
                  fontSize: 16,
                  color: "rgba(0, 0, 0, 0.7)",
                  padding: 20,
                }}
                dataFormat={(cell, row) => actionFormatter(cell, row)}
              >
                Actions
              </TableHeaderColumn>
            </BootstrapTable>
          )}
        </>
        <div
          className="modal fade modal-faq modal-faq-contact"
          id="UpdateProjectModal"
          aria-labelledby="UpdateProjectModalLabel"
          aria-hidden="true"
        >
          <ModalPropriete type_form={typeForm} datas={typeFormDatas} />
        </div>
      </div>
    );
  };

export default ProprieteTable;
