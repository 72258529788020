import avatarAuthorComment from "../../../images/author-comment-avatar.png";
import avatareAuthor from "../../../images/avatar-blog-author.png";
import trendingPost from '../../../images/trending-post.png'


export const AuthorBlogCardData = {
	avatarAuthor: avatareAuthor,
	name: "Kylian",
	description:
		"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi tempor risus dictum turpis sed. Urna justo, malesuada arcu ut interdum ornare adipiscing amet. Sit tellus convallis et risus. Dolor ac quis pellentesque morbi. Dignissim arisus id mi. Feugiat lacinia sit tempus, enim vel est.",
	profileLinkFb: "https://facebook.com",
};

export const CommentsData = [
	{
		id: 1,
		authorCommentImg: avatarAuthorComment,
		authorCommentName: "Kylian",
		dateComment: "08/11/2021",
		commentText:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra morbi vestibulum vivamus pulvinar tempor. Urna dolor est sagittis, arcu sit faucibus. Odio condimentum sit quam feugiat tellus. Tellus amet diam enim pellentesque in vel morbi. Donec sem viverra dolor purus urna eget in. Risus diam est suspendisse odio.",
	},

	{
		id: 2,
		authorCommentImg: avatarAuthorComment,
		authorCommentName: "Kylian",
		dateComment: "08/11/2021",
		commentText:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra morbi vestibulum vivamus pulvinar tempor. Urna dolor est sagittis, arcu sit faucibus. Odio condimentum sit quam feugiat tellus. Tellus amet diam enim pellentesque in vel morbi. Donec sem viverra dolor purus urna eget in. Risus diam est suspendisse odio.",
	},
	{
		id: 3,
		authorCommentImg: avatarAuthorComment,
		authorCommentName: "Kylian",
		dateComment: "08/11/2021",
		commentText:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra morbi vestibulum vivamus pulvinar tempor. Urna dolor est sagittis, arcu sit faucibus. Odio condimentum sit quam feugiat tellus. Tellus amet diam enim pellentesque in vel morbi. Donec sem viverra dolor purus urna eget in. Risus diam est suspendisse odio.",
	},
	{
		id: 4,
		authorCommentImg: avatarAuthorComment,
		authorCommentName: "Kylian",
		dateComment: "08/11/2021",
		commentText:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra morbi vestibulum vivamus pulvinar tempor. Urna dolor est sagittis, arcu sit faucibus. Odio condimentum sit quam feugiat tellus. Tellus amet diam enim pellentesque in vel morbi. Donec sem viverra dolor purus urna eget in. Risus diam est suspendisse odio.",
	},
];

export const Categoriesata = [
	{category: "Lifestyle", numberArticlePerCategory:12},
	{category: "Travel", numberArticlePerCategory: 12},
	{category: "Fashion", numberArticlePerCategory: 12},
	{category: "Branding", numberArticlePerCategory: 12},
	{category: "Music", numberArticlePerCategory: 12},
];

export const TrendingPostsData = [
	{
		id:1,
		image: trendingPost,
		postTitle: "It’s a long estabilished fact that a reader",
		datePub: "30 july 2021"
	},
	{
		id:2,
		image: trendingPost,
		postTitle: "It’s a long estabilished fact that a reader",
		datePub: "30 july 2021"
	},
	{
		id:3,
		image: trendingPost,
		postTitle: "It’s a long estabilished fact that a reader",
		datePub: "30 july 2021"
	},
	{
		id:4,
		image: trendingPost,
		postTitle: "It’s a long estabilished fact that a reader",
		datePub: "30 july 2021"
	},
	{
		id:5,
		image: trendingPost,
		postTitle: "It’s a long estabilished fact that a reader",
		datePub: "30 july 2021"
	},
]
