import axios from "axios";
import "intl";
import "intl/locale-data/jsonp/en-US";

export const DEFAULT_CURRENCY = "GMD";
export async function convertCurrency(
  montant: string,
  wanted: string = DEFAULT_CURRENCY
): Promise<string> {
  if (!["XOF", "GMD", "EUR", "GBP"].includes(wanted)) {
    wanted = "USD";
  }
  if (wanted === DEFAULT_CURRENCY) return montant;
  try {
    const result = await axios.post(
      "https://services.withvolkeno.com/api/convert/",
      {
        current_currency: DEFAULT_CURRENCY,
        desired_currency: wanted,
        amount: montant,
      }
    );
    console.log(
      "result",
      result,
      result.data.data?.result,
      parseFloat(String(result.data.data?.result))?.toFixed(2)
    );
    return parseFloat(String(result.data.data?.result))?.toFixed(2);
  } catch (error) {
    return parseFloat(String(montant))?.toFixed(2);
  }
}

export function formatAmout(amount: any, currency: any = DEFAULT_CURRENCY) {
  if (!["XOF", "GMD", "EUR", "GBP"].includes(currency)) {
    currency = "USD";
  }
  if (amount || amount === 0)
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
      // maximumFractionDigits: 2,
      // minimumFractionDigits: 0,
    }).format(Math.floor(parseFloat(amount)));
  return "-";
}
