import React from "react";
import { Location } from "react-iconly";
import { NavLink, useParams } from "react-router-dom";
import Header from "../../header/Header";
import "./Projets.css";

import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import baseUrl from "../../../Http/backend-base-url";
import { PROJECT_STATUS } from "../../../Http/global-vars";
import ImgIcon from "../../../images/icons/category.png";
import { detailProjectFront } from "../../../redux/projectsFront/projectsFrontAction";
import ProjetDatas from "../../../repositories/user/projets/DatasProjets";
import Skeleton from "../../../utils/Skeleton";
import FrontFooter from "../../frontFooter/FrontFooter";
// $(function() {

const Projets: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();
  const params = useParams<any>();
  const categorie = useSelector((s: any) => {
    const data = s.project_types?.data || [];
    return data.find((d) => d.id === parseInt(params?.id));
  });

  const projects_front = useSelector(
    (state: RootStateOrAny) => state.projects_front
  );
  const data = categorie
    ? projects_front?.data?.filter((i) => i.property_type?.id === categorie.id)
    : projects_front?.data;
  return (
    <div className="component-front-home">
      <Header />
      <div className="p-t-150 m-b-100 container-page-about-home">
        <div className="text-center border-bottom p-b-50 p-t-50">
          <h1 className="titre-page-content">
            Projects {categorie ? ` | ${categorie.name}` : ""}
          </h1>
          {!categorie && (
            <div className="col-md-8 offset-md-2 p-t-20">
              <p className="page-content-text">
                Our projects are geared towards delivering efficient and
                affordable homes. Over the years, our brand has become famous
                thanks to our firm belief in quality; we do not compromise on
                quality for whatever reason. A key signature of our estates is
                the many fruit trees that we plant, as well as the lush
                landscaping that has become an expectation for our loyal
                customers. We are a respectful company the environment and, as
                such, we are always guided by the environmental impact
                assessment report of each project.
              </p>
            </div>
          )}
        </div>
        <div>
          <div className="container-taf p-t-80">
            <div className="row">
              {projects_front?.isLoading && <ProjectSkeleton />}
              {!projects_front?.isLoading &&
                (data?.length > 0
                  ? data?.map((item: ProjetDatas) => {
                      return (
                        <div
                          key={`project-detail-${item.id}`}
                          className="col-xl-4 col-lg-6 col-md-6 pb-3"
                        >
                          <div>
                            <NavLink
                              to={`/project-details/${item.slug}`}
                              className="no-link"
                              onClick={() =>
                                dispatch(detailProjectFront(item.id))
                              }
                            >
                              <div className="card card-blog position-relative">
                                <img
                                  src={
                                    item.image === "" ||
                                    item.image === undefined ||
                                    item.image === null
                                      ? `https://ui-avatars.com/api/?name=${item?.property_name}`
                                      : `${baseUrl + item?.image}`
                                  }
                                  height="300"
                                  className="card-img-top"
                                  alt="..."
                                />
                                <div className="card-body pt-0">
                                  <div className="div-statut-projet text-uppercase">
                                    <span>
                                      {PROJECT_STATUS[item?.statut_project] ||
                                        "New"}
                                    </span>
                                  </div>
                                  <p
                                    className="card-text card-text-lastest-news pb-3"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item?.description?.slice(0, 100) +
                                        "...",
                                    }}
                                  />
                                  <div className="py-2">
                                    <p className="text-calendar-card d-flex align-items-center">
                                      <Location
                                        set="light"
                                        primaryColor="#828282"
                                      />
                                      <span className="pl-2">
                                        {item?.country_id &&
                                          item?.country_id?.title}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="py-2">
                                    <p className="text-calendar-card d-flex align-items-center">
                                      <img
                                        src={ImgIcon}
                                        alt="Icon category"
                                        className="icon-img-category"
                                      />
                                      {!!item.property_type && (
                                        <span className="pl-2">
                                          {item.property_type?.name}
                                        </span>
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </NavLink>
                          </div>
                        </div>
                      );
                    })
                  : "No project at the moment")}
            </div>
          </div>
        </div>
      </div>

      <FrontFooter />
    </div>
  );
};

export default Projets;

const ProjectSkeleton = () => {
  const data = [1, 2, 3, 4, 5, 6];
  return (
    <>
      {data.map((shopCard, i) => (
        <div className="col-xl-4 col-lg-6 col-md-6 pb-3" key={i}>
          <div className="card">
            <Skeleton
              // className="w-full h-full lh-2 from-home"
              variant="rect"
              width={`100%`}
              height={250}
            />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" width={`50%`} />
            <div className="d-flex justify-content-start text-calendar-card d-flex align-items-center">
              <Skeleton
                variant="text"
                width={50}
                height={50}
                className="mr-2"
              />

              <Skeleton variant="text" width={150} height={50} />
            </div>
            <div className="d-flex justify-content-start text-calendar-card d-flex align-items-center">
              <Skeleton
                variant="text"
                className="mr-2"
                width={50}
                height={50}
              />

              <Skeleton variant="text" width={150} height={50} />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
