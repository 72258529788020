import React from "react";
import "./Dashboard.css";
import LineChart from "./LineChart/LineChart";
import HeaderAdmin from '../headerAdmin/HeaderAdmin'
import homedashbord from '../../../images/icons/homedashbord.png'
import home3 from '../../../images/icons/home3.png'
import home4 from '../../../images/icons/home4.png'
import NavBarVertical from '../nav/NavbarVertical'
import { RootStateOrAny, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const Dashboard: React.FC = () => {

  const list_properties = useSelector((state: RootStateOrAny) => state.list_properties)
  const reservations = useSelector((state: RootStateOrAny) => state.reservations)
  const projects = useSelector((state: RootStateOrAny) => state.projects)


  const number_of_property_sales = list_properties.data.length > 0 && list_properties.data.filter((x) => x.statut_listing === "sale");
  const number_of_property_rents = list_properties.data.length > 0 && list_properties.data.filter((x) => x.statut_listing === "rent");

  return (
    <div className="container-fluid body-background-dashboard">
      <HeaderAdmin />

      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavBarVertical />
        </div>

        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header mt-4 px-md-3 px-0">
          <div className="container-fluid">
            <div className="projets-container bloc-principal-body-content">
              <div className="dashboard-card-container d-flex flex-column flex-md-row">
                <NavLink to={"/admin/properties"} className="dashboard-card">
                  <div className="dasboard-card-img-container">
                    <img src={homedashbord} alt="icone dashbord" />
                  </div>
                  <div className="dasboard-card-info">
                    <h1 className="dasboard-card-title">Total property</h1>
                    <h3 className="dasboard-card-montant text-secondary cursor-pointer">
                      {number_of_property_sales?.length} <small className="dasboard-card-title">On sale</small>
                    </h3>
                  </div>
                </NavLink>
                <NavLink to={"/admin/properties"} className="dashboard-card">
                  <div className="dasboard-card-img-container">
                    <img src={homedashbord} alt="icone dashbord"/>
                  </div>
                  <div className="dasboard-card-info">
                    <h1 className="dasboard-card-title">Total property</h1>
                    <h3 className="dasboard-card-montant text-secondary cursor-pointer">
                      {number_of_property_rents?.length} <small className="dasboard-card-title">For rent </small>
                    </h3>
                  </div>
                </NavLink>
                <NavLink to={"/admin/reservations"} className="dashboard-card">
                  <div className="dasboard-card-img-container">
                    <img src={home3} alt="icone dashbord"/>
                  </div>
                  <div className="dasboard-card-info">
                    <h1 className="dasboard-card-title">Total reservations</h1>
                    <h3 className="dasboard-card-montant text-secondary cursor-pointer">{reservations?.data?.length}</h3>
                  </div>
                </NavLink>
                <NavLink to={"/admin/projets"} className="dashboard-card">
                  <div className="dasboard-card-img-container">
                    <img src={home4} alt="icone dashbord" />
                  </div>
                  <div className="dasboard-card-info">
                    <h1 className="dasboard-card-title">Total projects</h1>
                    <h3 className="dasboard-card-montant text-secondary cursor-pointer">
                      {projects?.data?.length}
                    </h3>
                  </div>
                </NavLink>
              </div>
              <div className="dashboard-chart-container">
                <LineChart />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Dashboard;
