import { GoogleOAuthProvider } from "@react-oauth/google";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import App from "./App";
import "./index.scss";
import store from "./redux/store";
import * as serviceWorker from "./serviceWorker";

ReactGA.initialize("UA-181918543-1");
ReactGA.pageview(window.location.pathname);

ReactDOM.render(
  <Provider store={store}>
    <GoogleOAuthProvider
      clientId={
        "88149121972-jregnfuosg92sc2l45l8rl4654ars9v6.apps.googleusercontent.com"
      }
    >
      <App />
    </GoogleOAuthProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
