import ContactFormData from '../../repositories/user/ContactFormData';
import { FETCH_ADD_CONTACT_LIST, FETCH_CONTACT_LIST } from '../types';

export const contactDataReducer = (
  state = { isLoading: true, data: []},
  action: { type: any; payload: ContactFormData }
) => {
  switch (action.type) {
    case FETCH_CONTACT_LIST:
      return {
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};

export const addarticleReducer = (
    state = { isLoading: true, data: {} },
    action: { type: any; payload: any }
  ) => {
    switch (action.type) {
      case FETCH_ADD_CONTACT_LIST:
        return {
          isLoading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  };
  
//   export const updatearticleReducer = (
//     state = { isLoading: true, data: {} },
//     action: { type: any; payload: any }
//   ) => {
//     switch (action.type) {
//       case FETCH_UPDATE_ARTICLE_LIST:
//         return {
//           isLoading: false,
//           data: action.payload,
//         };
//       default:
//         return state;
//     }
//   };
