import React from "react";
import "./ProfilUser.css";
import Header from "../../header/Header";
import {
  CloseSquare,
  User,
  Bookmark,
  Logout,
  Password,
  Location
} from "react-iconly";
import FrontFooter from "../../frontFooter/FrontFooter";
import useContactForm from "./UseContactForm";
import Profil from "../../../images/profil.png";

import Listing6 from "../../../images/img4.png";

import ImgLit from "../../../images/icons/lit.png";
import ImgBain from "../../../images/icons/bain.png";
import ImgEat from "../../../images/icons/eat.png";
// $(function() {
//     $('a[href*=#]').on('click', function(e) {
//       e.preventDefault();
//       $('html, body').animate({ scrollTop: $((this).attr('href')).offset().top}, 500, 'linear');
//     });
//   });


const ProfilUser: React.FC = (props) => {
  const { register, errors } = useContactForm();

  return (
    <div className="component-front-home">
      <Header />
      <div className="p-t-150 m-b-100 container-page-about-home">
        <div className="text-center border-bottom p-b-50 p-t-50">
          <h1 className="titre-page-content">Profil</h1>
          <p className="sous-titre-page-content">Welcome to your account</p>
        </div>
        <div>
          <div className="container-taf p-t-80">
            <div>
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-12">
                  <div className="pb-5 bloc-img-profil-info">
                    <img
                      src={Profil}
                      alt="profil"
                      className="profil-user-img"
                    />
                    <div>
                      <p className="name-user-profil">Shaurya Preet</p>
                      <p
                        className="text-bloc-left-contact"
                        style={{ textAlign: "center" }}
                      >
                        Senegal, Dakar
                      </p>
                    </div>
                  </div>
                  <div className="bloc-navlink-profil">
                    <div
                      className="nav flex-column nav-pills  navtab-profil-user"
                      id="v-pills-tab"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      <a
                        className="nav-link active navlink-tab-profil-user"
                        id="v-pills-profil-tab"
                        data-toggle="pill"
                        href="#v-pills-profil"
                        role="tab"
                        aria-controls="v-pills-profil"
                        aria-selected="true"
                      >
                        <User set="bold" />
                        <span className="pl-2">My profile</span>
                      </a>
                      <a
                        className="nav-link navlink-tab-profil-user"
                        id="v-pills-listings-tab"
                        data-toggle="pill"
                        href="#v-pills-listings"
                        role="tab"
                        aria-controls="v-pills-listings"
                        aria-selected="false"
                      >
                        <Bookmark set="bold" />
                        <span className="pl-2">Bookmarked listings</span>
                      </a>
                      <a
                        className="nav-link navlink-tab-profil-user"
                        id="v-pills-password-tab"
                        data-toggle="pill"
                        href="#v-pills-password"
                        role="tab"
                        aria-controls="v-pills-password"
                        aria-selected="false"
                      >
                        <Password set="bold" />
                        <span className="pl-2">Change password</span>
                      </a>
                    </div>
                    <button className="navlink-tab-profil-user btn btn-change-mdp">
                      <Logout set="bold" />
                      <span className="pl-2">Change password</span>
                    </button>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-8 col-md-12">
                  <div className="tab-content" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="v-pills-profil"
                      role="tabpanel"
                      aria-labelledby="v-pills-profil-tab"
                    >
                      <div className="bloc-navlink-profil">
                        <h6 className="label-form-contact pb-4">My account</h6>
                        <form id="login-form" className=" onSubmit={onSubmit}">
                          <div className="row">
                            <div className="col-md-6 pb-3 no-pb-form-mobile">
                              <div className="form-group">
                                <label className="label-form-contact">
                                  Name
                                </label>
                                <input
                                  ref={register}
                                  type="text"
                                  name="name"
                                  id="name"
                                  className="form-control input-form-contact"
                                  aria-describedby="emailHelp"
                                />
                              </div>
                              {errors.name && (
                                <div className="alert alert-danger sia-alert-danger closer">
                                  {errors.name?.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 pb-3 no-pb-form-mobile">
                              <div className="form-group">
                                <label className="label-form-contact">
                                  Email
                                </label>
                                <input
                                  ref={register}
                                  type="email"
                                  name="email"
                                  id="email"
                                  className="form-control input-form-contact"
                                  aria-describedby="emailHelp"
                                />
                              </div>
                              {errors.email && (
                                <div className="alert alert-danger sia-alert-danger closer">
                                  {errors.email?.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 pb-3 no-pb-form-mobile">
                              <div className="form-group">
                                <label className="label-form-contact">
                                  Title
                                </label>
                                <input
                                  ref={register}
                                  type="text"
                                  name="subject"
                                  id="subject"
                                  className="form-control input-form-contact"
                                  aria-describedby="emailHelp"
                                />
                              </div>
                              {errors.subject && (
                                <div className="alert alert-danger sia-alert-danger closer">
                                  {errors.subject?.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 pb-3 no-pb-form-mobile">
                              <div className="form-group">
                                <label className="label-form-contact">
                                  Phone
                                </label>
                                <input
                                  ref={register}
                                  type="text"
                                  name="subject"
                                  id="subject"
                                  className="form-control input-form-contact"
                                  aria-describedby="emailHelp"
                                />
                              </div>
                              {errors.subject && (
                                <div className="alert alert-danger sia-alert-danger closer">
                                  {errors.subject?.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 pb-3 no-pb-form-mobile">
                              <div className="form-group">
                                <label className="label-form-contact">
                                  Adress
                                </label>
                                <input
                                  ref={register}
                                  type="text"
                                  name="subject"
                                  id="subject"
                                  className="form-control input-form-contact"
                                  aria-describedby="emailHelp"
                                />
                              </div>
                              {errors.subject && (
                                <div className="alert alert-danger sia-alert-danger closer">
                                  {errors.subject?.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 pb-3 no-pb-form-mobile">
                              <div className="form-group">
                                <label className="label-form-contact">
                                  Mail box
                                </label>
                                <input
                                  ref={register}
                                  type="text"
                                  name="subject"
                                  id="subject"
                                  className="form-control input-form-contact"
                                  aria-describedby="emailHelp"
                                />
                              </div>
                              {errors.subject && (
                                <div className="alert alert-danger sia-alert-danger closer">
                                  {errors.subject?.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-12 pb-3 no-pb-form-mobile">
                              <div className="form-group">
                                <label className="label-form-contact">
                                  Bio
                                </label>
                                <textarea
                                  ref={register}
                                  className="form-control input-form-contact"
                                  id="password"
                                ></textarea>
                              </div>
                            </div>
                            <div className="p-3">
                              <h6 className="label-form-contact pb-4">
                                Social account
                              </h6>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 pb-3 no-pb-form-mobile">
                              <div className="form-group">
                                <label className="label-form-contact">
                                  Facebook
                                </label>
                                <input
                                  ref={register}
                                  type="email"
                                  name="email"
                                  id="email"
                                  className="form-control input-form-contact"
                                  aria-describedby="emailHelp"
                                />
                              </div>
                              {errors.email && (
                                <div className="alert alert-danger sia-alert-danger closer">
                                  {errors.email?.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 pb-3 no-pb-form-mobile">
                              <div className="form-group">
                                <label className="label-form-contact">
                                  Twitter
                                </label>
                                <input
                                  ref={register}
                                  type="text"
                                  name="subject"
                                  id="subject"
                                  className="form-control input-form-contact"
                                  aria-describedby="emailHelp"
                                />
                              </div>
                              {errors.subject && (
                                <div className="alert alert-danger sia-alert-danger closer">
                                  {errors.subject?.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 pb-3 no-pb-form-mobile">
                              <div className="form-group">
                                <label className="label-form-contact">
                                  Linkedin
                                </label>
                                <input
                                  ref={register}
                                  type="text"
                                  name="subject"
                                  id="subject"
                                  className="form-control input-form-contact"
                                  aria-describedby="emailHelp"
                                />
                              </div>
                              {errors.subject && (
                                <div className="alert alert-danger sia-alert-danger closer">
                                  {errors.subject?.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 pb-3 no-pb-form-mobile">
                              <div className="form-group">
                                <label className="label-form-contact">
                                  Google+
                                </label>
                                <input
                                  ref={register}
                                  type="text"
                                  name="subject"
                                  id="subject"
                                  className="form-control input-form-contact"
                                  aria-describedby="emailHelp"
                                />
                              </div>
                              {errors.subject && (
                                <div className="alert alert-danger sia-alert-danger closer">
                                  {errors.subject?.message}
                                </div>
                              )}
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 pb-3">
                              <button className="btn btn-theme-submit">
                                SAVE CHANGES
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-listings"
                      role="tabpanel"
                      aria-labelledby="v-pills-listings-tab"
                    >
                      <div className="bloc-navlink-profil">
                        <div className="d-flex align-items-center w-full">
                          <div className="w-full">
                            <div className="card mb-4 card-item-listing card-item-listing-mobile">
                              <img
                                src={Listing6}
                                className="card-img-top img-listing-card-item"
                                alt="Listing"
                              />
                              <div className="card-body position-relative">
                                <h5 className="card-title title-card-item-listing">
                                  Bluebell Real Estate, apartment
                                </h5>
                                <p className="card-text span-for-sale-item">
                                  For sale
                                </p>
                                <p className="text-prix-card-item-listing">
                                  $1660
                                </p>
                                <div className="d-flex py-4 display-block-image-responsive">
                                  <div className="d-flex">
                                    <img src={ImgLit} alt="Icon" />{" "}
                                    <p className="para-text-div-border pl-2">
                                      5 beds
                                    </p>
                                  </div>
                                  <div className="mx-3 d-flex mx-none-responsive">
                                    <img src={ImgEat} alt="Icon" />{" "}
                                    <p className="para-text-div-border pl-2">
                                      1 kitchens
                                    </p>
                                  </div>
                                  <div className="d-flex">
                                    <img src={ImgBain} alt="Icon" />{" "}
                                    <p className="para-text-div-border pl-2">
                                      2 bathroom
                                    </p>
                                  </div>
                                </div>
                                <div className="mt-3">
                                  <div className="">
                                    <div className="d-flex">
                                      <Location
                                        set="light"
                                        primaryColor="#A1ABC9"
                                      />
                                      <p className="text-market-card-item-listing">
                                        Quice market Nigeria
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="width-btn">
                            <button className="btn btn-delete-listing">
                              <span className="icon-btn">
                                <CloseSquare set="light" />
                              </span>
                              <span className="pl-2">Delete</span>
                            </button>
                          </div>
                        </div>
                        <div className="d-flex align-items-center w-full">
                          <div className="w-full">
                            <div className="card mb-4 card-item-listing card-item-listing-mobile">
                              <img
                                src={Listing6}
                                className="card-img-top img-listing-card-item"
                                alt="Listing"
                              />
                              <div className="card-body position-relative">
                                <h5 className="card-title title-card-item-listing">
                                  Bluebell Real Estate, apartment
                                </h5>
                                <p className="card-text span-for-sale-item">
                                  For sale
                                </p>
                                <p className="text-prix-card-item-listing">
                                  $1660
                                </p>
                                <div className="d-flex py-4 display-block-image-responsive">
                                  <div className="d-flex">
                                    <img src={ImgLit} alt="Icon" />{" "}
                                    <p className="para-text-div-border pl-2">
                                      5 beds
                                    </p>
                                  </div>
                                  <div className="mx-3 d-flex mx-none-responsive">
                                    <img src={ImgEat} alt="Icon" />{" "}
                                    <p className="para-text-div-border pl-2">
                                      1 kitchens
                                    </p>
                                  </div>
                                  <div className="d-flex">
                                    <img src={ImgBain} alt="Icon" />{" "}
                                    <p className="para-text-div-border pl-2">
                                      2 bathroom
                                    </p>
                                  </div>
                                </div>
                                <div className="mt-3">
                                  <div className="">
                                    <div className="d-flex">
                                      <Location
                                        set="light"
                                        primaryColor="#A1ABC9"
                                      />
                                      <p className="text-market-card-item-listing">
                                        Quice market Nigeria
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="width-btn">
                            <button className="btn btn-delete-listing">
                              <span className="icon-btn">
                                <CloseSquare set="light" />
                              </span>
                              <span className="pl-2">Delete</span>
                            </button>
                          </div>
                        </div>
                        <div className="d-flex align-items-center w-full">
                          <div className="w-full">
                            <div className="card mb-4 card-item-listing card-item-listing-mobile">
                              <img
                                src={Listing6}
                                className="card-img-top img-listing-card-item"
                                alt="Listing"
                              />
                              <div className="card-body position-relative">
                                <h5 className="card-title title-card-item-listing">
                                  Bluebell Real Estate, apartment
                                </h5>
                                <p className="card-text span-for-sale-item">
                                  For sale
                                </p>
                                <p className="text-prix-card-item-listing">
                                  $1660
                                </p>
                                <div className="d-flex py-4 display-block-image-responsive">
                                  <div className="d-flex">
                                    <img src={ImgLit} alt="Icon" />{" "}
                                    <p className="para-text-div-border pl-2">
                                      5 beds
                                    </p>
                                  </div>
                                  <div className="mx-3 d-flex mx-none-responsive">
                                    <img src={ImgEat} alt="Icon" />{" "}
                                    <p className="para-text-div-border pl-2">
                                      1 kitchens
                                    </p>
                                  </div>
                                  <div className="d-flex">
                                    <img src={ImgBain} alt="Icon" />{" "}
                                    <p className="para-text-div-border pl-2">
                                      2 bathroom
                                    </p>
                                  </div>
                                </div>
                                <div className="mt-3">
                                  <div className="border-top">
                                    <div className="d-flex">
                                      <Location
                                        set="light"
                                        primaryColor="#A1ABC9"
                                      />
                                      <p className="text-market-card-item-listing">
                                        Quice market Nigeria
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="width-btn">
                            <button className="btn btn-delete-listing">
                              <span className="icon-btn">
                                <CloseSquare set="light" />
                              </span>
                              <span className="pl-2">Delete</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-password"
                      role="tabpanel"
                      aria-labelledby="v-pills-password-tab"
                    >
                      <h6 className="label-form-contact pb-4">Change my password</h6>
                        <form id="login-form" className=" onSubmit={onSubmit}">
                            <div className="row">
                                <div className="col-md-12 pb-3 no-pb-form-mobile">
                                <div className="form-group">
                                    <label className="label-form-contact">
                                    Old password
                                    </label>
                                    <input
                                    ref={register}
                                    type="password"
                                    name="name"
                                    id="name"
                                    className="form-control input-form-contact"
                                    aria-describedby="emailHelp"
                                    />
                                </div>
                                {errors.name && (
                                    <div className="alert alert-danger sia-alert-danger closer">
                                    {errors.name?.message}
                                    </div>
                                )}
                                </div>
                                <div className="col-md-6 pb-3 no-pb-form-mobile">
                                <div className="form-group">
                                    <label className="label-form-contact">
                                    New password
                                    </label>
                                    <input
                                    ref={register}
                                    type="password"
                                    name="password"
                                    id="password"
                                    className="form-control input-form-contact"
                                    aria-describedby="emailHelp"
                                    />
                                </div>
                                {errors.email && (
                                    <div className="alert alert-danger sia-alert-danger closer">
                                    {errors.email?.message}
                                    </div>
                                )}
                                </div>
                                <div className="col-md-6 pb-3 no-pb-form-mobile">
                                <div className="form-group">
                                    <label className="label-form-contact">
                                    Confim password
                                    </label>
                                    <input
                                    ref={register}
                                    type="password"
                                    name="subject"
                                    id="subject"
                                    className="form-control input-form-contact"
                                    aria-describedby="emailHelp"
                                    />
                                </div>
                                {errors.subject && (
                                    <div className="alert alert-danger sia-alert-danger closer">
                                    {errors.subject?.message}
                                    </div>
                                )}
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 pb-3">
                              <button className="btn btn-theme-submit">
                                SAVE CHANGES
                              </button>
                            </div>
                            </div>
                         </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FrontFooter />
    </div>
  );
};

export default ProfilUser;
