import moment from "moment";
import { useRef } from "react";
import { ArrowLeft } from "react-iconly";
import { Link, NavLink, useLocation } from "react-router-dom";
import baseUrl from "../../../Http/backend-base-url";
import Bathroom2 from "../../../images/icons/bathtub.png";
import Bed2 from "../../../images/icons/bed.png";
import ChatImg from "../../../images/icons/chat-listing.png";
import Kitchen2 from "../../../images/icons/kitchen.png";
import PaperImg from "../../../images/icons/paper-listing.png";
import ShowImg from "../../../images/icons/show-listing.png";
import { Amineties } from "../../user/listing/AmnetieItem";
import { Gallery } from "../../user/projets/DetailsProjet";
import PlayerVideo from "../../user/shared/PlayerVideo";
import HeaderAdmin from "../headerAdmin/HeaderAdmin";
import NavbarVertical from "../nav/NavbarVertical";
import { GoogleMap } from "./GoogleMap";

const DetailsListingAdmin = (props) => {
  const commentRef = useRef(null);
  const location = useLocation<any>();
  const property_datas = location.state?.row;
  const reviews = location.state?.reviews?.data;
  const reservations = location.state?.reservations?.data;

  const review_datas =
    reviews && reviews.filter((x: any) => x.property_id === property_datas?.id);
  const reservation_datas =
    reservations &&
    reservations.filter((x: any) => x.property_id?.id === property_datas?.id);

  const defaultProps = {
    center: {
      lat: parseFloat(property_datas?.latitude || 0),
      lng: parseFloat(property_datas?.longitude || 0),
    },
    zoom: 11,
  };

  const pictures =
    property_datas?.pictures?.length > 0 ? property_datas?.pictures : [];
  return (
    <div className="container-fluid body-background-dashboard">
      <HeaderAdmin />
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavbarVertical />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header mt-4 px-0">
          <div className="projets-container bloc-principal-body-content">
            <div className="profile-containers">
              <div className="container-profile-edit">
                <div className="d-flex align-items-center">
                  <Link to="/admin/properties" className="btn btn-add-listing">
                    <ArrowLeft set="light" primaryColor="#B0B0B0" />
                  </Link>
                  <h1 className="mon-profil pl-2">
                    {" "}
                    {property_datas?.property_name}
                    {property_datas?.property_type?.name
                      ? ", " + property_datas?.property_type?.name
                      : ""}{" "}
                  </h1>
                </div>
              </div>
              <div className="bloc-principal-body-content p-0">
                <div className="dashboard-card-container d-flex flex-column flex-md-row">
                  <div className="dashboard-card card-details-listing">
                    <div className="">
                      <img src={ShowImg} alt="icone dashbord" />
                    </div>
                    <div className="dasboard-card-info">
                      <h1 className="dasboard-card-title">Total views</h1>
                      <h3 className="dasboard-card-montant">
                        {property_datas?.vus}{" "}
                        <small className="dasboard-card-title">Views </small>
                      </h3>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      if (commentRef.current) {
                        //@ts-ignore
                        commentRef.current.scrollIntoView();
                      }
                    }}
                    className="dashboard-card card-details-listing cursor-pointer"
                  >
                    <div className="">
                      <img src={ChatImg} alt="icone dashbord" />
                    </div>
                    <div className="dasboard-card-info">
                      <h1 className="dasboard-card-title">Total comment</h1>
                      <h3 className="dasboard-card-montant">
                        {review_datas.length}{" "}
                        <small className="dasboard-card-title">
                          {review_datas.length > 1 ? "Comments" : "Comment"}{" "}
                        </small>
                      </h3>
                    </div>
                  </div>
                  <NavLink
                    className="dashboard-card card-details-listing"
                    to={{
                      pathname: `/admin/property/reservations/${property_datas?.slug}`,
                      state: { property: property_datas },
                    }}
                  >
                    <div className="">
                      <img src={PaperImg} alt="icone dashbord" />
                    </div>
                    <div className="dasboard-card-info">
                      <h1 className="dasboard-card-title">Total reservation</h1>
                      <h3 className="dasboard-card-montant">
                        {reservation_datas.length}{" "}
                        <small className="dasboard-card-title">
                          {reservation_datas.length > 1
                            ? "Reservations"
                            : "Reservation"}
                        </small>
                      </h3>
                    </div>
                  </NavLink>
                </div>
                <div className="project-container ">
                  <Gallery
                    pictures={pictures}
                    image={property_datas?.image}
                    name={property_datas?.property_name}
                  />
                  <div className="card-listing p-3 mt-3">
                    <h2 className="mid-listing-title">Detail & features</h2>
                    <div className="det-feat-container">
                      <div className="det-feat-item">
                        <img src={Bed2} alt="bed" />
                        <h6 className="det-feat-title">
                          {property_datas?.bedroom}
                          &nbsp; BEDROOM
                        </h6>
                      </div>
                      <div className="det-feat-item">
                        <img src={Bathroom2} alt="bathroom-img" />
                        <h6 className="det-feat-title">
                          {property_datas?.bathroom}
                          &nbsp; BATHROOM
                        </h6>
                      </div>
                      <div className="det-feat-item">
                        <img src={Kitchen2} alt="bathroom-img" />
                        <h6 className="det-feat-title">
                          {property_datas?.kitchen}
                          &nbsp; KITCHEN
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="card-listing p-3 mt-3">
                    <h2 className="mid-listing-title">Description</h2>
                    <div className="det-feat-container">
                      <div className="det-feat-ite">
                        <p
                          className="cla-text"
                          dangerouslySetInnerHTML={{
                            __html: property_datas?.description,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-listing mt-4 p-3">
                    <h2 className="mid-listing-title">Amenities</h2>
                    <Amineties amneties={property_datas?.ameneties} />
                  </div>
                  {!!property_datas?.video_presentation && (
                    <div className="card-listing mt-4 p-3">
                      <h2 className="mid-listing-title mb-3">Property video</h2>
                      <PlayerVideo
                        url={property_datas?.video_presentation}
                        showControls={false}
                      />
                    </div>
                  )}
                  <div className="card-listing mt-4 p-3">
                    <h2 className="mid-listing-title mb-3">Map</h2>

                    <GoogleMap defaultProps={defaultProps} />
                  </div>
                  {!!property_datas?.virtual_video && (
                    <div className="card-listing mt-3 p-3">
                      <div className="flex-sb">
                        <h2 className="mid-listing-title mb-3">
                          Virtual visit
                        </h2>
                      </div>
                      <PlayerVideo
                        url={property_datas?.virtual_video}
                        showControls={false}
                      />
                    </div>
                  )}
                  <div ref={commentRef} className="card-listing mt-3 p-3">
                    <h2 className="mid-listing-title mb-3">
                      Comments ({review_datas.length})
                    </h2>
                    <div className="container-taf">
                      <div className="row">
                        {review_datas &&
                          review_datas.map((review_data: any) => {
                            return (
                              <div className="col-12">
                                <div className="bloc-div-item-service">
                                  <div>
                                    <img
                                      src={
                                        review_data?.image
                                          ? `${baseUrl + review_data?.image}`
                                          : `https://ui-avatars.com/api/?name=${review_data?.name}`
                                      }
                                      alt="service"
                                      className="img-service"
                                    />
                                  </div>
                                  <div className="pl-3">
                                    <div className="d-flex">
                                      <h3 className="titre-section-service">
                                        {review_data?.name} <br />
                                        <span style={{fontSize: 12,opacity: .6}}>{review_data?.email}</span> <br />
                                        <span className="text-date-listing w-100">
                                          {moment(
                                            review_data.created_at
                                          ).format("YYYY-MM-DD")}
                                        </span>
                                      </h3>
                                    </div>
                                    <p className="text-section-services">
                                      {review_data?.message}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DetailsListingAdmin;
