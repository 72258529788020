import { yupResolver } from "@hookform/resolvers/yup";
import Axios from "axios";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import React, { useEffect } from "react";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { useForm } from "react-hook-form";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import baseUrl from "../../../Http/backend-base-url";
import api from "../../../Http/global-vars";
import arrowback from "../../../images/arrowback.png";
import groupphoto from "../../../images/Groupphoto.png";
import { articleList } from "../../../redux/Article/articleAction";
import { ArticleFormData } from "../../../repositories/user/ArticleFormData";
import HeaderAdmin from "../headerAdmin/HeaderAdmin";
import { ContentEditor } from "../ListingAdmin/ListingDetailsDeBaseSecondStep";
import NavbarVertical from "../nav/NavbarVertical";

function ArticledetailUpdate() {
  const userInfo = useSelector((state: RootStateOrAny) => state.user?.data);
  const [submitedForm, setSubmitedForm] = React.useState(false);
  const [isSuccessfullySubmittedForm, setIsSuccessfullySubmittedForm] =
    React.useState(false);
  const [image, setImage] = React.useState<any>();
  const [imageDefault, setImageDefault] = React.useState<any>();
  const [hideImageDefault, setHideImageDefault] = React.useState<boolean>();

  const dispatch = useDispatch();

  const location = useLocation<any>();
  const article_datas = location.state?.row;

  const validationSchema = yup.object().shape({
    title: yup.string().required("This field is required"),
    content: yup.string().required("This field is required"),
    category: yup.string().required("This field is required"),
  });
  const { register, handleSubmit, reset, formState, setValue } =
    useForm<ArticleFormData>({
      mode: "onBlur",
      resolver: yupResolver(validationSchema),
    });
  React.useEffect(() => {
    register("content");
    if (article_datas) {
      setValue("content", article_datas.content);
      setValue("title", article_datas.title);
      setValue("category", article_datas.category?.id);
      setValue("writen_by", article_datas.writen_by);
    }
  }, [article_datas]);

  useEffect(() => {
    if (article_datas?.id) {
      setImageDefault(article_datas?.image);
    }
  }, [article_datas?.id, article_datas?.image]);

  const history = useHistory();

  const { errors } = formState;

  const token = window.localStorage.getItem("userToken");
  const userID = window.localStorage.getItem("userID");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const cat_article_data = useSelector(
    (state: RootStateOrAny) => state.category_article
  );

  const getFilesFromEvent = async (e) => {
    const chosenFiles = await getDroppedOrSelectedFiles(e);
    return chosenFiles.map((f) => f.fileObject);
  };
  const InputChooseFile = ({ accept, onFiles, files, getFilesFromEvent }) => {
    const text =
      files.length === 0 ? (
        // 'Ajouter  des photos' :
        <div className="start-label-input-choose-file">
          <div className="label-img-container pt-3">
            <img src={groupphoto} className="label-image" alt="icone-label" />
          </div>
          <span className="first_label-input-choose-file">
            Déposez une image ici ou sélectionnez un fichier.
          </span>
          <span className="second-label-input-choose-file">
            Format acceptés : JPG, PNG, GIF, TIFF ou BMP, inférieurs à 200Mo.
          </span>
        </div>
      ) : (
        <div
          className="btn-taf-add"
          style={{
            cursor: "pointer",
            float: "right",
          }}
          onClick={(e) => e.preventDefault()}
        >
          Add more picture
        </div>
        // <div
        // 	className="row"
        // 	style={{ cursor: "pointer", float: "right" }}
        // 	onClick={(e) => e.preventDefault()}
        // >
        // 	ADD
        // </div>
      );
    let buttonStartStyle: any = {
      background: "#rgb(243, 243, 243);",
      cursor: "pointer",
      borderRadius: 10,
      marginTop: 10,
      textAlign: "center",
      maxWidth: "100%",
      color: "#fff",
    };

    if (files.length <= 0) {
      buttonStartStyle = {
        // color: '#ff9800',
        cursor: "pointer",
        padding: 15,
        borderRadius: 30,
        marginTop: 10,
        textAlign: "center",
      };
    }

    return (
      <div className="upload-dropzone-row">
        <div style={buttonStartStyle} className="col-md-11">
          <label htmlFor="dropzoneImage">{text}</label>
          <input
            style={{
              opacity: 0,
              cursor: "pointer",
            }}
            className="file-input"
            type="file"
            name="image"
            multiple
            accept={accept}
            onChange={async (e) => {
              const chosenFiles: File[] = await getFilesFromEvent(e);
              if (chosenFiles[0]) {
                setHideImageDefault(true);
              } else {
                setHideImageDefault(false);
              }
              setImage(chosenFiles[0]);
              onFiles(chosenFiles);
            }}
            id="dropzoneImage"
          />
        </div>
      </div>
    );
  };

  const submitUpdateNews = (data: ArticleFormData) => {
    setIsSuccessfullySubmittedForm(true);
    setSubmitedForm(true);
    const fd = new FormData();
    fd.append("title", data.title);
    fd.append("writen_by", data.writen_by || userInfo.fullname);
    fd.append("content", data.content);
    fd.append("category", data.category);
    if (image) {
      fd.append("image", image);
    }
    fd.append("created_by", userID ? userID : "null");

    const request = Axios.put(`${api}article/${article_datas.id}/`, fd, config);

    request
      .then((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            html: "News update successfully!",
            iconColor: "#df5656",
            showCancelButton: false,
            confirmButtonColor: "#df5656",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              // $('.modal').modal('hide');
              history.push("/admin/articles");
              reset();
            }
          });
          dispatch(articleList());
          setIsSuccessfullySubmittedForm(false);
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          title: "Please check the data entered.",
          showConfirmButton: true,
          allowOutsideClick: false,
        });
        setIsSuccessfullySubmittedForm(false);
      });
  };

  return (
    <>
      <div className="container-fluid body-background-dashboard">
        <HeaderAdmin />
        <div className="row">
          <div className="nav-container no-view-mobile">
            <NavbarVertical />
          </div>
          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header mt-4 px-0">
            <div
              className="projets-container bloc-principal-body-content"
              data-testid={submitedForm === false ? "FormSubmittedFail" : ""}
            >
              <div
                className="profile-containers"
                data-testid={submitedForm === true ? "FormSubmittedDone" : ""}
              >
                <Link to="/admin/articles">
                  <h1 className="mon-profil">
                    <img src={arrowback} alt="parametre" />
                    Update news
                  </h1>
                </Link>
                <div className="mt-3">
                  <form
                    className="form-input-parametre"
                    onSubmit={handleSubmit(submitUpdateNews)}
                  >
                    <div className="container-input-address">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="infos-perso-input">
                            <label className="form-label-text">Title</label>
                            <input
                              type="text"
                              className="form-control-profil"
                              placeholder="title"
                              name="title"
                              ref={register}
                              data-testid="titleId"
                            />
                            {errors.title && (
                              <div className="alert alert-danger sia-alert-danger closer mt-2">
                                {errors.title?.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="infos-perso-input">
                            <label className="form-label-text">
                              Written by
                            </label>
                            <input
                              type="text"
                              className="form-control-profil"
                              placeholder="Writen by"
                              name="writen_by"
                              ref={register}
                              data-testid="writenbyId"
                            />
                            {errors.writen_by && (
                              <div className="alert alert-danger sia-alert-danger closer mt-2">
                                {errors.writen_by?.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="infos-perso-input">
                            <label className="form-label-text">Category</label>
                            <select
                              className="form-control-profil"
                              id="exampleFormControlSelect1"
                              name="category"
                              ref={register}
                              data-testid="categoryId"
                            >
                              {cat_article_data.data.map((item) => (
                                <option value={item?.id} key={item?.id}>
                                  {item?.name}
                                </option>
                              ))}
                            </select>
                            {errors.category && (
                              <div className="alert alert-danger sia-alert-danger closer mt-2">
                                {errors.category?.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="infos-perso-input">
                            <label className="form-label-text">Content</label>
                            <ContentEditor
                              data={article_datas?.content}
                              setValue={setValue}
                              name="content"
                            />
                            {errors.content && (
                              <div
                                className="alert alert-danger sia-alert-danger closer mt-3"
                                role="alert"
                              >
                                {errors.content?.message}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group-add-project-group-container row">
                        <div className="form-add-project-group form-group-add-project-dropzone-container pl-3">
                          <label
                            htmlFor="projectImage"
                            className="form-add-project-label"
                          >
                            News imagess
                          </label>
                          <Dropzone
                            getUploadParams={() => ({
                              url: "https://httpbin.org/post",
                            })}
                            InputComponent={InputChooseFile}
                            getFilesFromEvent={getFilesFromEvent}
                            accept="image/*"
                            maxFiles={100}
                            styles={{
                              dropzone: {
                                minHeight: 150,
                                maxHeight: 300,
                                border: "2px dashed #d9d9d9",
                                background: "#F3F3F3",
                                overflow: "hidden",
                                opacity: 1,
                              },
                            }}
                          />
                          {!hideImageDefault && (
                            <img
                              src={imageDefault && baseUrl + imageDefault}
                              alt=""
                              height="150"
                              width="150"
                              className="my-2"
                            />
                          )}
                        </div>
                      </div>
                      <div className="d-flex justify-content-start mt-5">
                        {!isSuccessfullySubmittedForm && (
                          <button
                            className="btn-taf-primary"
                            type="submit"
                            data-testid="submitBtnId"
                          >
                            Submit
                          </button>
                        )}

                        {isSuccessfullySubmittedForm && (
                          <span className="btn btn-taf-primary trans-0-2">
                            <i className="fas fa-spin fa-spinner"></i>
                            &nbsp;&nbsp;&nbsp;In progress...
                          </span>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ArticledetailUpdate;
