import moment from "moment";
import React from "react";
import { ArrowRight, Calendar } from "react-iconly";
import { RootStateOrAny, useSelector } from "react-redux";
import { Link, NavLink, useParams } from "react-router-dom";
import baseUrl from "../../../Http/backend-base-url";
import FrontFooter from "../../frontFooter/FrontFooter";
import Header from "../../header/Header";
import { useScrollToTop } from "../projets/DetailsProjet";
import "./Blog.css";

const Blog: React.FC = () => {
  const [filtred, setFiltred] = React.useState<any[]>([]);
  const [search, setSearch] = React.useState("");
  useScrollToTop();
  const { slug } = useParams<any>();
  const articles_front = useSelector(
    (state: RootStateOrAny) => state.articles_front?.data
  );

  React.useEffect(() => {
    let filtred = [...articles_front];
    if (slug) {
      filtred = filtred.filter((f) => f.category?.name === slug);
    }
    if (search.length > 0) {
      setFiltred(
        filtred?.filter((f) =>
          f?.title?.toLowerCase()?.includes(search.toLowerCase())
        )
      );
    } else {
      setFiltred(filtred);
    }
  }, [search, articles_front, slug]);

  return (
    <div className="component-front-home">
      <Header />
      <div className="p-t-150 m-b-100 container-page-about-home">
        <div className="text-center border-bottom p-b-50 p-t-50">
          <h1 className="titre-page-content">Blog</h1>
          <p className="sous-titre-page-content">
            {slug || "See our latest articles & news"}
          </p>
        </div>
        <div>
          <div className="container-taf p-t-80">
            <div className="row d-flex justify-content-end">
              <div className="col-md-3">
                <form>
                  <div className="custom-file mb-3">
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search..."
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </form>
              </div>
            </div>

            <div className="row">
              {filtred.length > 0 ? (
                filtred.map((article) => {
                  return <BlogCard key={article.id} item={article} />;
                })
              ) : (
                <h1>No Articles</h1>
              )}
            </div>
          </div>
        </div>
      </div>

      <FrontFooter />
    </div>
  );
};

export default Blog;

export const BlogCard = ({ item: article }) => {
  const { category } = article;
  return (
    <div className="col-xl-4 col-lg-6 col-md-6 d-flex pb-3">
      <div className="w-full">
        <NavLink
          to={{
            pathname: `/blog/${article?.slug}`,
            state: { article },
          }}
          className="no-link"
        >
          <div className="card card-blog">
            <img
              height="391"
              width="303"
              src={
                article?.image === null
                  ? `https://ui-avatars.com/api/?name=${article?.title}`
                  : `${baseUrl + article?.image}`
              }
              alt="avatar"
              className="card-img-top img-cover"
            />
            <div className="card-body pt-0">
              <p className="text-calendar-card d-flex align-items-center mt-3">
                <Calendar set="light" primaryColor="#828282" />
                <span className="pl-2">
                  {moment(article?.created_at).format("DD MMM YYYY")}
                </span>
                {!!category && (
                  <Link
                    style={{
                      color: "#cc1b0b",
                      cursor: "pointer",
                    }}
                    to={`/blog/category/${category.name}`}
                    className="ml-2"
                  >
                    <small>{category.name}</small>
                  </Link>
                )}
              </p>

              <h5 className="card-title card-title-lastest-news py-2 mt-3">
                {article?.title ?? ""}
              </h5>
              {/* <p
                className="card-text card-text-lastest-news pb-3"
                dangerouslySetInnerHTML={{
                  __html: article?.content.substring(1, 100) + "...",
                }}
              /> */}
              <NavLink
                to={{
                  pathname: `/blog/${article?.slug}`,
                  state: { article },
                }}
                className="btn btn-continue-card pt-3 pl-0 trans-0-2"
              >
                <span className="pr-2">Continue</span>
                <ArrowRight set="light" />
              </NavLink>
            </div>
          </div>
        </NavLink>
      </div>
    </div>
  );
};
