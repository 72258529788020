import Axios from "axios";
import api from "../../Http/global-vars";
import DatasListings from "../../repositories/user/listings/DatasListings";
import { FETCH_LIST_PROPERTY, UPDATE_PROPERTY } from "../types";

export const listProperties = () => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    const token = localStorage.getItem("userToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const url = api + "listing/";
    if (token) {
      await fetch(url, config)
        .then(async (res) => {
          if (res?.status === 401) {
            localStorage.removeItem("userToken");
            localStorage.removeItem("userID");
            localStorage.clear();
            //@ts-ignore
            window.location = "/";
          } else {
            const data = await res.json();
            // console.log("data results", data.results)
            dispatch({
              type: FETCH_LIST_PROPERTY,
              payload: data.results,
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };
};

export const updateProperty = (id: DatasListings) => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const token = window.localStorage.getItem("userToken");
    const url = api + "listing/" + id;
    if (token) {
      await fetch(url, config)
        .then(async (res) => {
          const data = await res.json();
          // console.log("data results idididid", data)
          dispatch({
            type: UPDATE_PROPERTY,
            payload: data,
            // ,
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  };
};

export const listPropertiesDataToTest = async () => {
  try {
    return await Axios.get(`${api}listing`);
  } catch (e) {
    return [];
  }
};

export const listPropertiesDataFrontToTest = async () => {
  try {
    return await Axios.get(`${api}listingbyvisitor`);
  } catch (e) {
    return [];
  }
};
