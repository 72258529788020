import Axios from "axios";
import React, { useState } from "react";
import { useStep } from "react-hooks-helper";
import * as IconlyPack from "react-iconly";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../../Http/global-vars";
import { fetchProjects } from "../../../redux/projects/projectAction";
import ProjetDatas from "../../../repositories/user/projets/DatasProjets";
import HeaderAdmin from "../headerAdmin/HeaderAdmin";
import NavbarVertical from "../nav/NavbarVertical";
// import ContactInformation from "./ContactInformation";
import DetailDeBase from "./DetailDeBase";
import DetailsDeBaseSecondStep from "./DetailsDeBaseSecondStep";

const steps = [
  { id: "detail-projet-1", Component: DetailDeBase },
  { id: "detail-projet-2", Component: DetailsDeBaseSecondStep },
];

const AjouterProjet = ({ project }: { project?: ProjetDatas }) => {
  const [error, setError] = React.useState<any>(null);
  const [pics, setPics] = useState<any>(project?.pictures || []);
  const onDeleteImage = (img: any) => {
    const id = img.id;
    if (id) {
      const token = window.localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      Swal.fire({
        title: "Do you want to delete this image ?",
        // showDenyButton: true,
        confirmButtonText: `YES`,
        cancelButtonText: `NO`,
        iconColor: "#df5656",
        showCancelButton: true,
        confirmButtonColor: "#df5656",
        cancelButtonColor: "#6c757d",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          Axios.delete(`${api}picture/${id}/`, config)
            .then((response) => {
              dispatch(fetchProjects());
              setPics((old: any) => old.filter((f: any) => f !== img));
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Image deleted successfully",
                showConfirmButton: true,
                iconColor: "#df5656",
                confirmButtonColor: "#df5656",
                allowOutsideClick: false,
              });
            })
            .catch((error) => {
              console.log(error);
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Oops! An error occurred while deleting",
                showConfirmButton: true,
                iconColor: "#df5656",
                confirmButtonColor: "#df5656",
                allowOutsideClick: false,
              });
            });
        }
      });
    } else {
      setPics((old: any) => old.filter((f: any) => f !== img));
    }
  };
  const { step, navigation, index } = useStep({ initialStep: 0, steps });
  const [formData, setFormData] = useState<Array<Partial<ProjetDatas>>>(
    project
      ? [
          {
            property_name: project.property_name,
            building_age: project.building_age,
            min_price: project.min_price,
            max_price: project.max_price,
            property_type: project.property_type?.id,
            virtual_video: project.virtual_video,
            video_presentation: project.video_presentation,
            country_id: project.country_id?.id,
            location_id: project.location_id?.id,
            image: project?.image,
          },
          {
            description: project.description,
            ameneties: project.ameneties.map(String),
            latitude: project.latitude,
            longitude: project.longitude,
            city: project.city,
          },
        ]
      : []
  );
  const [finishedStep, setFinishedStep] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const props = {
    formData,
    setFormData,
    finishedStep,
    setFinishedStep,
    navigation,
    pics,
    setPics,
    onDeletePics: onDeleteImage,
  };
  const { Component } = step;

  const handleSubmit = () => {
    const token = window.localStorage.getItem("userToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const fd = new FormData();

    formData.map((item: Partial<ProjetDatas>) => {
      if (item.image && item.image !== project?.image) {
        fd.append("image", item.image);
      }
      if (item.description !== undefined) {
        fd.append("description", item.description);
      }

      if (item.property_name !== undefined) {
        fd.append("property_name", item.property_name);
      }
      if (item.location_id !== undefined) {
        fd.append("location_id", item.location_id);
      }
      if (item.ameneties?.length) {
        item.ameneties.map((item: any) => {
          fd.append("ameneties", item);
        });
      }
      if (item.statut_listing !== undefined) {
        fd.append("statut_listing", item.statut_listing);
      }
      if (item.min_price !== undefined) {
        fd.append("min_price", item?.min_price);
      }
      if (item.max_price !== undefined) {
        fd.append("max_price", item?.max_price);
      }
      fd.append("price", "0");

      fd.append("area", "area");
      if (item?.building_age !== undefined) {
        fd.append("building_age", item?.building_age);
      }
      if (item?.city !== undefined) {
        fd.append("city", item?.city);
      }

      if (item.property_type !== undefined) {
        fd.append("property_type", item?.property_type);
      }

      if (item.country_id !== undefined) {
        fd.append("country_id", item.country_id);
      }
      fd.append("postal_code", "000");
      fd.append("room", "0");
      if (item.virtual_video !== undefined) {
        fd.append("virtual_video", item.virtual_video);
      }
      if (item.video_presentation !== undefined) {
        fd.append("video_presentation", item.video_presentation);
      }
      if (item.latitude !== undefined) {
        fd.append("latitude", item.latitude);
      }
      if (item.longitude !== undefined) {
        fd.append("longitude", item.longitude);
      }
    });
    let request: any;

    if (project) {
      request = Axios.put(`${api}project/${project.id}/`, fd, config);
    } else {
      request = Axios.post(`${api}project/`, fd, config);
    }

    request
      .then(async (res) => {
        const id = res.data.id;
        try {
          const promises = pics.map((pic) => {
            const form = new FormData();
            form.append("label", pic.label);
            if (pic.image instanceof File) {
              form.append("image", pic.image);
            }
            if (pic.id) {
              return Axios.put(`${api}picture/${pic.id}/`, form, config);
            } else {
              return Axios.post(`${api}addpictureprojet/${id}/`, form, config);
            }
          });
          await Promise.all(promises);
        } catch (ex) {
          console.log("error pictures", ex);
        }
        Swal.fire({
          icon: "success",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          title: `Project ${project ? "updated" : "created"} successfully.`,
          showConfirmButton: true,
          allowOutsideClick: false,
        }).then((res) => {
          if (res.isConfirmed) {
            history.push("/admin/projets/");
          }
        });
        dispatch(fetchProjects());
        setFinishedStep(false);
      })
      .catch((error) => {
        const errors = error.response?.data;
        if (errors) {
          setError(
            Object.keys(errors)
              .map((key) => {
                if (Array.isArray(errors[key])) {
                  return key + ": " + errors[key][0];
                }
                return "";
              })
              .filter((f) => f)
              .join("<br>")
          );
        } else {
          setError(error?.message);
        }
        Swal.fire({
          icon: "error",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          title: "Please check the data entered.",
          showConfirmButton: true,
          allowOutsideClick: false,
        });
        setFinishedStep(false);
      });
  };

  return (
    <div className="container-fluid body-background-dashboard">
      <HeaderAdmin />
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavbarVertical />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header mt-4 px-0">
          <div className="projets-container bloc-principal-body-content">
            <div className="profile-containers">
              <div className="add-project-info-stepper row">
                <div className="add-project-info col-md-6">
                  <div className="add-project-info-icon-title-container">
                    {index > 0 && (
                      <span
                        className="add-project-icon"
                        onClick={navigation.previous}
                      >
                        <IconlyPack.ArrowLeft />
                      </span>
                    )}
                    <h1 className="add-project-general-title">
                      {!project ? "Add a project" : "Update a project"}
                    </h1>
                  </div>
                  <h3 className="add-project-component-title">
                    {index === 2
                      ? "Presentation of project"
                      : "Basic information"}
                  </h3>
                </div>
                <div className="add-project-stepper col-md-6">
                  <div
                    className={`stepper ${index >= 0 && "stepper-active"}`}
                  ></div>
                  <div
                    className={`stepper ${index >= 1 && "stepper-active"}`}
                  ></div>
                </div>
                {!!error && (
                  <div
                    className="alert alert-danger my-2 cursor-pointer"
                    onClick={() => setError(null)}
                    dangerouslySetInnerHTML={{ __html: error }}
                  />
                )}
              </div>
              <Component
                {...props}
                handleSubmit={handleSubmit}
                project={project}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AjouterProjet;
