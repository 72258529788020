import api from "../../Http/global-vars";
import { FETCH_FEATURED_PROJECT_OR_PROPERTY } from "../types";

export const fetchFeaturedProjectOrProperty = () => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    const url = api + "allfeatured/";

    await fetch(url)
      .then(async (res) => {
        const data = await res.json();
        dispatch({
          type: FETCH_FEATURED_PROJECT_OR_PROPERTY,
          payload: data,
          // ,
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};
