import React from "react";
import { ChevronDown, Notification } from "react-iconly";
import { RootStateOrAny, useSelector } from "react-redux";
import { Link, NavLink, useHistory } from "react-router-dom";
import baseUrl from "../../../Http/backend-base-url";
import Logo from "../../../images/logo.png";
import { RootState } from "../../../redux/store";
import "./HeaderAdmin.scss";
const HeaderAdmin: React.FC = () => {
  const [showNotifications, setShowNotifications] = React.useState(false);
  const count = useSelector(
    (s: RootState) => s.reservations.data?.filter((r) => !r.read)?.length
  );
  const user = useSelector((state: RootStateOrAny) => state.user);

  const history = useHistory();
  const logout = (e) => {
    e.preventDefault();
    localStorage.removeItem("userToken");
    window.localStorage.clear();
    // @ts-ignore
    window.location = "/login";
  };

  return (
    <nav className="empty-fluidy navbar navbar-expand-md navbar-dark fixed-top">
      <div className="headering-admin">
        <div className="logo-item d-flex align-items-center">
          <div className="logo-container dashboard-header-logo">
            <Link to={"/"}>
              <img
                src={Logo}
                className="logo-img"
                alt="logo-taf"
                style={{ width: "60px", height: "60px" }}
              />
            </Link>
          </div>
          <div>
            <p className="logo-texte pl-3">Africa Global</p>
          </div>
        </div>

        <div className="header-others-items">
          <div />
          <div
            className="notification-item-container linkable"
            onClick={() => {
              history.push("/admin/reservations");
            }}
          >
            <div className="notification-item-content">
              <Notification set="light" primaryColor="black" />
              <span className="notification-counter">{count}</span>
            </div>
          </div>
          <div className="connected-user-container">
            <div className="connected-user-content ml-auto no-view-mobile">
              <div className="dropdown dis-flex">
                <button
                  className="user-acount-dashboard-header"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="user-acount-dashboard-header-display">
                    <img
                      src={
                        user.data.avatar === "/mediafiles/avatars/default.png"
                          ? `https://ui-avatars.com/api/?name=${user.data?.fullname}`
                          : `${baseUrl + user.data.avatar}`
                      }
                      width="45"
                      className="user-avatar"
                      alt="User Avatar"
                    />
                    <ChevronDown set="light" />
                  </div>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-custom"
                  aria-labelledby="dropdownMenuButton"
                >
                  <NavLink
                    to="/admin/profiles"
                    className="dropdown-item item-dropdown-custom"
                  >
                    {user?.data?.fullname ?? "no data"}
                  </NavLink>
                  <div className="dropdown-divider"></div>
                  <button
                    type="button"
                    className="dropdown-item item-dropdown-custom"
                    onClick={(e) => logout(e)}
                  >
                    {" "}
                    Deconnexion
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hiddenable-nav-mobile-container no-view-desktop">
        <div className="hiddenable-nav-mobile-content">
          {/* <NavBarMobileV2 /> */}
        </div>
      </div>

      {showNotifications && (
        <div className={"header-hiddenable-notification-section"}>
          <div className="header-hiddenable-notification-title">
            Notifications
          </div>

          <ul className="header-hiddenable-notification-ul">
            <li className="header-hiddenable-notification-li">
              <NavLink to="#" className="notification-item-link">
                <p>Notif</p>
                <span>12/12/2021</span>
              </NavLink>
            </li>

            {/* <li className="header-hiddenable-notification-li">
                <NavLink to="#" className="notification-item-link">
                  <p>Nouvelle facture de Volkeno</p>
                  <span>19/04/2021</span>
                </NavLink>
              </li>
              <li className="header-hiddenable-notification-li">
                <NavLink to="#" className="notification-item-link">
                  <p>Nouvelle facture de Volkeno</p>
                  <span>19/04/2021</span>
                </NavLink>
              </li>
              <li className="header-hiddenable-notification-li"> 
                <NavLink to="#" className="notification-item-link">
                  <p>Nouvelle facture de Volkeno</p>
                  <span>19/04/2021</span>
                </NavLink>
              </li>*/}
          </ul>
        </div>
      )}
    </nav>
  );
};

export default HeaderAdmin;
