import { useSelector } from "react-redux";
import {
  applyMiddleware,
  //  compose,
  combineReducers,
  createStore,
} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { DEFAULT_CURRENCY } from "../utils/convert_currency";
import { aboutsReducer, aboutsfrontReducer } from "./AboutAdmin/AboutReducer";
import {
  ourTeamMemberFrontReducer,
  ourTeamMemberReducer,
} from "./AddNewMemberSetting/ourTeamMemberReducer";
import { agenceReducer } from "./Agence/agenceReducer";
import {
  articleDataReducer,
  articleDatasFrontReducer,
} from "./Article/articleReducer";
import { grapheDataReducer } from "./Graphe/GrapheReducer";
import {
  listPropertyReducer,
  updatePropertyReducer,
} from "./ListProperty/listPropertyReducer";
import { listPropertiesFrontReducer } from "./ListPropertyToFront/listPropertyToFrontReducer";
import {
  locationsFrontReducer,
  locationsReducer,
} from "./Location/LocationReducer";
import { newsletterAdminReducer } from "./NewsletterAdmin/NewsletterAdminReducer";
import { partnerFrontReducer, partnerReducer } from "./Partner/PartnerReducer";
import { projectTypesReducer } from "./ProjectTypes/ProjectTypesReducer";
import { promoteReducer } from "./Promote/PromoteReducer";
import {
  addpropertyTypeReducer,
  propertyTypeReducer,
  updatepropertyTypeReducer,
} from "./PropertyType/propertyTypeReducer";
import { propertyTypeFrontReducer } from "./PropertyTypeFront/propertyTypeFrontReducer";
import { reservationsReducer } from "./Reservations/reservationReducer";
import { responseContactDataReducer } from "./ResponseContact/ResponseContactReducer";
import { ShowOfFrontReducer, ShowOfReducer } from "./ShowOf/ShowOfReducer";
import { sliderFrontReducer, sliderReducer } from "./Slider/SliderReducer";
import {
  socialMediaFrontReducer,
  socialMediaReducer,
} from "./SocialMedia/SocialMediaReducer";
import {
  TestimonialsReducer,
  testimonialsFrontReducer,
} from "./Testimonials/TestimonialsReducer";
import { adminsReducer } from "./admins/adminsReducer";
import {
  amenetieFrontsReducer,
  amenetieReducer,
} from "./ameneties/amenetieReducer";
import { categoryArticleDataReducer } from "./categoryArticles/categoryArticlesReducer";
import { contactDataReducer } from "./contact/contactReducer";
import { countriesDataReducer } from "./countries/countriesReducer";
import { allfeaturedFrontReducer } from "./featuredProjectOrProperty/featuredProjectOrPropertyReducer";
import { getDatasLocationForConnectedUserReducer } from "./getLocationForConnectedUser/getLocationForConnectedUserReducer";
import {
  projectsReducer,
  updateProjectReducer,
} from "./projects/projectReducer";
import {
  detailProjectFrontReducer,
  projectsFrontReducer,
} from "./projectsFront/projectsFrontReducer";
import {
  reviewsFrontReducer,
  reviewsPropertyReducer,
} from "./reviews/reviewsReducer";
import {
  tafDataReducer,
  tafDatafrontReducer,
} from "./tafDataAdmin/tafDataReducer";
import {
  timelineReducer,
  timelinefrontReducer,
} from "./timelineAdmin/TimeLineReducer";
import { userReducer } from "./user/userReducers";

const middlewares = [thunk];
const middlewareEnhancer = applyMiddleware(...middlewares);

const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);

const store = createStore(
  combineReducers({
    user: userReducer,
    adminuser: adminsReducer,
    list_properties: listPropertyReducer,
    update_property_datas: updatePropertyReducer,
    property_types: propertyTypeReducer,
    addproperty_types: addpropertyTypeReducer,
    updateproperty_types: updatepropertyTypeReducer,
    property_types_front: propertyTypeFrontReducer,
    list_properties_front: listPropertiesFrontReducer,
    agences: agenceReducer,
    projects: projectsReducer,
    detail_project_front: detailProjectFrontReducer,
    update_project_datas: updateProjectReducer,
    projects_front: projectsFrontReducer,
    ameneties: amenetieReducer,
    amenetiesToFront: amenetieFrontsReducer,
    reviews: reviewsFrontReducer,
    reservations: reservationsReducer,
    review_property: reviewsPropertyReducer,
    allfeatured: allfeaturedFrontReducer,
    project_types: projectTypesReducer,
    locations: locationsReducer,
    locations_front: locationsFrontReducer,
    about: aboutsReducer,
    about_front: aboutsfrontReducer,
    timeline: timelineReducer,
    timeline_front: timelinefrontReducer,
    tafData: tafDataReducer,
    tafData_front: tafDatafrontReducer,
    promote: promoteReducer,
    our_team_member: ourTeamMemberReducer,
    our_team_member_front: ourTeamMemberFrontReducer,
    testimonial: TestimonialsReducer,
    testimonials_front: testimonialsFrontReducer,
    socialmedias: socialMediaReducer,
    socialmediasfront: socialMediaFrontReducer,
    partners: partnerReducer,
    partnersFront: partnerFrontReducer,
    article: articleDataReducer,
    category_article: categoryArticleDataReducer,
    countries: countriesDataReducer,
    sliders: sliderReducer,
    sliders_front: sliderFrontReducer,
    showof: ShowOfReducer,
    showofFront: ShowOfFrontReducer,
    articles_front: articleDatasFrontReducer,
    contact: contactDataReducer,
    responseContact: responseContactDataReducer,
    localisation_of_user: getDatasLocationForConnectedUserReducer,
    grapheData: grapheDataReducer,
    newsletterAdmin: newsletterAdminReducer,
  }),
  composedEnhancers
);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;

function useOldCurrency(): string {
  const currency = useSelector(
    (state: RootState) => state.localisation_of_user
  );
  if (currency) return currency;
  return DEFAULT_CURRENCY;
}

export function useCurrency() {
  const currency = useOldCurrency();
  if (!["XOF", "GMD", "EUR", "GBP"].includes(currency)) {
    return "USD";
  }
  return currency;
}
