import { Delete } from "react-iconly";
import React from "react";
import { NavLink } from "react-router-dom";
import { EditTwoTone } from "@material-ui/icons";
import "react-responsive-modal/styles.css";
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import AddAmeneties from "./ModalParametre/AddAmeneties";
import api from "../../../Http/global-vars";
import Axios from "axios";
import Swal from "sweetalert2";
import { ameneties } from "../../../redux/ameneties/amenetieAction";
import AmenitySkeleton from "./skeletons/AmenitySkeleton";

function AmenetiesTable() {
  const amenetie = useSelector((state: RootStateOrAny) => state.ameneties);
  const dispatch = useDispatch();
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
    },
  };
  const onDelete = (item: any) => {
    // let token = window.sessionStorage.getItem("userToken");
    Swal.fire({
      title: "Are you sure you want to delete this amenity?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirmer",
      cancelButtonText: "Annuler",
      showLoaderOnConfirm: true,
      iconColor: "#dc3545",
      confirmButtonColor: "#dc3545",
      preConfirm: () => {
        return Axios.delete(`${api}amenetie/${item?.id}`, config)
          .then(async (response) => {
            console.log("res", response);
            await dispatch(ameneties());
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
        Swal.fire({
          icon: "success",
          title: "Amenity deleted !",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="parametre-actions-container">
        <NavLink
          to="#"
          style={{ color: "#2D3748" }}
          className="mr-2"
          data-toggle="modal"
          data-target={`#EditAmeneties${row?.id}`}
          title="Edit this about"
        >
          <EditTwoTone style={{ fontSize: "20px" }} />
        </NavLink>

        <NavLink
          to="#"
          style={{
            color: "#E53E3E",
          }}
          title="Delete this about"
          onClick={() => onDelete(row)}
        >
          <Delete
            set="bold"
            primaryColor="#E53E3E"
            style={{
              fontSize: "16px",
              width: "20px",
              marginLeft: " 10px",
            }}
          />
        </NavLink>
        <div
          className="modal fade modal-faq EditAmeneties"
          id={`EditAmeneties${row?.id}`}
          data-backdrop="static"
          aria-labelledby="EditAmenetiesModalLabel"
          aria-hidden="true"
        >
          <AddAmeneties type="Add" item={row} />
        </div>
      </div>
    );
  };
  return (
    <div className="infos-perso-input">
      {amenetie?.isLoading && <AmenitySkeleton />}
      {!amenetie?.isLoading && (
        <BootstrapTable
          data={amenetie?.data}
          // data={about}
          striped={true}
          hover={true}
          condensed={false}
          multiColumnSort={2}
          search={false}
          version="4"
          bordered={false}
          pagination
        >
          <TableHeaderColumn
            width="50"
            dataField="id"
            isKey={true}
            bordered={false}
            thStyle={{
              fontWeight: 600,
              fontSize: 14,
              color: "#BABEC6",
            }}
            tdStyle={{
              fontWeight: 500,
              fontSize: 16,
              color: "rgba(0, 0, 0, 0.8)",
              padding: 20,
            }}
            hidden
          >
            N°
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField="name"
            bordered={false}
            thStyle={{
              fontWeight: 600,
              fontSize: 14,
              color: "#BABEC6",
            }}
            tdStyle={{
              fontWeight: 500,
              fontSize: 16,
              color: "rgba(0, 0, 0, 0.7)",
              padding: 20,
            }}
          >
            Name
          </TableHeaderColumn>

          <TableHeaderColumn
            bordered={false}
            thStyle={{
              fontWeight: 600,
              fontSize: 18,
              color: "#BABEC6",
            }}
            tdStyle={{
              fontWeight: 500,
              fontSize: 16,
              color: "rgba(0, 0, 0, 0.7)",
              padding: 20,
            }}
            width="150"
            dataField="id"
            dataFormat={(cell, row) => actionFormatter(cell, row)}
          >
            Actions
          </TableHeaderColumn>
        </BootstrapTable>
      )}

      <div
        className="modal fade modal-faq modal-faq-contact"
        id="UpdateAboutModal"
        aria-labelledby="updateLocationModalLabel"
        aria-hidden="true"
      >
        {/* <Modalaboutpersonnalisation type={typeForm} datas={aboutDatas} /> */}
      </div>
    </div>
  );
}

export default AmenetiesTable;
