import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import ImgEquipe from "../../../images/equipe.png";
import companyImg from "../../../images/icons/company.png";
import Deal from "../../../images/icons/deal.png";
import House from "../../../images/icons/house.png";
import {
  default as Home,
  default as Loan,
} from "../../../images/icons/mortgage.png";
import visionImg from "../../../images/icons/vision.png";
import Logo from "../../../images/logo.png";
import LogoTafFoundation from "../../../images/taf_foundation.png";
import { LocationFormData } from "../../../repositories/user/LocationFormData";
import FrontFooter from "../../frontFooter/FrontFooter";
import Header from "../../header/Header";
import "./AboutUs.css";
import CarouselTeam from "./CarouselTeam";
import Historique from "./Historique";

const AboutImages = [
  {
    img: Logo,
    title: "logo-taf",
  },
  {
    img: companyImg,
    title: "company",
  },
  {
    img: visionImg,
    title: "vision",
  },
];

const AboutUs: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const about_front = useSelector((state: RootStateOrAny) => state.about_front);
  const tafData = useSelector((state: RootStateOrAny) => state.tafData_front);

  return (
    <div className="component-front-home">
      <Header />
      <div className="about-container">
        <div className="banniere-about-us">
          <div className="d-flex align-items-center justify-content-center p-t-200 pb-banniere-mobile">
            <div>
              <h1 className="titre-banniere">About us</h1>
              <p className="sous-titre-about-us">Who we are ?</p>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div className="row container-taf mt-5">
            {about_front.data ? (
              about_front.data.map((item: LocationFormData, i) => {
                return (
                  <div className="col-xl-4 col-lg-4 col-md-4 mb-5">
                    <div>
                      <div
                        className="img-container flex-c mb-3"
                        key={item?.title + i}
                      >
                        <img
                          src={AboutImages[i]?.img}
                          alt={AboutImages[i]?.title}
                          style={{
                            width: "60px",
                            height: "60px",
                          }}
                        />
                      </div>
                      <h3 className="titre-h3-theme pb-3">
                        {item.title || ""}
                      </h3>
                      <p
                        className="text-para-black-aboutus"
                        dangerouslySetInnerHTML={{
                          __html: item.message || "",
                        }}
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <h1 className="text-center text-dark">No Inoformation</h1>
            )}
          </div>
        </div>
      </div>
      <section className="section-historique bg-white">
        <div className="container-taf">
          <div className=" px-lg-5 pb-4">
            <p
              className="taf-introduce"
              dangerouslySetInnerHTML={{
                __html:
                  tafData?.data?.taf_africa_global ||
                  "TAF Africa Global Limited is a leading Pan-African real estate company with over 3 decades of experience. We have presence in 8 African countries with its parent company registered in Dubai, United Arab Emirate and major operations in Nigeria and The Gambia. Our projects have consistently imprinted indelible marks across Africa.",
              }}
            />
          </div>
        </div>
        <div
          style={{
            background: "#F4F4F4",
            paddingBottom: "2rem",
            paddingTop: "2rem",
          }}
        >
          <div className="container-taf">
            <Historique />
          </div>
        </div>
      </section>
      <section className="section-images">
        <div className="container-taf">
          <div className="text-center p-t-50 p-b-50">
            <h1 className="titre-meet-our-team">Meet Our Team</h1>
          </div>
          <div>
            <CarouselTeam />
          </div>
        </div>
      </section>
      <section className="section-equipe">
        <div className="container-taf p-t-100 p-b-100">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div>
                <img src={ImgEquipe} alt="équipe" className="img-equipe" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div>
                <div className="pb-4">
                  <img
                    src={LogoTafFoundation}
                    alt="logo-taf-foundation"
                    className="img-logo-equipe"
                  />
                </div>
                <h2 className="titre-equipe-about pb-4">
                  Taf africa foundation
                </h2>
                <p
                  className="text-equipe-about"
                  dangerouslySetInnerHTML={{
                    __html:
                      tafData?.data?.taf_fundation ||
                      `As part of our corporate social responsibility strategy, we
                    are dedicating 1% of all our revenues towards several causes
                    that will make a difference in the lives of the communities we
                    serve. As a pan African organization, we see it as our moral
                    obligation to give back to these communities that have given
                    so much to our organization for the past 30 years. Each year
                    TAF Africa Foundation gives young Gambian entrepreneurs a
                    chance to win 1 Million Dalasis yearly at TAFCON annual
                    conference between December and January. Find out more about
                    TAFCON`,
                  }}
                />
                <a
                  className="text-color-link-about"
                  href="http://www.tafafricafoundation.org/"
                >
                  http://www.tafafricafoundation.org/
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-service mb-5">
        <div className="container-taf p-t-100 p-b-100">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 pb-4">
              <div className="bloc-div-item-service">
                <div>
                  <img src={Home} alt="service" className="img-service" />
                </div>
                <div className="pl-3">
                  <h3 className="titre-section-service">ESTATE AGENCY</h3>
                  <p
                    className="text-section-service"
                    dangerouslySetInnerHTML={{
                      __html:
                        tafData?.data?.estate_agency ||
                        `Our expertise in real estate development is unrivaled. We
                      have designed, financed and developed affordable real estate
                      projects within the West African sub-region. This ranges
                      from Luxury & Affordable Estates to Hotels and Shopping
                      Malls.`,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 pb-4">
              <div className="bloc-div-item-service">
                <div>
                  <img src={Loan} alt="service" className="img-service" />
                </div>
                <div className="pl-3">
                  <h3 className="titre-section-service">REAL ESTATE RENTALS</h3>
                  <p
                    className="text-section-service"
                    dangerouslySetInnerHTML={{
                      __html:
                        tafData?.data?.real_estate_rentals ||
                        `We offer a wide selection of Apartments, Houses and
                      Commercial properties for short, medium and long-term rental
                      across The Gambia, Nigeria, and Senegal.`,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 pb-4">
              <div className="bloc-div-item-service">
                <div>
                  <img src={House} alt="service" className="img-service" />
                </div>
                <div className="pl-3">
                  <h3 className="titre-section-service">MORTGAGE</h3>
                  <p
                    className="text-section-service"
                    dangerouslySetInnerHTML={{
                      __html:
                        tafData?.data?.mortgage ||
                        `In partnership with Trust Bank Limited, Reliance Financial
                      Services, EcoBank & Guaranty Trust Bank, we offer mortgage
                      loans to qualified customers who are interested in
                      purchasing quality properties from TAF. With just 20% -25%
                      Down, the rest of the payments can spread for up 10 - 15
                      years.`,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 pb-4">
              <div className="bloc-div-item-service">
                <div>
                  <img src={Deal} alt="service" className="img-service" />
                </div>
                <div className="pl-3">
                  <h3 className="titre-section-service">
                    PUBLIC–PRIVATE PARTNERSHIP
                  </h3>
                  <p
                    className="text-section-service"
                    dangerouslySetInnerHTML={{
                      __html:
                        tafData?.data?.public_private_partnership ||
                        `Our mastery of Public-Private Partnerships with Governments,
                      gave birth to projects like the RIVTAF Golf Estate and
                      GIETAF Special Economic Zone. We deliver as promised, and
                      ensure our host communities benefit from our development
                      projects`,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FrontFooter />
    </div>
  );
};

export default AboutUs;
