import React from "react";
import ImgBackground from "../../images/background.png";
// import { NavLink } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import Axios from "axios";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import api from "../../Http/global-vars";

const ResetPassword: React.FC = () => {
  const [isSuccessfullySubmittedForm, setIsSuccessfullySubmittedForm] =
    React.useState(false);

  const validationSchema = yup.object().shape({
    email: yup.string().email("Enter a valid email").required(),
    code: yup.number().required(),
    new_password: yup
      .string()
      .required("Password is required.")
      .min(8, "The password must contain at least 8 characters")
      .matches(/[a-z]+/, "Password must contain at least one lowercase letter")
      .matches(
        /[A-Z]+/,
        "The password must contain at least one capital letter"
      )
      .matches(
        /[!@#$%^&*(),;-_+*/.?":{}|<>]+/,
        "Password must contain at least one special character"
      )
      .matches(/\d+/, "The password must contain at least one number"),
    new_password_confirm: yup
      .string()
      .oneOf(
        [yup.ref("new_password"), null],
        "Please enter identical passwords."
      )
      .required("Please confirm your password"),
  });

  const history = useHistory();
  const { register, handleSubmit, reset, formState } = useForm<any>({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  const { errors } = formState;

  const onSubmitResetPassword = async (data: any) => {
    setIsSuccessfullySubmittedForm(true);

    const request = Axios.post(`${api}reset-password/`, data);
    await request
      .then(async (resp) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          html: "Your password has been successfully changed!",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            reset();
            history.push("/login");
          }
        });

        setIsSuccessfullySubmittedForm(false);
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        setIsSuccessfullySubmittedForm(false);
      });
  };

  return (
    <div className="p-t-100">
      <div className="login-component-container">
        <div className="row">
          <div className="col-xl-5 col-lg-5 col-md-5">
            <div>
              <img
                src={ImgBackground}
                className="w-full"
                alt="background login"
                style={{
                  height: "100vh",
                }}
              />
            </div>
          </div>
          <div className="col-xl-7 col-lg-7 col-md-7">
            <div className="card-connexion py-5">
              <p className="titre-connexion p-t-20">Update your password</p>
              <form
                id="login-form"
                onSubmit={handleSubmit(onSubmitResetPassword)}
              >
                <div className="form-group">
                  <label className="label-input">Code</label>
                  <input
                    name="code"
                    id="code"
                    type="number"
                    className="form-control input-with-icon"
                    ref={register}
                  />
                  {errors.code && (
                    <div className="alert alert-danger sia-alert-danger closer mt-2">
                      {errors.code?.message}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label className="label-input">Email</label>
                  <input
                    name="email"
                    id="email"
                    type="email"
                    className="form-control input-with-icon"
                    data-testid="emailId"
                    ref={register}
                  />
                  {errors.email && (
                    <div className="alert alert-danger sia-alert-danger closer mt-2">
                      {errors.email?.message}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label className="label-input">New password</label>
                  <input
                    name="new_password"
                    id="new_password"
                    type="password"
                    className="form-control input-with-icon"
                    ref={register}
                  />
                  {errors.new_password && (
                    <div className="alert alert-danger sia-alert-danger closer mt-2">
                      {errors.new_password?.message}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label className="label-input">New password confirm</label>
                  <input
                    name="new_password_confirm"
                    id="new_password_confirm"
                    type="password"
                    className="form-control input-with-icon"
                    ref={register}
                  />
                  {errors.new_password_confirm && (
                    <div className="alert alert-danger sia-alert-danger closer mt-2">
                      {errors.new_password_confirm?.message}
                    </div>
                  )}
                </div>
                <div className="col-md-4 offset-md-4 py-3 px-0">
                  {!isSuccessfullySubmittedForm ? (
                    <button
                      type="submit"
                      className="btn-connexion"
                      data-testid="buttonId"
                    >
                      Update
                    </button>
                  ) : (
                    <span className="btn-connexion">
                      <i className="fa fa-spin fa-spinner"></i>&nbsp;&nbsp;In
                      Progress
                    </span>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
