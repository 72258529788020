import React from "react";
import { UserFormData } from "../../../../repositories/user/UserFormData";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// import { useHistory } from "react-router";
import { useForm } from 'react-hook-form';
// import AdminUser from "../../../../services/AdminUser";
import Swal from 'sweetalert2';
import { useDispatch } from "react-redux";
import { fetchAdmins } from "../../../../redux/admins/adminsAction";
import $ from "jquery";
import Axios from "axios";
import api from "../../../../Http/global-vars";

const ModalAddAdmin: React.FC = () => {
  const [isSuccessfullySubmittedForm, setIsSuccessfullySubmittedForm] = React.useState(false);

  // const history = useHistory();
  const dispatch = useDispatch();

  const validationSchema = yup.object().shape({
    email: yup.string().email("Enter a valid email").required(),
    fullname: yup.string().required("This field is required"),

  });

  const {
    register,
    handleSubmit,
    reset,
    formState,
  } = useForm<UserFormData>({
    mode: "onBlur",
    resolver: yupResolver(validationSchema)
  });

  const { errors } = formState;


  const token = window.localStorage.getItem('userToken');

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const onSubmit = async (data: UserFormData) => {
    data['parent'] = window.localStorage.getItem("userID");
    setIsSuccessfullySubmittedForm(true);
    const request = Axios.post(`${api}adminuser/`, data, config);
    await request
      .then(async (resp) => {
        // console.log("resp", resp);
        Swal.fire({
          icon: 'success',
          title: "Success",
          html: "Administrator added successfully",
          iconColor: '#df5656',
          showCancelButton: false,
          confirmButtonColor: '#df5656',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          allowOutsideClick: false,

        }).then((result) => {
          if (result.isConfirmed) {
            $('.modal').click();
            // history.push("/admin/users");
            reset();
          }
        })
        dispatch(fetchAdmins())
        setIsSuccessfullySubmittedForm(false);
      })
      .catch((error) => {
        // console.error("error", error, error?.response?.data?.message);
        if (
          error?.response?.data?.message === "Cet utilisateur existe déjà et archivé"
        ) {
          Swal.fire({
            icon: "error",
            iconColor: '#df5656',
            showCancelButton: false,
            confirmButtonColor: '#df5656',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
            title: "This user already exists and archived",
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              reset();
            }
          });
        }
        setIsSuccessfullySubmittedForm(false);
      });
  }

  return (

    <div className="modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content faq-modal-content">
        <div className="modal-header modal-faq-header modal-faq-header-add-member">
          <h5
            className="modal-title titre-question-reponse"
            id="addAdminModal"
          >
            Add a administrator
          </h5>
          <button
            type="button"
            className="close close-icon"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="form-input-profile-modal">
          <div className="modal-body">

            <div className="container-input-address-modal">
              <div className="row">
                <div className="col-md-6">
                  <div className="infos-perso-input-modal">
                    <div className="form-label-text text-label-modal">
                      Name
                    </div>
                    <input
                      type="text"
                      className="form-control-profil"
                      name="fullname" id="fullname"
                      placeholder="Diop"
                      data-testid="nameId"
                      ref={register}
                    />
                  </div>
                  {errors.fullname &&
                    <div className="alert alert-danger sia-alert-danger closer">
                      {errors.fullname?.message}
                    </div>
                  }
                </div>
                <div className="col-md-6">
                  <div className="infos-perso-input-modal">
                    <div className="form-label-text text-label-modal">
                      Address
                    </div>
                    <input
                      type="text"
                      ref={register} name="adresse" id="adresse"
                      className="form-control-profil"
                      placeholder="Mbao"
                      data-testid="adresseId"
                    />
                  </div>
                  {errors.adresse &&
                    <div className="alert alert-danger sia-alert-danger closer">
                      {errors.adresse?.message}
                    </div>
                  }
                </div>
                <div className="col-md-6">
                  <div className="infos-perso-input-modal">
                    <div className="form-label-text text-label-modal">
                      Email
                    </div>
                    <input
                      type="email"
                      ref={register} name="email" id="email"
                      className="form-control-profil"
                      placeholder="email"
                      data-testid="emailId"
                    />
                  </div>
                  {errors.email &&
                    <div className="alert alert-danger sia-alert-danger closer">
                      {errors.email?.message}
                    </div>
                  }
                </div>
                <div className="col-md-6">
                  <div className="infos-perso-input-modal">
                    <div className="form-label-text text-label-modal">
                      Phone
                    </div>
                    <input
                      type="tel"
                      className="form-control-profil"
                      ref={register} name="telephone" id="telephone"
                      placeholder="7700000"
                      data-testid="telephoneId"
                    />
                  </div>
                  {errors.telephone &&
                    <div className="alert alert-danger sia-alert-danger closer">
                      {errors.telephone?.message}
                    </div>
                  }
                </div>
              </div>
            </div>

          </div>
          <div className="modal-footer faq-modal-footer modal-faq-footer-add-member">
            <button
              type="button"
              className="btn btn-retour-faq-modal"
              data-dismiss="modal"
            >
              Back
            </button>
            {!isSuccessfullySubmittedForm && (
              <div className="d-flex justify-content-end">
                <button className="btn-taf-primary" type="submit">Send</button>
              </div>
            )
            }

            {
              isSuccessfullySubmittedForm && (
                <div className="div-btn-modal-addmember">
                  <button type="submit" className="btn-taf-primary"><i className="fas fa-spin fa-spinner"></i>&nbsp;&nbsp;In progress</button>
                </div>
              )
            }
          </div>
        </form>
      </div>
    </div>

  );
}

export default ModalAddAdmin;
