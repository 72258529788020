import "./LineChart.css";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import DatePicker from "react-datepicker";
import * as IconlyPack from "react-iconly";
// import "react-datepicker/dist/react-datepicker.css";
import { Bar } from "react-chartjs-2";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { grapheData } from "../../../../redux/Graphe/GrapheAction";
import Skeleton from "../../../../utils/Skeleton";

ChartJS.register(CategoryScale, LinearScale, BarElement);

const LineChart = (props) => {
  const dispatch = useDispatch();
  let dataGraphe = useSelector((state) => state.grapheData);
  const [date, setDate] = useState(new Date());
  const [statData, setStatData] = useState([]);

  useEffect(() => {
    if (date) {
      dispatch(grapheData(moment(date).format("YYYY")));
    }
  }, [date, dispatch]);

  useEffect(() => {
    if (!dataGraphe?.isLoading) {
      setStatData(dataGraphe?.data?.map((item) => item?.montant));
    }
  }, [dataGraphe]);

  var data = {
    labels: [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AOU",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ],
    datasets: [
      {
        label: "Ventes",
        data: statData,
        backgroundColor: "#06640C",
        borderColor: false,
        borderWidth: 0,
        borderRadius: 14,
        barPercentage: 0.45,
        scaleFontColor: "red",
        display: true,
      },
    ],
  };

  let options = {
    responsive: true,
    scaleShowLabels: false,
    mainttainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        ticks: {
          stepSize: 0,
          callback: function (num) {
            return Math.abs(num) > 999
              ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
              : Math.sign(num) * Math.abs(num);
          },
          font: {
            family: "Roboto",
            size: 14,
            weight: "bold",
          },
          color: "#000",
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: "Roboto",
            size: 14,
            weight: "bold",
          },
          color: "#000",
        },
      },
    },
    legend: {
      label: {
        fontSize: 26,
      },
    },

    plugin: {
      tooltip: {},
    },
  };

  const onChangeDate = (date) => {
    setDate(date);
  };
  return (
    <div className="chart-item-container">
      <div className="dashboard-chart-info-filter flex-column flex-md-row">
        <div className="dashboard-chart-info">
          <h3 className="dashboard-chart-info-title">
            Restitution des ventes par mois
          </h3>
        </div>
        <div className="dashboard-chart-filter ">
          <DatePicker
            selected={date}
            showYearPicker
            maxDate={new Date()}
            dateFormat="yyyy"
            onChange={(date) => onChangeDate(date)}
            className="dashboard-chart-filter-select"
          />
          <IconlyPack.ChevronDown
            set="bold"
            className="dashboard-chart-filter-icon"
          />
        </div>
      </div>
      <div className="dashboard-chart mt-5" style={{ maxHeight: "40%" }}>
        {!dataGraphe?.isLoading && (
          <Bar width="100%" height={30} data={data} options={options} />
        )}
        {dataGraphe?.isLoading && (
          <Skeleton variant="rect" width={`100%`} height={300} />
        )}
      </div>
    </div>
  );
};

export default LineChart;
