import React from "react";
import ImgLit from "../../../images/icons/lit.png";
import ImgBain from "../../../images/icons/bain.png";
import ImgEat from "../../../images/icons/eat.png";
import Appartement from "../../../images/appartment.png"

const PaymentInformationCard = () => {
	return (
		<div className="card-checkout card p-3">
			<img src={Appartement} alt="apartement-img" />
			<div className="card-body p-0">
				<h1 className="card-check-title">Bluebell Real Estate, apartment</h1>
				<h3 className="card-quice-text">Quice market Nigeria</h3>
				<hr className="checkout-custom-bar mt-2"/>
				<div className="d-flex py-4 display-block-image-responsive">
					<div className="d-flex">
						<img src={ImgLit} alt="Icon" />{" "}
						<p className="para-text-div-border pl-2">
							5 beds
						</p>
					</div>
					<div className="mx-3 d-flex mx-none-responsive">
						<img src={ImgEat} alt="Icon" />{" "}
						<p className="para-text-div-border pl-2">
							1 kitchens
						</p>
					</div>
					<div className="d-flex">
						<img src={ImgBain} alt="Icon" />{" "}
						<p className="para-text-div-border pl-2">
							2 bathroom
						</p>
					</div>
				</div>
				<p className="card-quice-text">10/12/2021 - 10/01/2022</p>
				<form action="" className="mt-4">
					<div className="form-group">
						<label htmlFor="codeCoupon" className="card-quice-text mb-3">Coupon code</label>
						<input 
							type="text" 
							className="card-check-form-control px-3" 
							placeholder="I"
							id="codeCoupon"
						/>
						<small className="text-danger"></small>
					</div>
					<div className="flex-c mt-5">
						<button className="btn-taf-primary">apply</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default PaymentInformationCard;
