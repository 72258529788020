import Axios from "axios";
import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { Delete, Edit } from "react-iconly";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import "react-responsive-modal/styles.css";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../../../Http/global-vars";
import { propertyTypes } from "../../../../redux/PropertyType/propertyTypeAction";
import ChampsSkeleton from "../ChampsSkeleton";
import Modalafficherpropertytype from "../LesModals/ModalUpdatePropertyType";

const PropertyType = () => {
  const property_types = useSelector(
    (state: RootStateOrAny) => state.property_types
  );
  console.log(property_types);
  const dispatch = useDispatch();

  const [isPageLoading, setIsPageLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setIsPageLoading(false);
    }, 3000);
  }, []);

  // var handleClearButtonClick = (onClick) => {
  //   console.log("This is my custom function for ClearSearchButton click event");
  //   onClick();
  // };

  // var createCustomClearButton = (onClick) => {
  //   return (
  //     <ClearSearchButton
  //       btnText="Effacer"
  //       btnContextual="btn-warning"
  //       className="my-custom-class"
  //       onClick={(e) => handleClearButtonClick(onClick)}
  //     />
  //   );
  // };
  // const options = {
  //   clearSearch: false,
  //   clearSearchBtn: createCustomClearButton,
  //   noDataText: "Aucune donnée disponible",
  // };

  const actionFormatter = (cell, row) => {
    const idmodal = "property_types" + row.id;

    return (
      <div className="parametre-actions-container">
        <NavLink
          to="#"
          style={{
            color: "linear-gradient(180deg, #06640C 0%, #061A07 100%)",
          }}
          data-toggle="modal"
          data-target={"#" + idmodal}
          title="Edit this property type"
        >
          <Edit
            set="bold"
            primaryColor="linear-gradient(180deg, #06640C 0%, #061A07 100%)"
            style={{
              fontSize: "20px",
            }}
          />
        </NavLink>

        <NavLink
          to="#"
          style={{
            color: "#E53E3E",
          }}
          onClick={() => onDelete(row?.id)}
          title="Delete this property type"
        >
          <Delete
            set="bold"
            primaryColor="#E53E3E"
            style={{
              fontSize: "16px",
              width: "20px",
              marginLeft: " 10px",
            }}
          />
        </NavLink>

        <div
          className="modal fade"
          id={idmodal}
          aria-labelledby="VoirCompteRenduLabel"
          aria-hidden="true"
        >
          <Modalafficherpropertytype item={row} />
        </div>
      </div>
    );
  };

  const onDelete = (id) => {
    const token = window.localStorage.getItem("userToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    Swal.fire({
      title: "Do you want to delete this property type?",
      // showDenyButton: true,
      icon: "warning",
      confirmButtonText: `YES`,
      cancelButtonText: `NO`,
      iconColor: "#df5656",
      showCancelButton: true,
      confirmButtonColor: "#df5656",
      cancelButtonColor: "#6c757d",
      allowOutsideClick: false,
      preConfirm: () => {
        return Axios.delete(`${api}property_type/${id}/`, config)
          .then(async (response) => {
            console.log("res", response);
            await dispatch(propertyTypes());
            return response;
          })
          .catch((error) => {
            // console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log("res", result);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Project type deleted successfully",
          showConfirmButton: true,
          iconColor: "#df5656",
          confirmButtonColor: "#df5656",
          allowOutsideClick: false,
        });
      }
    });
  };

  // const getItem = (item) => {
  //   dispatch({
  //     type: "PATIENT",
  //     payload: item,
  //   });
  // };

  return (
    <div className="infos-perso-input">
      <>
        {isPageLoading && <ChampsSkeleton />}
        {!isPageLoading && (
          <BootstrapTable
            data={property_types?.data}
            // options={options}
            striped={true}
            hover={true}
            condensed={false}
            multiColumnSort={2}
            search={false}
            version="4"
            bordered={false}
            pagination
          >
            <TableHeaderColumn
              width="80"
              dataField="id"
              isKey={true}
              bordered={false}
              thStyle={{
                fontWeight: 600,
                fontSize: 14,
                color: "#BABEC6",
              }}
              tdStyle={{
                fontWeight: 500,
                fontSize: 16,
                color: "rgba(0, 0, 0, 0.8)",
                padding: 20,
              }}
            >
              N°
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="name"
              bordered={false}
              thStyle={{
                fontWeight: 600,
                fontSize: 14,
                color: "#BABEC6",
              }}
              tdStyle={{
                fontWeight: 500,
                fontSize: 16,
                color: "rgba(0, 0, 0, 0.7)",
                padding: 20,
              }}
              dataFormat={(cell, row) => row?.name}
            >
              Name
            </TableHeaderColumn>

            <TableHeaderColumn
              bordered={false}
              thStyle={{
                fontWeight: 600,
                fontSize: 14,
                color: "#BABEC6",
              }}
              tdStyle={{
                fontWeight: 500,
                fontSize: 16,
                color: "rgba(0, 0, 0, 0.7)",
                padding: 20,
              }}
              dataField="description"
              dataFormat={(cell, row) => row?.description}
            >
              Description
            </TableHeaderColumn>
            <TableHeaderColumn
              bordered={false}
              thStyle={{
                fontWeight: 600,
                fontSize: 18,
                color: "#BABEC6",
              }}
              tdStyle={{
                fontWeight: 500,
                fontSize: 16,
                color: "rgba(0, 0, 0, 0.7)",
                padding: 20,
              }}
              dataFormat={(cell, row) => actionFormatter(cell, row)}
            >
              Actions
            </TableHeaderColumn>
          </BootstrapTable>
        )}
      </>
    </div>
  );
};

export default PropertyType;
