import React from 'react';
import HeaderAdmin from "../headerAdmin/HeaderAdmin";
import NavbarVertical from "../nav/NavbarVertical";
import "./ArticleAdmin.css";
import ArticleTable from './ArticleTable/ArticleTable';
import { Plus } from 'react-iconly';
import { Link } from "react-router-dom";

const Articleadmin: React.FC = () => {
	return (
    <div className="container-fluid body-background-dashboard">
      <HeaderAdmin />
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavbarVertical />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header mt-4 px-0">
          <div className="projets-container bloc-principal-body-content">
            <div className="profile-containers">
			        <div className="container-profile-edit container-profile-article">
                <h1 className="mon-profil">
					        Article
                </h1>
                <Link
                  to="/admin/article-detail/:id"
                  className="btn btn-add-listing"
                >
                  <Plus
                    set="light"
                    primaryColor="#B0B0B0"
                  />
                  <span className="pl-2">
                    Add
                    news
                  </span>
                </Link>
              </div>
              <div className="users-table">
                <ArticleTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Articleadmin;
