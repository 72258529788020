/*  */
import { RootStateOrAny, useSelector } from "react-redux";
import baseUrl from "../../../Http/backend-base-url";
import facebookIcon from "../../../images/socials/facebook-blog.png";
import instagramIcon from "../../../images/socials/instagram-blog.png";
import twitterIcon from "../../../images/socials/twitter-blog.png";

const AuthorBlogCard = (props) => {
  const socialmediasfront = useSelector(
    (state: RootStateOrAny) => state.socialmediasfront
  );
  return (
    <div className="author-blog-card mt-4 card-blog py-4 px-2 px-sm-5">
      <img
        src={
          !props?.authorBlogCardData?.avatar
            ? `https://ui-avatars.com/api/?name=${props?.authorBlogCardData?.fullname}`
            : `${baseUrl + props?.authorBlogCardData?.avatar}`
        }
        alt="avatar"
        className="avatar-author-blog"
      />
      <div className="author-blog-card-body">
        <h2 className="author-blog-card-name mt-4">
          {props?.authorBlogCardData?.fullname}
        </h2>
        <div className="author-blog-socials-containers my-3">
          <a
            href={socialmediasfront?.data[0]?.facebook ?? "#"}
            className="blog-social-link cursor-pointer"
            target="_blank"
            rel={"noreferrer"}
          >
            <img
              src={facebookIcon}
              alt="facebook-icon"
              className="blog-social-icon"
            />
          </a>
          <a
            href={socialmediasfront?.data[0]?.twitter ?? "#"}
            className="blog-social-link cursor-pointer"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={twitterIcon}
              alt="twitter-icon"
              className="blog-social-icon"
            />
          </a>
          <a
            href={socialmediasfront?.data[0]?.instagram ?? "#"}
            className="blog-social-link cursor-pointer"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={instagramIcon}
              alt="instagram-icon"
              className="blog-social-icon"
            />
          </a>
        </div>
        <p className="author-blog-description">
          {props?.authorBlogCardData?.description}
        </p>
      </div>
    </div>
  );
};

export default AuthorBlogCard;
