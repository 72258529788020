// import * as IconlyPack from "react-iconly";
import { Delete } from "@material-ui/icons/";
import Axios from "axios";
import React, { useState } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Show } from "react-iconly";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../../../Http/global-vars";
import { listReservations } from "../../../../redux/Reservations/reservationAction";
import DetailsReservationModal from "./DetailsReservationModal";
import ListingSkeleton from "./ReservationSkeleton";
import "./ReservationTable.css";

const ReservationsTable = ({
  property,
  project,
}: {
  property?: any;
  project?: any;
}) => {
  const reservations = useSelector((state: RootStateOrAny) => {
    if (property) {
      return state.reservations.data?.filter(
        (f) => f.property_id?.id === property?.id
      );
    }
    if (project) {
      return state.reservations.data?.filter(
        (f) => f.project_id?.id === project?.id
      );
    }
    return state.reservations.data;
  });

  const [reservationData, setReservationData] = useState();

  const [isPageLoading] = React.useState(false);

  const token = window.localStorage.getItem("userToken");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const dispatch = useDispatch();

  const options = {
    clearSearch: false,
    noDataText: "No data available",
  };

  const getReservationData = (data) => {
    setReservationData(data);
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-container">
        <button
          data-toggle="modal"
          data-target="#DetailsReservationModal"
          style={{ color: "#2D3748" }}
          className="mr-3 btn"
          type="button"
          onClick={() => {
            if (!row.read) {
              markAsRead(row.id);
            }
            getReservationData(row);
          }}
          title="Show this reservation"
        >
          <Show set="bold" primaryColor="#CECECE" />
        </button>
        <NavLink
          to="#"
          style={{ color: "#E53E3E" }}
          className=""
          onClick={() => onDelete(row.id)}
          title="Delete this reservation"
        >
          <Delete style={{ fontSize: "20px" }} />
        </NavLink>
      </div>
    );
  };

  const onDelete = (id: number) => {
    Swal.fire({
      title: "Do you want to delete this reservation ?",
      // showDenyButton: true,
      confirmButtonText: `YES`,
      cancelButtonText: `NO`,
      iconColor: "#df5656",
      showCancelButton: true,
      confirmButtonColor: "#df5656",
      cancelButtonColor: "#6c757d",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const addRequest = Axios.delete(`${api}interet/${id}`, config);
        addRequest
          .then((response) => {
            dispatch(listReservations());
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Reservation deleted successfully",
              showConfirmButton: true,
              iconColor: "#df5656",
              confirmButtonColor: "#df5656",
              allowOutsideClick: false,
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Oops! An error occurred while deleting",
              showConfirmButton: true,
              iconColor: "#df5656",
              confirmButtonColor: "#df5656",
              allowOutsideClick: false,
            });
          });
      } else {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Deletion was successfully undone",
          showConfirmButton: true,
          iconColor: "#df5656",
          confirmButtonColor: "#df5656",
          allowOutsideClick: false,
        });
      }
    });
  };

  const getPropertyName = (cell, row) => {
    if (cell?.id) {
      return (
        <div className="nameFormatter">
          <span className="name">{cell?.property_name}</span>
          <br />
          <span className="entreprise">Property</span>
        </div>
      );
    } else if (row?.project_id?.id) {
      return (
        <div className="nameFormatter">
          <span className="name">{row?.project_id?.property_name}</span>
          <br />
          <span className="entreprise">Project</span>
        </div>
      );
    } else {
      return "";
    }
  };

  const filterName = (cell, row) => {
    return cell?.property_name || row?.project_id?.property_name;
  };

  const status_publication = {
    treaty: "Treaty",
    new: "New",
  };

  const statutFormatter = (cell, row) => {
    return (
      <span className="name">
        <button
          type="button"
          title="Change this status reservation"
          onClick={() => {
            if (!row.read) {
              markAsRead(row.id);
            }
            updateStatut(row?.id);
          }}
          className={
            cell === "new"
              ? `btn projet-btn-nouveau text-uppercase cursor-pointer`
              : `btn projet-btn-nouveau-rouge text-uppercase cursor-pointer`
          }
        >
          {cell === "new" ? "New" : cell === "treaty" ? "Treaty" : ""}
        </button>
      </span>
    );
  };

  const markAsRead = async (id: number) => {
    const fd = new FormData();
    fd.append("read", "true");
    const request = Axios.put(`${api}interet/` + id + "/", fd, config);
    request
      .then((res) => {
        dispatch(listReservations());
      })
      .catch((error) => {
        console.log("error", error?.response);
      });
  };

  const updateStatut = async (id: number) => {
    const { value } = await Swal.fire({
      title: "Change status",
      input: "select",
      confirmButtonText: `SAVE`,
      confirmButtonColor: "#df5656",
      cancelButtonColor: "#6c757d",
      color: "#6c757d",
      inputOptions: {
        treaty: "Treaty",
        new: "New",
      },
      inputPlaceholder: "Select a status",
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value) {
            Swal.showLoading();
            const fd = new FormData();
            fd.set("status", value);

            const request = Axios.put(`${api}interet/` + id + "/", fd, config);
            request
              .then((res) => {
                dispatch(listReservations());
                Swal.fire({
                  icon: "success",
                  iconColor: "#df5656",
                  showCancelButton: false,
                  confirmButtonColor: "#df5656",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "OK",
                  title: "Status changed successfully.",
                  showConfirmButton: true,
                  allowOutsideClick: false,
                });
              })
              .catch((error) => {
                console.log("error", error?.response);
                Swal.fire({
                  icon: "error",
                  iconColor: "#df5656",
                  showCancelButton: false,
                  confirmButtonColor: "#df5656",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "OK",
                  title: "Please check the data entered.",
                  showConfirmButton: true,
                  allowOutsideClick: false,
                });
              });
          } else {
            resolve("Please select a status:)");
          }
        });
      },
    });
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container-activite">
        <div className="row easypm-table-row">
          <div className="col-md-12 easypm-table-col">
            {
              <>
                {isPageLoading && <ListingSkeleton />}
                {!isPageLoading && (
                  <div className="table-container-activite">
                    <BootstrapTable
                      data={reservations}
                      hover={true}
                      condensed={false}
                      multiColumnSort={2}
                      options={options}
                      search={false}
                      version="4"
                      bordered={false}
                      striped={true}
                      pagination
                    >
                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 12,
                          color: "#A0AEC0",
                          fontFamily: "Raleway",
                        }}
                        tdStyle={{
                          fontWeight: "normal",
                          fontSize: 14,
                          color: "#2D3748",
                          textTransform: "capitalize",
                          verticalAlign: "middle",
                        }}
                        width="150"
                        dataField="id"
                        isKey={true}
                        hidden
                      ></TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 12,
                          color: "#A0AEC0",
                          fontFamily: "Raleway",
                        }}
                        tdStyle={{
                          fontWeight: "normal",
                          fontSize: 14,
                          color: "#2D3748",
                          textTransform: "capitalize",
                          verticalAlign: "middle",
                        }}
                        width="150"
                        dataField="first_name"
                        dataFormat={(cel, row) => {
                          return (
                            <div style={{ position: "relative" }}>
                              {`${row.first_name} ${row.last_name}`}
                              {!row.read && (
                                <span className="notification-counter2">
                                  {1}
                                </span>
                              )}
                            </div>
                          );
                        }}
                        filterValue={(cel, row) =>
                          `${row.first_name} ${row.last_name}`
                        }
                        filter={{ type: "TextFilter" }}
                      >
                        Full Name
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 12,
                          color: "#A0AEC0",
                          fontFamily: "Raleway",
                        }}
                        tdStyle={{
                          fontWeight: "normal",
                          fontSize: 14,
                          color: "#2D3748",
                          fontFamily: "Raleway",
                          verticalAlign: "middle",
                        }}
                        width="250"
                        dataField="email"
                        filter={{ type: "TextFilter" }}
                      >
                        Email
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 12,
                          color: "#A0AEC0",
                          fontFamily: "Raleway",
                        }}
                        tdStyle={{
                          fontWeight: "normal",
                          fontSize: 14,
                          color: "#2D3748",
                          fontFamily: "Raleway",
                          verticalAlign: "middle",
                        }}
                        width="130"
                        dataField="phone"
                        filter={{ type: "TextFilter" }}
                      >
                        Phone
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 12,
                          color: "#A0AEC0",
                          fontFamily: "Raleway",
                        }}
                        tdStyle={{
                          fontWeight: "normal",
                          fontSize: 14,
                          color: "#2D3748",
                          fontFamily: "Raleway",
                          verticalAlign: "middle",
                        }}
                        width="130"
                        dataField="date_souhaite"
                        filter={{ type: "TextFilter" }}
                      >
                        Desired date
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 12,
                          color: "#A0AEC0",
                          fontFamily: "Raleway",
                        }}
                        tdStyle={{
                          fontWeight: "normal",
                          fontSize: 14,
                          color: "#2D3748",
                          fontFamily: "Raleway",
                          verticalAlign: "middle",
                        }}
                        width="130"
                        dataField="property_id"
                        dataFormat={(cell, row) => getPropertyName(cell, row)}
                        filter={{ type: "TextFilter" }}
                        filterValue={filterName}
                      >
                        Property/Project Name
                      </TableHeaderColumn>

                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 12,
                          color: "#A0AEC0",
                          fontFamily: "Raleway",
                        }}
                        tdStyle={{
                          fontWeight: "normal",
                          fontSize: 14,
                          color: "#2D3748",
                          fontFamily: "Raleway",
                        }}
                        width="100"
                        dataField="status"
                        dataFormat={(cell, row) => statutFormatter(cell, row)}
                        filter={{
                          type: "SelectFilter",
                          options: status_publication,
                          condition: "eq",
                        }}
                      >
                        Statut Reservations
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 12,
                          color: "#A0AEC0",
                          fontFamily: "Raleway",
                        }}
                        tdStyle={{
                          verticalAlign: "middle",
                        }}
                        dataField="id"
                        width="100"
                        dataFormat={(cell, row) => actionFormatter(cell, row)}
                      >
                        Actions
                      </TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                )}
              </>
            }
          </div>
        </div>
        <div
          className="modal fade"
          id="DetailsReservationModal"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <DetailsReservationModal detailReservation={reservationData} />
        </div>
      </div>
    </div>
  );
};

export default ReservationsTable;
