import { ArticleFormData } from '../../repositories/user/ArticleFormData';
import { FETCH_ADD_ARTICLE_LIST, FETCH_ARTICLE_LIST, FETCH_ARTICLE_LIST_FRONT, FETCH_UPDATE_ARTICLE_LIST } from '../types';

export const articleDataReducer = (
  state = { isLoading: true, data: []},
  action: { type: any; payload: ArticleFormData }
) => {
  switch (action.type) {
    case FETCH_ARTICLE_LIST:
      return {
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};

export const articleDatasFrontReducer = (
  state = { isLoading: true, data: []},
  action: { type: any; payload: ArticleFormData }
) => {
  switch (action.type) {
    case FETCH_ARTICLE_LIST_FRONT:
      return {
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};

export const addarticleReducer = (
    state = { isLoading: true, data: {} },
    action: { type: any; payload: any }
  ) => {
    switch (action.type) {
      case FETCH_ADD_ARTICLE_LIST:
        return {
          isLoading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  };
  
  export const updatearticleReducer = (
    state = { isLoading: true, data: {} },
    action: { type: any; payload: any }
  ) => {
    switch (action.type) {
      case FETCH_UPDATE_ARTICLE_LIST:
        return {
          isLoading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  };
