import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHistory } from 'react-router';
import ContactFormData from '../../../repositories/user/ContactFormData';
import RegisterFormData from '../../../repositories/user/RegisterFormData';



function useContactForm() {
  const history = useHistory()
  const validationSchema = useMemo(() => (
    yup.object().shape({
        name: yup.string().required('Votre nom est requis'),
        email: yup.string().email().required('Votre Email est requis'),
    })
  ), [])

  const { register, handleSubmit, errors } = useForm<ContactFormData>({
    resolver: yupResolver(validationSchema)
  });
 
  const onSubmit = ((formValues: RegisterFormData) => {
    // console.log(formValues);
    // var loginCallBack = user.loginUser(formValues)
    // console.log(loginCallBack)
    history.push('home')
  });
 
  return {
    register,
    errors,
    onSubmit: handleSubmit(onSubmit)
  }
}

export default useContactForm;