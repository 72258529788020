import axios from "axios";
import { authenticate } from "./Endpoints";

export default {
  async onLogin(data) {
    const response = await axios.post(authenticate.onLogin(), data);
    return response.data;
  },
  async onRegister(data) {
    const response = await axios.post(authenticate.onRegesister(), data);
    return response.data;
  },
  async onRegisterSocial(data) {
    const response = await axios.post(authenticate.onRegisterSocial(), data);
    return response.data;
  },
  async onMe(config) {
    const response = await axios.get(authenticate.onMe(), config);
    return response.data;
  },
};
