import api from "../../Http/global-vars";
import { FETCH_PROMOTE_ELEMENT } from "../types";

export const fetchPromote = () => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    const url = api + "propertyenavant/";

    await fetch(url)
      .then(async (res) => {
        const data = await res.json();
        dispatch({
          type: FETCH_PROMOTE_ELEMENT,
          payload: data,
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};

export const fetchPromoteGet = (data: any) => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    dispatch({
      type: FETCH_PROMOTE_ELEMENT,
      payload: data,
      // ,
    });
  };
};
