import { yupResolver } from "@hookform/resolvers/yup";
//@ts-ignore
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import { useEffect, useState } from "react";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { useForm } from "react-hook-form";
import { RootStateOrAny, useSelector } from "react-redux";
import * as yup from "yup";
import baseUrl from "../../../Http/backend-base-url";
import groupphoto from "../../../images/Groupphoto.png";
import ProjetDatas from "../../../repositories/user/projets/DatasProjets";
//@ts-ignore

const DetailDeBase = ({
  formData,
  setFormData,
  navigation,
  pics,
  setPics,
  onDeletePics,
}) => {
  const [image, setImage] = useState<string | File | any>();
  const [imageShow, setImageShow] = useState<string>();
  const [typeFormat, setTypeFormat] = useState<any>();
  const [emptyImage, setEmptyImage] = useState<any>();
  const property_types = useSelector(
    (state: RootStateOrAny) => state.project_types
  );
  const countries = useSelector((state: RootStateOrAny) => state.countries);
  const locations = useSelector((state: RootStateOrAny) => state.locations);

  const getFilesFromEvent = async (e) => {
    const chosenFiles = await getDroppedOrSelectedFiles(e);
    return chosenFiles.map((f) => f.fileObject);
  };

  const InputChooseFile = ({ accept, onFiles, files, getFilesFromEvent }) => {
    const text =
      files.length === 0 ? (
        // 'Ajouter  des photos' :
        <div className="start-label-input-choose-file">
          <div className="label-img-container pt-3">
            <img src={groupphoto} className="label-image" alt="icone-label" />
          </div>
          <span className="first_label-input-choose-file">
            Déposez une image ici ou sélectionnez un fichier.
          </span>
          <span className="second-label-input-choose-file">
            Format acceptés : JPG, PNG, GIF, TIFF ou BMP, inférieurs à 200Mo.
          </span>
        </div>
      ) : (
        <div
          className="btn-taf-add"
          style={{
            cursor: "pointer",
            float: "right",
          }}
          onClick={(e) => e.preventDefault()}
        >
          Add more pictures
        </div>
      );
    let buttonStartStyle: any = {
      background: "#rgb(243, 243, 243);",
      cursor: "pointer",
      borderRadius: 10,
      marginTop: 10,
      textAlign: "center",
      maxWidth: "100%",
      color: "#fff",
    };

    if (files.length <= 0) {
      buttonStartStyle = {
        // color: '#ff9800',
        cursor: "pointer",
        padding: 15,
        borderRadius: 30,
        marginTop: 10,
        textAlign: "center",
      };
    }

    return (
      <div className="upload-dropzone-row">
        <div style={buttonStartStyle} className="col-md-11">
          {/* {text} */}
          <label htmlFor="dropzoneImage">{text}</label>
          <input
            style={{ opacity: 0, cursor: "pointer" }}
            className="file-input"
            type="file"
            name="image"
            multiple
            accept={accept}
            onChange={async (e) => {
              const chosenFiles = await getFilesFromEvent(e);
              onFiles(chosenFiles);
            }}
            id="dropzoneImage"
          />
        </div>
      </div>
    );
  };

  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
  ];

  const validationSchema = yup.object().shape({
    property_name: yup.string().required("This Field is required"),
    location_id: yup.string().required("This Field is required"),
    property_type: yup
      .string()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required("This Field is required"),
    min_price: yup
      .string()
      .required("This Field is required")
      .trim()
      .matches(/^[0-9]*$/, "This field must contain valid number"),
    max_price: yup
      .string()
      .required("This Field is required")
      .trim()
      .matches(/^[0-9]*$/, "This field must contain valid number"),
  });

  const handleChange = (e: any) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      setImageShow(URL.createObjectURL(file));
      setEmptyImage(false);
      setImage(file);
      setTypeFormat(SUPPORTED_FORMATS.includes(file.type));
    }
  };

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    // reset,
    formState,
  } = useForm<ProjetDatas>({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });
  const { errors } = formState;
  const wf = watch();
  // console.log("formData results", formData);
  useEffect(() => {
    register("min_price");
    register("max_price");
  }, []);
  useEffect(() => {
    const item = formData[0];

    if (item) {
      setValue("property_name", item.property_name && item.property_name);
      setValue("building_age", item.building_age && item.building_age);
      setValue("min_price", item.min_price && item.min_price);
      setValue("max_price", item.max_price && item.max_price);
      setValue("property_type", item.property_type);
      setValue("virtual_video", item.virtual_video);
      setValue("video_presentation", item.video_presentation);
      setValue("country_id", item.country_id);
      setValue("location_id", item.location_id);
      if (item.image) {
        if (typeof item.image !== "string") {
          setImageShow(URL.createObjectURL(item?.image));
        } else {
          setImageShow(baseUrl + item?.image);
        }
      }
    }
  }, []);
  useEffect(() => {
    if (
      image ||
      wf.property_name ||
      wf.location_id ||
      wf.building_age ||
      wf.min_price ||
      wf.max_price ||
      wf.property_type ||
      wf.virtual_video ||
      wf.video_presentation ||
      wf.country_id
    ) {
      const obj = { ...wf };
      if (image) {
        obj.image = image;
      } else if (formData[0]?.image) {
        obj.image = formData[0]?.image;
      }
      setFormData((old: any) => {
        if (old.length === 0) {
          return [obj];
        } else {
          old[0] = obj;
          return [...old];
        }
      });
    }
  }, [
    image,
    wf.property_name,
    wf.building_age,
    wf.min_price,
    wf.max_price,
    wf.property_type,
    wf.virtual_video,
    wf.video_presentation,
    wf.location_id,
    wf.country_id,
  ]);
  const onSubmitDetailsDeBase = () => {
    if (!image && !formData[0]?.image) {
      setEmptyImage(true);
      return;
    }
    navigation.next();
  };

  return (
    <>
      <div className="form-group-add-project-group-container">
        <form
          className="form-input-profile pl-3"
          onSubmit={handleSubmit(onSubmitDetailsDeBase)}
        >
          <div className="row">
            <div className="col-md-12">
              <div className="infos-perso-input">
                <label className="form-label-text">
                  Project title <sup className="text-danger h6">*</sup>
                </label>
                <input
                  type="text"
                  name="property_name"
                  ref={register}
                  className="form-control-profil"
                  placeholder="Project name"
                />
                {errors.property_name && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {errors.property_name?.message}
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="infos-perso-input">
                <label className="form-label-text">
                  Min Price <sup className="text-danger h6">*</sup>
                </label>
                {/* <RangeSliderInput
                  onChange={(val) => {
                    setValue("min_price", val[0]);
                    setValue("max_price", val[1]);
                  }}
                  value={[wf.min_price, wf.max_price]}
                /> */}
                <input
                  type="number"
                  className="form-control-profil"
                  placeholder="GMD"
                  name="min_price"
                  ref={register}
                  min="0"
                  step="any"
                />
                {errors.min_price && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {errors.min_price?.message}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="infos-perso-input">
                <label className="form-label-text">
                  Max. price <sup className="text-danger h6">*</sup>
                </label>
                <input
                  type="number"
                  className="form-control-profil"
                  placeholder="GMD"
                  name="max_price"
                  ref={register}
                  min="0"
                  step="any"
                />
                {errors.max_price && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {errors.max_price?.message}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="infos-perso-input">
                <label className="form-label-text">Location</label>

                <select
                  className="form-control-profil"
                  id="exampleFormControlSelect3"
                  name="location_id"
                  ref={register}
                >
                  <option>Select an option</option>
                  {locations?.data?.length > 0 &&
                    locations.data?.map((item: any) => {
                      return (
                        <option key={`${item.id}-location--`} value={item.id}>
                          {item?.name}
                        </option>
                      );
                    })}
                </select>
                {errors.location && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {errors.location?.message}
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="infos-perso-input">
                <label className="form-label-text">
                  Built in <sup className="text-danger h6">*</sup>
                </label>
                <input
                  type="date"
                  className="form-control-profil"
                  placeholder="Size"
                  name="building_age"
                  ref={register}
                />
                {errors.building_age && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {errors.building_age?.message}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="infos-perso-input">
                <label className="form-label-text">
                  Project type <sup className="text-danger h6">*</sup>
                </label>
                <select
                  className="form-control-profil"
                  id="exampleFormControlSelect1"
                  name="property_type"
                  ref={register}
                >
                  <option>Select an option</option>
                  {property_types?.data?.length > 0 &&
                    property_types.data?.map((item: any) => {
                      return (
                        <option key={`${item.id}-property`} value={item.id}>
                          {item?.name}
                        </option>
                      );
                    })}
                </select>
                {errors.property_type && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {errors.property_type?.message}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="infos-perso-input">
                <label className="form-label-text">Country</label>
                <select
                  className="form-control-profil"
                  id="exampleFormControlSelect2"
                  name="country_id"
                  ref={register}
                >
                  <option defaultValue={""}>Select an option</option>
                  {countries?.data?.map((c) => (
                    <option value={c.id} key={c.id}>
                      {c.title}
                    </option>
                  ))}
                </select>
                {errors.country_id && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {errors.country_id?.message}
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="infos-perso-input">
                <label className="form-label-text">
                  Project image <sup className="text-danger h6">*</sup>
                </label>
                <input
                  type="file"
                  className="form-control-profil"
                  name="image"
                  onChange={handleChange}
                />
                {typeFormat === false && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {/* {errors.image?.message} */}
                    {"Supported files are: jpeg , png , gif , jpg"}
                  </div>
                )}
                {emptyImage === true && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {"This Field is required"}
                  </div>
                )}

                {imageShow && (
                  <div>
                    <img
                      src={imageShow}
                      className="card-img-top img-listing-card-item mt-2"
                      alt="Listing"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="infos-perso-input">
                <label className="form-label-text">Project video link</label>
                <input
                  type="url"
                  className="form-control-profil"
                  name="video_presentation"
                  placeholder="https://www.youtube.com/watch?v=121243465654bcvhhtyry4"
                  onChange={(e) => handleChange(e)}
                  ref={register}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="infos-perso-input">
                <label className="form-label-text">
                  Project virtual video link
                </label>
                <input
                  type="url"
                  className="form-control-profil"
                  name="virtual_video"
                  placeholder="https://www.youtube.com/watch?v=121243465654bcvhhtyry4"
                  ref={register}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12 form-add-project-group form-group-add-project-dropzone-container">
            <label htmlFor="projectImage" className="form-add-project-label">
              Pictures
            </label>
            <Dropzone
              InputComponent={InputChooseFile}
              getFilesFromEvent={getFilesFromEvent}
              onChangeStatus={(file, status) => {
                if (status === "done") {
                  setPics((old) => [...old, { image: file.file, label: "" }]);
                }
              }}
              accept="image/*"
              maxFiles={100}
              styles={{
                dropzone: {
                  minHeight: 150,
                  maxHeight: 300,
                  border: "2px dashed #d9d9d9",
                  background: "#F3F3F3",
                  overflow: "hidden",
                  opacity: 1,
                },
              }}
            />
            <div className="row">
              {pics.map((pic, index) => {
                const url = pic.id
                  ? baseUrl + pic.image
                  : URL.createObjectURL(pic.image);
                const key = pic.id || `picture${index}`;
                return (
                  <div key={key} className="col-md-4">
                    <div>
                      <img
                        src={url}
                        width={"100%"}
                        height="200"
                        className="mt-1"
                        alt="Listing"
                      />
                    </div>
                    <input
                      type="text"
                      value={pic.label}
                      onChange={(e) => {
                        const newPics = [...pics];
                        newPics[index].label = e.target.value;
                        setPics(newPics);
                      }}
                      className="form-control-profil mt-1"
                      name="label"
                      placeholder="Add image label"
                    />
                    <button
                      className="btn btn-danger btn-sm btn-block mt-1"
                      type="button"
                      onClick={() => {
                        onDeletePics(pic);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="d-flex justify-content-start mt-5">
            <button className="btn-taf-primary" type="submit">
              Continue
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default DetailDeBase;
