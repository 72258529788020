import api from "../../Http/global-vars";
import { FETCH_AGENCES, FETCH_UPDATE_AGENCES } from "../types";

export const agences = () => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const token = window.localStorage.getItem("userToken");
    const url = api + "agence/";
    if (token) {
      await fetch(url, config)
        .then(async (res) => {
          const data = await res.json();
          dispatch({
            type: FETCH_AGENCES,
            payload: data.results,
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  };
};

export const updateagences = (id: number) => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const token = window.localStorage.getItem("userToken");
    const url = api + "agence/" + id;
    if (token) {
      await fetch(url, config)
        .then(async (res) => {
          const data = await res.json();
          // console.log("data results", data.results)
          dispatch({
            type: FETCH_UPDATE_AGENCES,
            payload: data.results,
            // ,
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  };
};
