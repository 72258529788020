import { yupResolver } from "@hookform/resolvers/yup";
import Axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import { ChevronUp } from "react-iconly";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import * as yup from "yup";
import api from "../../../Http/global-vars";
import { fetchReviews } from "../../../redux/reviews/reviewsAction";
import AddReviewData from "../../../repositories/user/listing/AddReviewData";

interface Props {
  type: string;
  id: number;
}

const FormAddReview: React.FC<Props> = ({ type, id }) => {
  const dispatch = useDispatch();

  const [isSuccessfullySubmittedForm, setIsSuccessfullySubmittedForm] =
    React.useState(false);

  const validationSchema = yup.object().shape({
    email: yup.string().email("Enter a valid email").required(),
    message: yup.string().required("This field is required"),
    name: yup.string().required("This field is required"),
  });

  const { register, handleSubmit, reset, formState } = useForm<AddReviewData>({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  const { errors } = formState;

  const onSubmitFormReview = async (data: AddReviewData) => {
    setIsSuccessfullySubmittedForm(true);
    if (type === "project") {
      data["project_id"] = id;
    }
    if (type === "property") {
      data["property_id"] = id;
    }

    const request = Axios.post(`${api}review/`, data);
    await request
      .then(async (resp) => {
        // console.log("resp", resp);
        Swal.fire({
          icon: "success",
          title: "Success",
          html: "Comment sent successfully",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            reset();
          }
        });
        dispatch(fetchReviews());

        setIsSuccessfullySubmittedForm(false);
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        setIsSuccessfullySubmittedForm(false);
      });
  };
  return (
    <div className="form-add-comment-container card-blog mt-4 py-3">
      <div className="px-3 flex-sb mb-3">
        <h2 className="write-comment-text">Write a review</h2>
        <ChevronUp primaryColor="#200E32" />
      </div>
      <form
        className="pl-3 pr-3 pr-lg-5"
        onSubmit={handleSubmit(onSubmitFormReview)}
      >
        <div className="form-textarea-group-container row mb-3">
          <div className="form-group col-12">
            <textarea
              name="message"
              id="message"
              data-testid="messageId"
              className="form-control-textarea px-3 pt-3"
              placeholder="Message"
              ref={register}
            />
            <small className="text-danger">{errors.message?.message}</small>
          </div>
        </div>
        {/* <div className="form-group-container row mb-3">
					<div className="form-group col-12">
						<input
							type="text"
							className="form-control-perso px-3"
							placeholder="Property title"
							name="propertyTitle"
							id="propertyTitle"
							ref={register}
						/>
						<small className="text-danger">{errors.propertyTitle?.message }</small>
					</div>
                </div> */}
        <div className="form-group-container row mb-3">
          <div className="form-group col-md-6">
            <input
              type="text"
              className="form-control-perso px-3"
              placeholder="Your name"
              name="name"
              id="name"
              data-testid="nameId"
              ref={register}
            />
            <small className="text-danger">{errors.name?.message}</small>
          </div>
          <div className="form-group col-md-6">
            <input
              type="email"
              className="form-control-perso px-3"
              placeholder="Your email"
              name="email"
              id="email"
              data-testid="emailId"
              ref={register}
            />
            <small className="text-danger">{errors.email?.message}</small>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-4 mb-3">
          {!isSuccessfullySubmittedForm ? (
            <button className="submit-comment">Submit review</button>
          ) : (
            <span className="submit-comment">
              <i className="fa fa-spin fa-spinner"></i>&nbsp;&nbsp;In Progress
            </span>
          )}
        </div>
      </form>
    </div>
  );
};

export default FormAddReview;
