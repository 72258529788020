import Axios from "axios";
import { useEffect, useState } from "react";
import { useStep } from "react-hooks-helper";
import * as IconlyPack from "react-iconly";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../../Http/global-vars";
import { listProperties } from "../../../redux/ListProperty/listPropertyAction";
import DatasListings from "../../../repositories/user/listings/DatasListings";
import HeaderAdmin from "../headerAdmin/HeaderAdmin";
import NavbarVertical from "../nav/NavbarVertical";
// import ContactInformation from "./ListingContactInformation";
import ListingDetailDeBase from "./ListingDetailDeBase";
import ListingDetailsDeBaseSecondStep from "./ListingDetailsDeBaseSecondStep";

const steps = [
  { id: "detail-listing-1", Component: ListingDetailDeBase },
  { id: "detail-listing-2", Component: ListingDetailsDeBaseSecondStep },
];

const AjouterListing = ({ property }: { property?: DatasListings }) => {
  const [error, setError] = useState<any>(null);
  const [pics, setPics] = useState<any>(property?.pictures || []);
  const onDeleteImage = (img: any) => {
    if (img.id) {
      const id = img.id;
      const token = window.localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      Swal.fire({
        title: "Do you want to delete this image ?",
        // showDenyButton: true,
        confirmButtonText: `YES`,
        cancelButtonText: `NO`,
        iconColor: "#df5656",
        showCancelButton: true,
        confirmButtonColor: "#df5656",
        cancelButtonColor: "#6c757d",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          Axios.delete(`${api}picture/${id}/`, config)
            .then((response) => {
              dispatch(listProperties());
              setPics((old: any) => old.filter((f: any) => f !== img));
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Image deleted successfully",
                showConfirmButton: true,
                iconColor: "#df5656",
                confirmButtonColor: "#df5656",
                allowOutsideClick: false,
              });
            })
            .catch((error) => {
              console.log(error);
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Oops! An error occurred while deleting",
                showConfirmButton: true,
                iconColor: "#df5656",
                confirmButtonColor: "#df5656",
                allowOutsideClick: false,
              });
            });
        }
      });
    } else {
      setPics((old: any) => old.filter((f: any) => f !== img));
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { step, navigation, index } = useStep({ initialStep: 0, steps });
  const [formDataListing, setFormDataListing] = useState<
    Array<Partial<DatasListings>>
  >(
    property
      ? [
          {
            property_name: property.property_name,
            location: property.location_id?.id,
            statut_listing: property.statut_listing,
            price: property.price,
            max_price: property.max_price,
            property_type: property.property_type?.id,
            country_id: property.country_id?.id,
            type_price: property.type_price,
            size: property.size,
            bathroom: property.bathroom,
            bedroom: property.bedroom,
            kitchen: property.kitchen,
            virtual_video: property.virtual_video,
            video_presentation: property.video_presentation,
            image: property.image,
          },
          {
            description: property.description,
            building_age: property.building_age,
            room: property.room,
            ameneties: property.ameneties.map(String),
            latitude: property.latitude,
            longitude: property.longitude,
            city: property.city,
          },
        ]
      : []
  );
  const [finishedStep, setFinishedStep] = useState(false);
  const history = useHistory();
  const handleSubmit = () => {
    setFinishedStep(true);
    const token = window.localStorage.getItem("userToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    const fd = new FormData();
    fd.append("first_name", " ");
    fd.append("last_name", "");
    fd.append("phone", "");
    fd.append("email", "");

    formDataListing.map((item: Partial<DatasListings>) => {
      if (item.image && item.image !== property?.image) {
        fd.append("image", item?.image);
      }
      if (item.description !== undefined) {
        fd.append("description", item?.description);
      }
      if (item.ameneties !== undefined) {
        item.ameneties.map((item: any) => {
          fd.append("ameneties", item);
        });
      }
      if (item.property_name !== undefined) {
        fd.append("property_name", item?.property_name);
      }
      if (item.location !== undefined) {
        fd.append("location_id", item?.location);
      }
      if (item.statut_listing !== undefined) {
        fd.append("statut_listing", item?.statut_listing);
      }
      if (item.price !== undefined) {
        fd.append("price", item?.price);
      }
      if (item.max_price !== undefined) {
        fd.append("max_price", item?.max_price);
      }
      if (item.property_type !== undefined) {
        fd.append("property_type", item?.property_type);
      }
      if (item.bathroom !== undefined) {
        fd.append("bathroom", item?.bathroom);
      }
      if (item.bedroom !== undefined) {
        fd.append("bedroom", item?.bedroom);
      }
      if (item.kitchen !== undefined) {
        fd.append("kitchen", item.kitchen);
      }
      if (item.size !== undefined) {
        fd.append("size", item.size);
      }
      if (item?.building_age !== undefined) {
        fd.append("building_age", item?.building_age);
      }
      if (item?.virtual_video !== undefined) {
        fd.append("virtual_video", item?.virtual_video);
      }
      if (item?.video_presentation !== undefined) {
        fd.append("video_presentation", item?.video_presentation);
      }
      if (item.city !== undefined) {
        fd.append("city", item.city);
      }
      if (item.country_id !== undefined) {
        fd.append("country_id", item?.country_id);
      }
      if (
        ![undefined, "", "Select an option"].includes(item.type_price) &&
        item.type_price
      ) {
        fd.append("type_price", item?.type_price);
      }
      fd.append("postal_code", "postal");
      if (item?.room !== undefined) {
        fd.append("room", item?.room);
      }
      if (item.latitude !== undefined) {
        fd.append("latitude", item.latitude);
      }
      if (item.longitude !== undefined) {
        fd.append("longitude", item.longitude);
      }
    });
    let request: any;
    if (property) {
      request = Axios.put(`${api}listing/${property.id}/`, fd, config);
    } else {
      request = Axios.post(`${api}listing/`, fd, config);
    }
    request
      .then(async (res) => {
        const id = res.data.id;
        try {
          const promises = pics.map((pic) => {
            const form = new FormData();
            form.append("label", pic.label);
            if (pic.image instanceof File) {
              form.append("image", pic.image);
            }
            if (pic.id) {
              return Axios.put(`${api}picture/${pic.id}/`, form, config);
            } else {
              return Axios.post(`${api}addpicturelisting/${id}/`, form, config);
            }
          });
          await Promise.all(promises);
        } catch (ex) {
          console.log("error pictures", ex);
        }
        dispatch(listProperties());
        history.push("/admin/properties");
        Swal.fire({
          icon: "success",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          title: `Property ${property ? "updated" : "created"} successfully.`,
          showConfirmButton: true,
          allowOutsideClick: false,
        });
        setFinishedStep(false);
      })
      .catch((error) => {
        const errors = error.response?.data;
        if (errors) {
          setError(
            Object.keys(errors)
              .map((key) => {
                if (Array.isArray(errors[key])) {
                  return key + ": " + errors[key][0];
                }
                return "";
              })
              .filter((f) => f)
              .join("<br>")
          );
        } else {
          setError(error?.message);
        }
        Swal.fire({
          icon: "error",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          title: "Please check the data entered.",
          showConfirmButton: true,
          allowOutsideClick: false,
        });
        setFinishedStep(false);
      });
  };

  const props = {
    formDataListing,
    setFormDataListing,
    finishedStep,
    setFinishedStep,
    navigation,
    handleSubmit,
    pics,
    setPics,
    onDeletePics: onDeleteImage,
  };
  const { Component } = step;

  const dispatch = useDispatch();

  return (
    <div className="container-fluid body-background-dashboard">
      <HeaderAdmin />
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavbarVertical />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header mt-4 px-0">
          <div className="projets-container bloc-principal-body-content">
            <div className="profile-containers">
              <div className="add-project-info-stepper row">
                <div className="add-project-info col-md-6">
                  <div className="add-project-info-icon-title-container">
                    {index > 0 && (
                      <span
                        className="add-project-icon"
                        onClick={navigation.previous}
                      >
                        <IconlyPack.ArrowLeft />
                      </span>
                    )}
                    <h1 className="add-project-general-title">
                      {property ? "Update a property" : "Add a property"}{" "}
                    </h1>
                  </div>
                  <h3 className="add-project-component-title">
                    {index === 2
                      ? "Présentation du projet"
                      : "Basic information"}
                  </h3>
                </div>
                <div className="add-project-stepper col-md-6">
                  <div
                    className={`stepper ${index >= 0 && "stepper-active"}`}
                  ></div>
                  <div
                    className={`stepper ${index >= 1 && "stepper-active"}`}
                  ></div>
                </div>
                {!!error && (
                  <div
                    className="alert alert-danger my-2 cursor-pointer"
                    onClick={() => setError(null)}
                    dangerouslySetInnerHTML={{ __html: error }}
                  />
                )}
              </div>
              <Component {...props} property={property} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AjouterListing;
