export function getUrl(text) {
	// regex for extracting url from string
	const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/i;
	// regex for extracting image url from string
	const regexImageUrl = /(https?:\/\/.*\.(?:png|jpg))/;
	// match url
	const url = text.match(urlRegex);

	if (url) {
		const checkifUrlNotImageUrl = regexImageUrl.test(url);
		if (checkifUrlNotImageUrl) {
			return null;
		}
		return url[0];
	}
	return null;
}

export function cleannerError(errors: any, cleanner: any, timeOut: number = 3000) {
	if (errors) {
		setTimeout(() => Object.entries(errors).map(([key]: any) => cleanner(key)), timeOut);
	}
}