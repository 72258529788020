import { Check } from "@material-ui/icons";
import React from "react";

const CouponConfirmation: React.FC = () => {
	return (
		<div className="row pt-5">
			<div className="col-md-8 offset-md-2 flex-col-c">
				<div className="done-container mt-3 ">
					<Check style={{ fontSize: "40px" }} />
				</div>
				<div className="done-text mt-4">
					<h5 className="done-title">
						Thank you, your booking order confirmed!
					</h5>
					<h6 className="done-message">
						Aconfirmation mail send to your email, check
						your inbox
					</h6>
				</div>
				<div className="checkout-data-container row w-full">
					<div className="col-12 col-md-8 col-xl-6 offset-md-2 offset-xl-3">
						<div className="row mb-3">
							<p className="done-text col-sm-6">Booking ID /Num</p>
							<p className="done-text fw-600 col-sm-6">#Bk201569</p>
						</div>
						<div className="row mb-3">
							<p className="done-text col-sm-6">First Name</p>
							<p className="done-text fw-600 col-sm-6">Shaurya</p>
						</div>
						<div className="row mb-3">
							<p className="done-text col-sm-6">Last Name</p>
							<p className="done-text fw-600 col-sm-6">Preet</p>
						</div>
						<div className="row mb-3">
							<p className="done-text col-sm-6">Email</p>
							<p className="done-text fw-600 col-sm-6">shaeyap@gmail.com</p>
						</div>
						<div className="row mb-3">
							<p className="done-text col-sm-6">Phone</p>
							<p className="done-text fw-600 col-sm-6">77 700 00 00</p>
						</div>
						<div className="row mb-3">
							<p className="done-text col-sm-6">Country</p>
							<p className="done-text fw-600 col-sm-6">Gambia</p>
						</div>
						<div className="row mb-3">
							<p className="done-text col-sm-6">City</p>
							<p className="done-text fw-600 col-sm-6">Banjul</p>
						</div>
					</div>
				</div>
				<div className="pt-5">
					<h5 className="done-title text-left">Payment detail</h5>
					<p className="done-text">
						Lorem ipsum dolor sit amet, consectetur
						adipiscing elit. Ultricies pharetra,
						consectetur tortor dolor aliquam. Elementum
						cum viverra mattis turpis. Aliquet massa sit
						et facilisis pretium. Maecenas in viverra
						aliquet sodales. Tortor nec ullamcorper
						vitae magnis id varius odio ipsum lectus.
						Cursus commodo tincidunt condimentum feugiat
						risus nunc lorem massa vitae. Tortor
						pellentesque tempus eu morbi egestas eget
						pellentesque scelerisque. Consectetur non
						congue risus pretium. Ut neque.
					</p>
				</div>
			</div>
		</div>
	);
};

export default CouponConfirmation;
