import ProjetDatas from "../../repositories/user/projets/DatasProjets";
import { DETAIL_PROPERTY, FETCH_PROPERTIES_TYPE_TO_FRONT } from "../types";

type Type = {
  isLoading: boolean;
  data: ProjetDatas[];
};
export const listPropertiesFrontReducer = (
  state: Type = { isLoading: true, data: [] },
  action: { type: any; payload: Type["data"] }
): Type => {
  switch (action.type) {
    case FETCH_PROPERTIES_TYPE_TO_FRONT:
      return {
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};

export const detailPropertyFrontReducer = (
  state = { isLoading: true, data: [] },
  action
) => {
  switch (action.type) {
    case DETAIL_PROPERTY:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };

    default:
      return state;
  }
};
