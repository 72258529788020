export const FETCH_TESTIMONIALS = "FETCH_TESTIMONIALS";

export const FETCH_ABOUT_ADMIN = "FETCH_ABOUT_ADMIN";

export const FETCH_ABOUT_FRONT = "FETCH_ABOUT_FRONT";

export const FETCH_TIMELINE_ADMIN = "FETCH_TIMELINE_ADMIN";

export const FETCH_TIMELINE_FRONT = "FETCH_TIMELINE_FRONT";

export const FETCH_TAFDATA_ADMIN = "FETCH_TAFDATA_ADMIN";

export const FETCH_TAFDATA_FRONT = "FETCH_TAFDATA_FRONT";

export const FETCH_FRONT_TESTIMONIALS = "FETCH_FRONT_TESTIMONIALS";

export const FETCH_ADD_TESTIMONIALS = "FETCH_ADD_TESTIMONIALS";

export const FETCH_ADMINS = "FETCH_ADMINS";

export const FETCH_NEWSLETTER = "FETCH_NEWSLETTER";

export const FETCH_PROJECTS = "FETCH_PROJECTS";

export const UPDATE_PROJECT = "UPDATE_PROJECT";

export const DETAIL_PROJECT = "DETAIL_PROJECT";

export const FETCH_PROJECTS_FRONT = "FETCH_PROJECTS_FRONT";

export const FETCH_REVIEWS_PROPERTIES_FRONT = "FETCH_REVIEWS_PROPERTIES_FRONT";

export const FETCH_REVIEWS = "FETCH_REVIEWS";

export const FETCH_REVIEWS_PROJECTS_FRONT = "FETCH_REVIEWS_PROJECTS_FRONT";

export const FETCH_LIST_PROPERTY = "FETCH_LIST_PROPERTY";

export const UPDATE_PROPERTY = "UPDATE_PROPERTY";

export const DETAIL_PROPERTY = "DETAIL_PROPERTY";

export const FETCH_PROPERTY_TYPE = "FETCH_PROPERTY_TYPE";

export const FETCH_ADD_PROPERTY_TYPE = "FETCH_ADD_PROPERTY_TYPE";

export const FETCH_UPDATE_PROPERTY_TYPE = "FETCH_UPDATE_PROPERTY_TYPE";

export const FETCH_PROPERTY_TYPE_FRONT = "FETCH_PROPERTY_TYPE_FRONT";

export const FETCH_PROPERTIES_TYPE_TO_FRONT = "FETCH_PROPERTIES_TYPE_TO_FRONT";

export const FETCH_AGENCES = "FETCH_AGENCES";

export const FETCH_UPDATE_AGENCES = "FETCH_UPDATE_AGENCES";

export const FETCH_AMENETIES = "FETCH_AMENETIES";

export const FETCH_AMENETIES_FRONT = "FETCH_AMENETIES_FRONT";

export const ME = "ME";

export const FETCH_USERS = "FETCH_USERS";

export const FETCH_RESERVATIONS = "FETCH_RESERVATIONS";

export const FETCH_PROPERTY_REVIEW = "FETCH_PROPERTY_REVIEW";

export const FETCH_PROJECT_TYPE = "FETCH_PROJECT_TYPE";

export const UPDATE_PROJECT_TYPE = "UPDATE_PROJECT_TYPE";

export const FETCH_FEATURED_PROJECT_OR_PROPERTY =
  "FETCH_FEATURED_PROJECT_OR_PROPERTY";

export const FETCH_PROJECT_TYPES = "FETCH_PROJECT_TYPES";

export const FETCH_LOCATIONS = "FETCH_LOCATIONS";

export const FETCH_PROMOTE_ELEMENT = "FETCH_PROMOTE_ELEMENT";

export const FETCH_MEMBER_LIST = "FETCH_MEMBER_LIST";

export const FETCH_MEMBER_LIST_FRONT = "FETCH_MEMBER_LIST_FRONT";

export const FETCH_ADD_MEMBER_LIST = "FETCH_ADD_MEMBER_LIST";

export const FETCH_UPDATE_MEMBER_LIST = "UPDATE_MEMBER_LIST";

// export const FETCH_ADD_MEMBER_LIST = "FETCH_ADD_MEMBER_LIST";

export const FETCH_SOCIAL_MEDIAS = "FETCH_SOCIAL_MEDIAS";

export const FETCH_SOCIAL_MEDIAS_FRONT = "FETCH_SOCIAL_MEDIAS_FRONT";

export const FETCH_PARTNER = "FETCH_PARTNER";

export const FETCH_ARTICLE_LIST = "FETCH_ARTICLE_LIST";
export const FETCH_PARTNER_FRONT = "FETCH_PARTNER_FRONT";

export const FETCH_SLIDER = "FETCH_SLIDER";

export const FETCH_FRONT_SLIDER = "FETCH_FRONT_SLIDER";

export const FETCH_SHOW_OF = "FETCH_SHOW_OF";

export const FETCH_SHOW_OF_FRONT = "FETCH_SHOW_OF_FRONT";

export const FETCH_ARTICLE_LIST_FRONT = "FETCH_ARTICLE_LIST_FRONT";

export const FETCH_LOCATIONS_FRONT = "FETCH_LOCATIONS_FRONT";

export const FETCH_ADD_ARTICLE_LIST = "FETCH_ADD_ARTICLE_LIST";

export const FETCH_UPDATE_ARTICLE_LIST = "UPDATE_ARTICLE_LIST";

export const FETCH_CATEGORY_ARTICLE_LIST = "FETCH_CATEGORY_ARTICLE_LIST";
export const FETCH_COUNTRIES_LIST = "FETCH_COUNTRIES_LIST";

export const FETCH_CONTACT_LIST = "FETCH_CONTACT_LIST";

export const FETCH_ADD_CONTACT_LIST = "FETCH_ADD_CONTACT_LIST";

export const FETCH_RESPONSE_CONTACT_LIST = "FETCH_RESPONSE_CONTACT_LIST";

export const FETCH_ADD_RESPONSE_CONTACT_LIST =
  "FETCH_ADD_RESPONSE_CONTACT_LIST";

export const FETCH_LOCATION_FOR_CONNECTED_USER =
  "FETCH_LOCATION_FOR_CONNECTED_USER";

export const FETCH_MONTANT_CURRENCY = "FETCH_MONTANT_CURRENCY";

export const FETCH_GRAPHE_DATA = "FETCH_GRAPHE_DATA";

export const FETCH_NEWSLETTER_ADMIN = "FETCH_NEWSLETTER_ADMIN";
