import React, { useEffect } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./App.scss";
import Agencieadmin from "./components/admin/AgencieAdmin/AgencieAdmin";
import Agenciesmarthouse from "./components/admin/AgencieAdmin/AgencieSmartHouse";
import Smarthouse from "./components/admin/AgencieAdmin/SmartHouse";
import Articleadmin from "./components/admin/Article/ArticleAdmin";
import Articledetail from "./components/admin/Article/ArticleDetail";
import ArticledetailUpdate from "./components/admin/Article/ArticleDetailUpdate";
import CategoryArticles from "./components/admin/Article/CategoryArticles";
import ShowArticledetail from "./components/admin/Article/ShowArticledetail";
import Contactadmin from "./components/admin/ContactAdmin/ContactAdmin";
import Dashboard from "./components/admin/Dashboard/Dashboard";
import AjouterListing from "./components/admin/ListingAdmin/AjouterListing";
import DetailsListingAdmin from "./components/admin/ListingAdmin/DetailsListingAdmin";
import Listingadmin from "./components/admin/ListingAdmin/ListingAdmin";
import ModifierListing from "./components/admin/ListingAdmin/ModifierListing";
import Reservations from "./components/admin/ListingAdmin/Reservations";
import ReservationsByProject from "./components/admin/ListingAdmin/ReservationsByProject";
import ReservationsByProperty from "./components/admin/ListingAdmin/ReservationsByProperty";
import LocationPropertyAccueil from "./components/admin/LocationPropriete/LocationPropertyAccueil";
import Newsletter from "./components/admin/Newsletter/Newsletter";
import ListSliders from "./components/admin/ParaAdmin/ListeSliders";
import Parametreadmin from "./components/admin/ParaAdmin/ParaAdmin";
import Paradetail from "./components/admin/ParaAdmin/ParaDetail";
import Profiladmin from "./components/admin/ProfileAdmin/ProfileAdmin";
import AjouterProjet from "./components/admin/ProjetAdmin/AjouterProjet";
import DetailsProjetsAdmin from "./components/admin/ProjetAdmin/DetailsProjetAdmin";
import ModifierProjet from "./components/admin/ProjetAdmin/ModifierProjet";
import Projetadmin from "./components/admin/ProjetAdmin/ProjetAdmin";
import Testimonials from "./components/admin/Testimonials/Testimonials";
import Useradmin from "./components/admin/Users/Users";
import Login from "./components/auth/Login";
import ModifierPassword from "./components/auth/ModifierPassword";
import ResetPassword from "./components/auth/ResetPassword";
import AboutUs from "./components/user/about-us/AboutUs";
import Blog from "./components/user/blog/Blog";
import DetailBlog from "./components/user/blog/DetailBlog";
import Checkout from "./components/user/checkout/Checkout";
import Contact from "./components/user/contact/Contact";
import FrontHome from "./components/user/front-home/FrontHome";
import Showresultat from "./components/user/front-home/ShowResultat";
import Listing from "./components/user/listing/Listing";
import ListingItem from "./components/user/listing/ListingItem";
import ProfilUser from "./components/user/profil/ProfilUser";
import DetailsProjet from "./components/user/projets/DetailsProjet";
import Projets from "./components/user/projets/Projets";
import isAuthenticate from "./modules/secure/secure";
import { fetchAbouts, fetchAboutsFront } from "./redux/AboutAdmin/AboutAction";
import {
  memberList,
  memberListFront,
} from "./redux/AddNewMemberSetting/ourTeamMemberAction";
import { agences } from "./redux/Agence/agenceAction";
import { articleList, articleListFront } from "./redux/Article/articleAction";
import { listProperties } from "./redux/ListProperty/listPropertyAction";
import {
  listPropertiesToFront,
  listPropertiesToFrontGET,
} from "./redux/ListPropertyToFront/listPropertyToFrontAction";
import {
  fetchLocations,
  fetchLocationsFront,
} from "./redux/Location/LocationAction";
import { fetchNewsletterAdmin } from "./redux/NewsletterAdmin/NewsletterAdminAction";
import {
  fetchPartners,
  fetchPartnersFront,
} from "./redux/Partner/PartnerAction";
import { projectTypeAction } from "./redux/ProjectType/projectTypeAction";
import { fetchProjectTypes } from "./redux/ProjectTypes/ProjectTypesAction";
import { fetchPromote, fetchPromoteGet } from "./redux/Promote/PromoteAction";
import { propertyTypes } from "./redux/PropertyType/propertyTypeAction";
import { propertyTypesFront } from "./redux/PropertyTypeFront/propertyTypeFrontAction";
import { listReservations } from "./redux/Reservations/reservationAction";
import { responseContactList } from "./redux/ResponseContact/ResponseContactAction";
import { fetchShowOf, fetchShowOfFront } from "./redux/ShowOf/ShowOfAction";
import { fetchSlider, fetchSlidersFront } from "./redux/Slider/SliderAction";
import {
  fetchSocialMedias,
  fetchSocialMediasFront,
} from "./redux/SocialMedia/SocialMediaAction";
import {
  fetchTestimonials,
  testimonialsAction,
} from "./redux/Testimonials/TestimonialsAction";
import { fetchAdmins } from "./redux/admins/adminsAction";
import { ameneties, amenetiesToFront } from "./redux/ameneties/amenetieAction";
import { categoryArticleList } from "./redux/categoryArticles/categoryArticlesAction";
import { contactList } from "./redux/contact/contactAction";
import { countriesList } from "./redux/countries/countriesAction";
import { fetchFeaturedProjectOrProperty } from "./redux/featuredProjectOrProperty/featuredProjectOrPropertyAction";
import {
  getCurrentUserInfos,
  getDatasLocationForConnectedUser,
} from "./redux/getLocationForConnectedUser/getLocationForConnectedUserAction";
import { fetchProjects } from "./redux/projects/projectAction";
import { fetchProjectsFront } from "./redux/projectsFront/projectsFrontAction";
import { fetchReviews } from "./redux/reviews/reviewsAction";
import { RootState, useCurrency } from "./redux/store";
import {
  fetchTafdata,
  fetchTafdataFront,
} from "./redux/tafDataAdmin/tafDataAction";
import {
  fetchtimelineFront,
  fetchtimelines,
} from "./redux/timelineAdmin/TimeLineAction";
import { fetchMe } from "./redux/user/userActions";
import { convertCurrency } from "./utils/convert_currency";

const App: React.FC = () => {
  const [userInfos, setUserInfos] = React.useState<any>(null);
  const dispatch = useDispatch();
  const properties = useSelector(
    (state: RootState) => state.list_properties_front?.data
  );
  const projects = useSelector(
    (state: RootState) => state.projects_front?.data
  );
  const promote = useSelector((state: RootState) => state.promote?.data);
  useEffect(() => {
    sessionStorage.removeItem("shopromoteModal");
    window.scrollTo(0, 0);
  }, []);

  const token = window.localStorage.getItem("userToken");
  const isLogInExpired = isAuthenticate();
  const userType = window.localStorage.getItem("userType");
  React.useEffect(() => {
    getCurrentUserInfos().then((ui) => {
      setUserInfos(ui);
      dispatch(getDatasLocationForConnectedUser(ui?.currency));
    });
  }, []);
  useEffect(() => {
    dispatch(fetchTafdataFront());
    dispatch(fetchtimelineFront());
    dispatch(fetchProjectTypes());
    dispatch(listPropertiesToFront());
    dispatch(propertyTypesFront());
    dispatch(fetchProjectsFront());
    dispatch(amenetiesToFront());
    dispatch(fetchReviews());
    dispatch(fetchFeaturedProjectOrProperty());
    dispatch(fetchPromote());
    dispatch(fetchTestimonials());
    dispatch(fetchSlidersFront());
    dispatch(fetchPartnersFront());
    dispatch(memberListFront());
    dispatch(fetchSocialMediasFront());
    dispatch(fetchShowOfFront());
    dispatch(articleListFront());
    dispatch(categoryArticleList());
    dispatch(fetchAboutsFront());
    dispatch(fetchLocationsFront());
  }, [dispatch]);

  useEffect(() => {
    if (token && (userType === "admin" || userType === "superadmin")) {
      dispatch(listProperties());
      dispatch(propertyTypes());
      dispatch(testimonialsAction());
      dispatch(projectTypeAction());
      dispatch(fetchProjects());
      dispatch(ameneties());
      dispatch(agences());
      dispatch(listReservations());
      dispatch(fetchAdmins());
      dispatch(fetchLocations());
      dispatch(fetchAbouts());
      dispatch(fetchTafdata());
      dispatch(fetchtimelines());
      dispatch(memberList());
      dispatch(fetchPartners());
      dispatch(fetchSocialMedias());
      dispatch(articleList());
      dispatch(countriesList());
      dispatch(categoryArticleList());
      dispatch(fetchSlider());
      dispatch(fetchShowOf());
      dispatch(contactList());
      dispatch(responseContactList());
      dispatch(fetchNewsletterAdmin());
    }
  }, [dispatch, token, userType]);

  useEffect(() => {
    if (token) {
      dispatch(fetchMe());
    }
  }, [dispatch, token]);
  const currency = useCurrency();

  React.useEffect(() => {
    if (userInfos && properties?.length > 0) {
      (async () => {
        const newArray = [...properties];
        const onGmb = await convertCurrency(
          "1",
          currency || userInfos.currency
        );

        for (let i = 0; i < newArray.length; i++) {
          const item = { ...newArray[i] };
          item.newPrice = (parseFloat(item.price) * parseFloat(onGmb)).toFixed(
            2
          );
          item.currency = currency || userInfos.currency;
          newArray[i] = item;
        }
        dispatch(listPropertiesToFrontGET(newArray));
        // dispatch(getDatasLocationForConnectedUser(userInfos.currency))
      })();
    }
  }, [properties?.length, userInfos, currency]);

  React.useEffect(() => {
    if (userInfos && promote?.id) {
      (async () => {
        const item = { ...promote };
        const convertedPrice = await convertCurrency(
          item.price,
          currency || userInfos.currency
        );
        item.newPrice = convertedPrice;
        item.currency = currency || userInfos.currency;
        dispatch(fetchPromoteGet(item));
      })();
    }
  }, [promote?.id, userInfos, currency]);
  return (
    <BrowserRouter>
      <Switch>
        <Route exact={true} path="/login" component={Login} />
        {/* <Route exact={true} path="/register" component={Register} /> */}
        <Route exact={true} path="/" component={FrontHome} />
        <Route exact={true} path="/show-resultats" component={Showresultat} />
        <Route exact={true} path="/about-us" component={AboutUs} />
        <Route exact={true} path="/listing" component={Listing} />
        <Route exact={true} path="/projects" component={Projets} />
        <Route exact={true} path="/projects/:id/:name" component={Projets} />
        <Route
          exact={true}
          path="/project-details/:slug"
          component={DetailsProjet}
        />
        <Route exact={true} path="/blog/category/:slug" component={Blog} />
        <Route exact={true} path="/blog" component={Blog} />
        <Route exact={true} path="/contact" component={Contact} />
        <Route exact={true} path="/blog/:id" component={DetailBlog} />
        <Route exact={true} path="/profil-user" component={ProfilUser} />
        <Route exact={true} path="/listings/:slug" component={ListingItem} />
        <Route exact={true} path="/checkout" component={Checkout} />
        {/* <Route exact={true} path="/dashboard" component={Dashboard} /> */}
        <Route path="/dashboard">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <Dashboard />
          )}
        </Route>
        <Route path="/admin/profiles">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <Profiladmin />
          )}
        </Route>
        <Route path="/admin/setting">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <Parametreadmin />
          )}
        </Route>
        <Route path="/admin/para-admin-detail">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <Paradetail />
          )}
        </Route>
        <Route path="/admin/contact">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <Contactadmin />
          )}
        </Route>
        <Route path="/admin/newsletter">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <Newsletter />
          )}
        </Route>
        <Route path="/admin/users">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <Useradmin />
          )}
        </Route>
        <Route path="/admin/agencies">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <Agencieadmin />
          )}
        </Route>
        <Route path="/admin/agencie/:id">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <Agenciesmarthouse />
          )}
        </Route>
        <Route path="/admin/agencie-smart/:id">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <Smarthouse />
          )}
        </Route>
        <Route path="/admin/articles">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <Articleadmin />
          )}
        </Route>
        <Route path="/admin/article-detail/:id">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <Articledetail />
          )}
        </Route>
        <Route path={`/admin/article-detail-update/:id`}>
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <ArticledetailUpdate />
          )}
        </Route>
        <Route path={`/admin/show-article-detail/:id`}>
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <ShowArticledetail />
          )}
        </Route>
        <Route path="/admin/projets">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <Projetadmin />
          )}
        </Route>
        <Route path="/admin/ajouter-projet">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AjouterProjet />
          )}
        </Route>
        <Route path="/admin/modifier-projet/:id">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <ModifierProjet />
          )}
        </Route>

        <Route path="/admin/properties">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <Listingadmin />
          )}
        </Route>
        <Route path="/admin/property/reservations/:slug">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <ReservationsByProperty />
          )}
        </Route>
        <Route path="/admin/project/reservations/:slug">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <ReservationsByProject />
          )}
        </Route>
        <Route path="/admin/details-property/:id">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <DetailsListingAdmin />
          )}
        </Route>
        <Route path="/admin/project-details/:id">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <DetailsProjetsAdmin />
          )}
        </Route>

        <Route path="/admin/reservations">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <Reservations />
          )}
        </Route>

        <Route path="/admin/add-property">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <AjouterListing />
          )}
        </Route>
        <Route path="/admin/modifier-listing/:id">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <ModifierListing />
          )}
        </Route>

        <Route path="/admin/location-property">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <LocationPropertyAccueil />
          )}
        </Route>

        <Route path="/modifierpassword" component={ModifierPassword} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/category-articles" component={CategoryArticles} />

        <Route path="/admin/sliders">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <ListSliders />
          )}
        </Route>
        <Route path="/admin/testimonials">
          {isLogInExpired ||
          !token ||
          userType === "usager" ||
          userType === "owner" ? (
            <Redirect to="/login" />
          ) : (
            <Testimonials />
          )}
        </Route>

        {/* Not Found */}
        <Route component={() => <Redirect to="/" />} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
