import api from "../../Http/global-vars";
import { DETAIL_PROJECT, FETCH_PROJECTS_FRONT } from "../types";

export const fetchProjectsFront = () => {
  return async (dispatch) => {
    const url = api + "projectbyvisitor/";

    await fetch(url)
      .then(async (res) => {
        const data = await res.json();

        dispatch({
          type: FETCH_PROJECTS_FRONT,
          payload: data.results.filter(
            (x) => x.statut_publication === "publier"
          ),
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};

export const fetchProjectsFrontGet = (data: any) => {
  return (dispatch) => {
    return dispatch({
      type: FETCH_PROJECTS_FRONT,
      payload: data,
    });
  };
};
export const detailProjectFront = (id: number) => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    const url = api + "projectbyvisitor/" + id;

    await fetch(url)
      .then(async (res) => {
        const data = await res.json();
        // console.log("data results idididid", data)
        dispatch({
          type: DETAIL_PROJECT,
          payload: data,
          // ,
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};
