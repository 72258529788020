/*  */
// import $ from "jquery";
import React from "react";
import { Heart, Location, Search, Show } from "react-iconly";
import Skeleton from "react-loading-skeleton";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { RootStateOrAny, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import baseUrl from "../../../Http/backend-base-url";
import { PROJECT_STATUS, displayPriceType } from "../../../Http/global-vars";
import Gallery1 from "../../../images/gallery/gallery10.png";
import { useCurrency } from "../../../redux/store";
import ProjetDatas from "../../../repositories/user/projets/DatasProjets";
import { getNumberSelectOptions } from "../../../services/Endpoints";
import FrontFooter from "../../frontFooter/FrontFooter";
import Header from "../../header/Header";
import { BlogCard } from "../blog/Blog";
import { getPrice } from "./CardAppartItem";
import "./FrontHome.css";
import MultiSliderClient from "./MultiSliderClient";

const FrontHome: React.FC = () => {
  const locations_front = useSelector(
    (state: RootStateOrAny) => state.locations_front
  );
  const property_types_front = useSelector(
    (state: RootStateOrAny) => state.property_types_front
  );
  const allfeatured = useSelector((state: RootStateOrAny) => state.allfeatured);
  const promote = useSelector((state: RootStateOrAny) => state.promote);
  const sliders_front = useSelector(
    (state: RootStateOrAny) => state.sliders_front
  );
  const partnersFronts = useSelector(
    (state: RootStateOrAny) => state.partnersFront
  );
  const showofFront = useSelector((state: RootStateOrAny) => state.showofFront);
  const articles_front = useSelector(
    (state: RootStateOrAny) => state.articles_front
  );

  const [min, setMin] = React.useState("");
  const [max, setMax] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [typeObj, setTypeObj] = React.useState("");
  const [propertyType, setPropertyType] = React.useState("");

  const promoteData = promote?.data;

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const filtred =
    sliders_front?.data?.length > 0
      ? sliders_front?.data.filter((s: any) => !!s.image)
      : [];
  const slides = filtred.length > 0 ? filtred : [{}];

  return (
    <div className="component-front-home">
      <Header />
      {!!promoteData?.id && <PromotModal promoteData={promoteData} />}

      <div className="carousel-container">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-interval="4000"
          data-ride="carousel"
        >
          <ol style={{ bottom: 100 }} className="carousel-indicators">
            {slides.map((item: any, index: number) => {
              return (
                <li
                  className={index === 0 ? "active" : ""}
                  key={index}
                  data-target="#carouselExampleIndicators"
                  data-slide-to={index}
                ></li>
              );
            })}
          </ol>
          <div className="carousel-inner pt-4">
            {slides.map((item: any, index: number) => {
              const image = item.id ? item.image : null;
              return (
                <div
                  className={`carousel-item banniere ${
                    index === 0 ? "active" : ""
                  }`}
                  key={item?.id}
                >
                  {!!image && (
                    <img
                      src={baseUrl + image}
                      alt="ImgSlider"
                      className="img-slider-accueil w-full"
                    />
                  )}
                  {!image && (
                    <Skeleton
                      className="img-slider-accueil w-full"
                      baseColor="#CCC"
                      height={"100%"}
                    />
                  )}
                  <div className="carousel-caption d-md-block">
                    <div className="bloc-slider-pt-mobile">
                      <div className="div-container-titre-banniere-accueil">
                        <div
                          className="titre-banniere text-uppercase"
                          dangerouslySetInnerHTML={{
                            __html: item.catch_phrase ?? "",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12 ml-auto mr-auto container-bloc-filter">
          <div className="card-profile-choice py-3">
            <p className="titre-profile-choice">
              Find your new TAF space ( Residential, Commercial and Retail)
            </p>
            <div className="row  m-t-30">
              <div className="col-sm-12 col-md-3 profile-choice-col">
                <div className="form-group">
                  <select
                    className="form-control profile-choice-item cursor-pointer"
                    id="exampleFormControlSelect1"
                    value={location}
                    onChange={(e) => setLocation(e.currentTarget.value)}
                  >
                    <option value={""}>Locations</option>
                    {locations_front.data.length > 0 &&
                      locations_front.data.map((item) => {
                        return (
                          <option
                            key={`locat${item?.id}tion`}
                            value={item?.name}
                          >
                            {item?.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="col-sm-12 col-md-3 profile-choice-col">
                <div className="form-group">
                  <select
                    className="form-control profile-choice-item cursor-pointer"
                    id="exampleFormControlSelect1"
                    value={propertyType}
                    onChange={(e) => setPropertyType(e.currentTarget.value)}
                  >
                    <option value={""}>Property type</option>
                    {property_types_front.data.length > 0 &&
                      property_types_front.data.map((item) => (
                        <option key={`locat${item?.id}tion`} value={item?.name}>
                          {item?.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="col-sm-12 col-md-2 profile-choice-col">
                <div className="form-group">
                  <select
                    className="form-control profile-choice-item cursor-pointer"
                    id="exampleFormControlSelect1"
                    value={typeObj}
                    onChange={(e) => setTypeObj(e.currentTarget.value)}
                  >
                    <option value={""}>Bedroom</option>
                    {getNumberSelectOptions().map((o) => (
                      <option key={o} value={o}>
                        {o}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-sm-12 col-md-2 profile-choice-col">
                <div className="form-group">
                  <input
                    className="form-control profile-choice-item cursor-pointer"
                    value={max}
                    type="number"
                    onChange={(e) => {
                      setMax(e.target.value);
                    }}
                    placeholder="Budget"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-2 ">
                <div className="form-group">
                  <NavLink
                    to={{
                      pathname: `/listing`,
                      state: {
                        location,
                        typeObj,
                        propertyType,
                        min,
                        max,
                      },
                    }}
                    className="btn btn-filter-form w-100"
                  >
                    <Search set="light" />
                    <span className="pl-2">Search</span>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="featured-listing pt-5">
        <div className="container-taf">
          <div className="bloc-contain-titre-featured-listing">
            <div>
              <h1 className="titre-featured-listing">Featured projects</h1>
            </div>
            <div>
              <NavLink to="/projects" className="btn btn-see-all trans-0-2">
                See all
              </NavLink>
            </div>
          </div>
          <div className="row pt-3">
            {allfeatured.data?.projects_featured &&
              allfeatured.data?.projects_featured.map((item: ProjetDatas) => {
                return (
                  <div
                    key={`project-detail-${item.id}`}
                    className="col-xl-4 col-lg-6 col-md-6 pb-3"
                  >
                    <div className="card card-appart-item position-relative">
                      <NavLink
                        to={`/project-details/${item.slug}`}
                        className="no-link"
                      >
                        <img
                          src={
                            item.image === "" ||
                            item.image === undefined ||
                            item.image === null
                              ? `https://ui-avatars.com/api/?name=${item?.property_name}`
                              : `${baseUrl + item?.image}`
                          }
                          height="300"
                          className="card-img-top img-cover"
                          alt="..."
                        />
                        <div className="card-body p-0">
                          <div className="div-statut-projet text-uppercase">
                            <span>
                              {PROJECT_STATUS[item?.statut_project] || "New"}
                            </span>
                          </div>
                          <div className="p-3">
                            <h6 className="titre-card-item">
                              {item?.property_name && item?.property_name}
                            </h6>
                            <p
                              className="card-text card-text-lastest-news"
                              dangerouslySetInnerHTML={{
                                __html: item?.description?.slice(0, 30) + "...",
                              }}
                            />
                          </div>
                          <div className="bloc-card-div-border mt-2 px-3">
                            <div className="p-3 border-right no-border-mobile w-50">
                              <p className="para-text-div-border d-flex align-items-center">
                                <Location set="light" primaryColor="#828282" />
                                <span
                                  className="pl-2"
                                  style={{
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {item?.country_id && item?.country_id?.title}
                                </span>
                              </p>
                            </div>
                            {!!item.property_type && (
                              <div className="p-3">
                                <p className="para-text-div-border d-flex align-items-center">
                                  {/* <img src={ImgIcon} alt="Icon category" className="icon-img-category" /> */}
                                  <span className="pl-2">
                                    {item.property_type?.name}
                                  </span>
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
      <section className="section-images mt-4">
        <div className="container-taf">
          <div className="row">
            <div className="col-md-7">
              <div className="flex-column">
                <div className="row row-images-mobile">
                  <div className="col-md-6 pb-mobile">
                    <a
                      href={showofFront.data[0]?.link_state}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="position-relative d-inline-block w-100"
                    >
                      <img
                        src={`${
                          showofFront.data[0]?.image_number_state
                            ? baseUrl + showofFront.data[0]?.image_number_state
                            : Gallery1
                        }`}
                        alt="show-of-image-estate"
                        className="show-of-image show-of-small-size w-100"
                      />
                      <div className="show-of-text-container">
                        <p className="titre-section-images">
                          {showofFront.data[0]?.number_state
                            ? showofFront.data[0]?.number_state
                            : 0}{" "}
                          <br />
                          ESTATES
                        </p>
                      </div>
                    </a>
                  </div>
                  <div className="col-md-6 pb-mobile">
                    <a
                      href={showofFront.data[0]?.link_home}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="position-relative d-inline-block w-100"
                    >
                      <img
                        src={`${
                          showofFront.data[0]?.image_number_home
                            ? baseUrl + showofFront.data[0]?.image_number_home
                            : Gallery1
                        }`}
                        alt="show-of-image-home"
                        className="show-of-image show-of-small-size w-100"
                      />
                      <div className="show-of-text-container">
                        <p className="titre-section-images">
                          {showofFront.data[0]?.number_home
                            ? showofFront.data[0]?.number_home
                            : 0}{" "}
                          <br />
                          HOMES
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
                <div
                  style={{ marginTop: "1rem" }}
                  className="pb-mobile no-mt-mobile"
                >
                  <div className="position-relative">
                    <a
                      href={showofFront.data[0]?.link_year}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="position-relative d-inline-block w-100"
                    >
                      <img
                        src={`${
                          showofFront.data[0]?.image_year_existence
                            ? baseUrl +
                              showofFront.data[0]?.image_year_existence
                            : Gallery1
                        }`}
                        alt="show-of-image-years"
                        className="show-of-image show-of-image-year w-100"
                      />
                      <div className="show-of-text-container">
                        <p className="titre-section-images">
                          {showofFront.data[0]?.year_existence
                            ? showofFront.data[0]?.year_existence
                            : 0}
                          <br />
                          YEARS IN BUSINESS
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div>
                <a
                  href={showofFront.data[0]?.link_country}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="position-relative d-inline-block w-100"
                >
                  <img
                    src={`${
                      showofFront.data[0]?.image_number_country
                        ? baseUrl + showofFront.data[0]?.image_number_country
                        : Gallery1
                    }`}
                    alt="show-of-image-countries"
                    className="show-of-image show-of-image-country w-100"
                  />
                  <div className="show-of-text-container">
                    <p className="titre-section-images">
                      {showofFront.data[0]?.number_country
                        ? showofFront.data[0]?.number_country
                        : 0}{" "}
                      <br />
                      COUNTRIES
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="p-t-100 p-b-20">
        <div className="container-taf">
          <div className="text-center">
            <h1 className="titre-featured-listing">Happy clients</h1>
          </div>
          <div className="pt-5">
            <MultiSliderClient />
          </div>
        </div>
      </section>
      <section className="section-lastest-news">
        <div className="container-taf p-t-50 p-b-100">
          <div className="text-center">
            <h1 className="titre-featured-listing">Latest news</h1>
          </div>
          <div className="row">
            {articles_front.data.length > 0 ? (
              articles_front.data.slice(0, 3).map((article, index) => {
                return <BlogCard item={article} key={index} />;
              })
            ) : (
              <h1>No Articles</h1>
            )}
          </div>
          <div className="text-right mt-4">
            <NavLink to="/blog" className="btn btn-see-all">
              View all news
            </NavLink>
          </div>
        </div>
      </section>
      <section className="section-our-partners bg-white">
        <div className="container-taf p-t-50 p-b-100">
          <div className="text-center">
            <h1 className="titre-featured-listing">Our Partners</h1>
          </div>
          <div className="mt-4">
            {/* @ts-ignore */}
            <Carousel
              swipeable={false}
              draggable={true}
              showDots={false}
              responsive={responsive}
              ssr={true}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={1000}
              keyBoardControl={false}
              customTransition="transform 1000ms ease-in-out"
              transitionDuration={1000}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              {partnersFronts.data ? (
                partnersFronts.data.map((item: any) => {
                  return (
                    <div className="" key={`partener-front${item?.id}`}>
                      <img
                        src={baseUrl + item?.logo}
                        alt={item?.name}
                        height="100"
                        width="250"
                      />
                    </div>
                  );
                })
              ) : (
                <h1>No Partner</h1>
              )}
            </Carousel>
          </div>
        </div>
      </section>
      <FrontFooter />
    </div>
  );
};

export default FrontHome;

function PromotModal({ promoteData }) {
  const currency = useCurrency();
  const closeModalPromote = () => {
    $("#promoteModal").modal("hide");
  };

  React.useEffect(() => {
    const showed = sessionStorage.getItem("shopromoteModal");
    if (!showed) {
      $("#promoteModal").modal("show");
      sessionStorage.setItem("shopromoteModal", "true");
    }
  }, []);

  return (
    <div
      className="modal fade"
      id="promoteModal"
      role="dialog"
      aria-labelledby="promoteModalTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header header-modal-promote">
            <button
              type="button"
              className="close closeBtnPromote"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="">
              <div className="card card-appart-item position-relative">
                {promoteData?.statut_listing ? (
                  <NavLink
                    to={`listings/${promoteData?.slug}`}
                    onClick={() => closeModalPromote()}
                    className="no-link"
                  >
                    {promoteData?.image ? (
                      <div>
                        <img
                          src={promoteData.image && baseUrl + promoteData.image}
                          className="card-img-top width-img-popup"
                          alt="promoted-restal"
                        />
                      </div>
                    ) : (
                      <Skeleton width="100%" height={400} />
                    )}
                    <div className="div-container-statut span-for-sale">
                      <span>For {promoteData?.statut_listing}</span>
                    </div>
                    <div className="bloc-contain-prix">
                      <p
                        style={{
                          color: "#fff",
                          backgroundColor: "#cc1b0b",
                          padding: "8px",
                          borderRadius: "10px",
                          fontWeight: 700,
                        }}
                      >
                        {getPrice(promoteData)}
                        {displayPriceType(promoteData?.type_price)}
                      </p>
                    </div>
                    <div className="card-body p-0">
                      <div className="p-3">
                        <h6 className="titre-card-item">
                          {promoteData?.property_type?.name}
                        </h6>
                        <p className="card-text text-card-item">
                          {promoteData?.country_id?.title}
                        </p>
                        <div className="container-heart-icon">
                          <Heart set="bold" primaryColor="#CC1B0B" />
                        </div>
                      </div>
                      <div className="bloc-card-div-border mt-2 px-3">
                        <div>
                          <p className="para-text-div-border">
                            {promoteData?.bedroom}{" "}
                            <span className="view-sm">bedrooms</span>
                            <span className="icon-bed-2 d-sm-none"></span>
                          </p>
                        </div>
                        <div className="border-left border-right">
                          <p className="p-3 para-text-div-border">
                            {promoteData?.kitchen}{" "}
                            <span className="view-sm">kitchens</span>
                            <span className="icon-outline-soup-kitchen  d-sm-none"></span>
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="pr-5">
                            <p className="para-text-div-border">
                              {promoteData?.bathroom}{" "}
                              <span className="view-sm">bathroom</span>
                              <span className="icon-bathtub-2  d-sm-none"></span>
                            </p>
                          </div>
                          <div>
                            <Show
                              set="bold"
                              primaryColor="rgba(102, 102, 102, 0.69)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                ) : (
                  <NavLink
                    to={`/project-details/${promoteData.slug}`}
                    onClick={() => closeModalPromote()}
                    className="no-link"
                  >
                    <div className="card card-blog position-relative">
                      <div>
                        <img
                          src={
                            promoteData.image === "" ||
                            promoteData.image === undefined ||
                            promoteData.image === null
                              ? `https://ui-avatars.com/api/?name=${promoteData?.property_name}`
                              : `${baseUrl + promoteData?.image}`
                          }
                          height="300"
                          className="card-img-top width-img-popup"
                          alt="..."
                        />
                      </div>
                      <div className="card-body pt-0">
                        <div className="div-statut-projet text-uppercase">
                          <span>
                            {PROJECT_STATUS[promoteData?.statut_project] ||
                              "New"}
                          </span>
                        </div>
                        <div className="py-2">
                          <p className="text-calendar-card d-flex align-promoteDatas-center align-items-center">
                            <Location set="light" primaryColor="#828282" />
                            <span className="pl-2">
                              {promoteData?.country_id &&
                                promoteData?.country_id?.title}
                            </span>
                          </p>
                        </div>
                        {promoteData?.property_type && (
                          <div className="py-2">
                            <p className="text-calendar-card d-flex align-items-center">
                              {/* <img src={ImgIcon} alt="Icon category" className="icon-img-category" /> */}
                              <span className="pl-2">
                                {promoteData?.property_type?.name}
                              </span>
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </NavLink>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
