import React from "react";
import { Check } from "@material-ui/icons";

interface Props {
    key: number;
    feature: string;
};

const FeatureItem:React.FC<Props> = ({feature}) => {
    
	return (
		<div className="feature-item d-flex mb-3">
			<div className="feature-check-container">
				<Check />
			</div>
			<div className="feature-title-container">
				<h6 className="check-title">{feature}</h6>
			</div>
		</div>
	);
};

export default FeatureItem;
