import React from "react";
import { Check } from "@material-ui/icons";

type Props = {
    index: number;
}

const CheckoutStepper:React.FC<Props> = ({index}) => {
	console.log(index);
	return (
		<div className="checkout-step-container flex-md-row justify-content-md-between card-checkout px-5 py-3">
			<div className="checkout-stepper-item itm-center">
				<span className="checkout-stepper mr-2 active">
					<Check />
				</span>
				<h3 className="step-label">Customer detail</h3>
			</div>
			<div className="checkout-stepper-item itm-center">
				<span
					className={`checkout-stepper mr-2 ${
						index !== 0 && "active"
					}`}
				>
					{index === 0 ? 2 : <Check />}
				</span>
				<h3 className="step-label">Payment information</h3>
			</div>
			<div className="checkout-stepper-item itm-center">
				<span
					className={`checkout-stepper mr-2 ${
						index > 1 && "active"
					}`}
				>
					{index <= 1 ? 3 : <Check />}
				</span>
				<h3 className="step-label">Confirmation!</h3>
			</div>
		</div>
	);
};

export default CheckoutStepper;
