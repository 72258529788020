import React, { useEffect } from 'react'
import Swal from 'sweetalert2';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { SocialMediaDatas } from '../../../repositories/user/SocialMediaDatas';
import { useForm } from 'react-hook-form';
import SocialMediaServices from "../../../services/SocialMedia";
import { fetchSocialMedias } from '../../../redux/SocialMedia/SocialMediaAction';



const SocialMedia: React.FC = () => {

    const dispatch = useDispatch();
    const socialmedias = useSelector((state: RootStateOrAny) => state.socialmedias);

    const [isSuccessfullySubmittedForm, setIsSuccessfullySubmittedForm] = React.useState(false);
    const [submitedForm, setSubmitedForm] = React.useState(false);

    const validationSchema = yup.object().shape({
        // facebook_link: yup.string().required("This field is required"),
    });
    const { register, handleSubmit, formState, setValue, clearErrors, reset } = useForm<any>({
        mode: "onBlur",
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        if (socialmedias?.data[0]?.id) {
            setValue('facebook', socialmedias?.data[0]?.facebook ?? '')
            setValue('instagram', socialmedias?.data[0]?.instagram ?? '')
            setValue('twitter', socialmedias?.data[0]?.twitter ?? '')
            setValue('linkedin', socialmedias?.data[0]?.linkedin ?? '')
        }
    }, [setValue,socialmedias,socialmedias?.data, socialmedias.facebook_link, socialmedias.id, socialmedias.instagram_link, socialmedias.linkedin_link, socialmedias.twitter_link])

    const token = window.localStorage.getItem("userToken");
    const userID = window.localStorage.getItem("userID");

    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    useEffect(() => {
        if (formState.errors) {
            setTimeout(() => {
                Object.entries(formState.errors).map(() => {
                    return clearErrors();
                });
            }, 3000);
        }
    }, [formState, clearErrors]);

    const onSubmitSocialMedia = (data: SocialMediaDatas) => {
        setSubmitedForm(true)
        setIsSuccessfullySubmittedForm(true);

        const fd = new FormData();
        fd.append('facebook', data.facebook)
        fd.append('instagram', data.instagram)
        fd.append('twitter', data.twitter)
        fd.append('linkedin', data.linkedin)
        if (userID) {
            fd.append('created_by', userID)
        }

        const request = socialmedias?.data[0]?.id ? SocialMediaServices.onEditSocialMedia(socialmedias?.data[0]?.id, fd, config) : SocialMediaServices.onAddSocialMedia(fd, config);
        request
            .then((res) => {
                Swal.fire({
                    icon: 'success',
                    title: "Success",
                    html: socialmedias?.data[0]?.id ? "Social Media updated successfully!" : "Social Media saved successfully!",
                    iconColor: '#df5656',
                    showCancelButton: false,
                    confirmButtonColor: '#df5656',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'OK',
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        $('.modal').modal('hide');
                        reset();
                        dispatch(fetchSocialMedias());
                    }
                });
                setIsSuccessfullySubmittedForm(false);
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: "Errorr",
                    html: socialmedias?.data[0]?.id ? "Social Media not updated successfully!,Please review the data entered" : "Social Media not saved successfully!,Please review the data entered",
                    iconColor: '#df5656',
                    showCancelButton: false,
                    confirmButtonColor: '#df5656',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'OK',
                    allowOutsideClick: false
                });
                setIsSuccessfullySubmittedForm(false);
                reset()

            });
    };


    return (
        <>
            <div className="profile-containers" data-testid={submitedForm === false ? "FormSubmittedFail" : ""}>

                <div className="mt-3" data-testid={submitedForm === true ? "FormSubmittedDone" : ""}>
                    <form className="form-input-parametre" onSubmit={handleSubmit(onSubmitSocialMedia)}>
                        <div className="container-input-address">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="infos-perso-input">
                                        <label className="form-label-text">
                                            Facebook
                                        </label>
                                        <input
                                            type="text"
                                            name="facebook"
                                            data-testid="facebookId"
                                            ref={register}
                                            className="form-control-profil form-control-showoff"
                                            placeholder="Facebook Link"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="infos-perso-input">
                                        <label className="form-label-text">
                                            Instagram
                                        </label>
                                        <input
                                            type="text"
                                            name="instagram"
                                            data-testid="instagramId"
                                            ref={register}
                                            className="form-control-profil form-control-showoff"
                                            placeholder="Instagram Link"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="infos-perso-input">
                                        <label className="form-label-text">
                                            Twitter
                                        </label>
                                        <input
                                            type="text"
                                            name="twitter"
                                            data-testid="twitterId"
                                            ref={register}
                                            className="form-control-profil form-control-showoff"
                                            placeholder="Twitter Link"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="infos-perso-input">
                                        <label className="form-label-text">
                                            LinkedIn
                                        </label>
                                        <input
                                            type="text"
                                            name="linkedin"
                                            data-testid="linkedinId"
                                            ref={register}
                                            className="form-control-profil form-control-showoff"
                                            placeholder="LinkedIn Link"
                                        />
                                    </div>
                                </div>


                            </div>
                            <div className="d-flex justify-content-end mt-5">
                                {!isSuccessfullySubmittedForm ? (
                                    <button type="submit" className="btn-taf-primary" data-testid="saveBtnId">
                                        {socialmedias?.data[0]?.id ? 'Update' : 'Save'}
                                    </button>
                                ) : (
                                    <span className="btn-taf-primary" data-testid="saveBtnId">
                                        <i className="fa fa-spin fa-spinner"></i>&nbsp;&nbsp;In Progress
                                    </span>
                                )}

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </>
    );
}

export default SocialMedia