import { yupResolver } from "@hookform/resolvers/yup";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { Call, Home, Message } from "react-iconly";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import api, { recaptcha } from "../../../Http/global-vars";
import { contactList } from "../../../redux/contact/contactAction";
import ContactFormData from "../../../repositories/user/ContactFormData";
import { GoogleMap } from "../../admin/ListingAdmin/GoogleMap";
import FrontFooter from "../../frontFooter/FrontFooter";
import Header from "../../header/Header";
import "./Contact.css";

const Contact: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isSuccessfullySubmittedForm, setIsSuccessfullySubmittedForm] =
    React.useState(false);
  const [isValid, setIsValid] = React.useState<boolean>(false);

  // const [submitedForm, setSubmitedForm] = React.useState();
  const [submitedForm, setSubmitedForm] = React.useState(false);
  const dispatch = useDispatch();

  const validationSchema = yup.object().shape({
    email: yup.string().email("Enter a valid email").required(),
    name: yup.string().required("This field is required"),
    subject: yup.string().required("This field is required"),
    message: yup.string().required("This field is required"),
  });

  const history = useHistory();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
  } = useForm<ContactFormData>({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  const userID = window.localStorage.getItem("userID");

  const onChange = (value) => {
    if (value !== undefined || value !== null) {
      console.log(value);
      setIsValid(true);
      setError("recaptcha", { message: "" });
    }
  };
  const onSubmitContact = async (data: ContactFormData) => {
    if (!isValid) {
      return setError("recaptcha", { message: "This Field is required" });
    }
    setSubmitedForm(true);
    console.log(data);
    if (userID) {
      data["created_by"] = userID;
    }
    setIsSuccessfullySubmittedForm(true);
    const request = Axios.post(`${api}contact/`, data);
    await request
      .then((res) => {
        if (res.status === 201) {
          Swal.fire({
            icon: "success",
            title: "Success",
            html: "Message send successfully!",
            iconColor: "#df5656",
            showCancelButton: false,
            confirmButtonColor: "#df5656",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              // $('.modal').modal('hide');
              history.push("/contact");
              reset();
            }
          });
          dispatch(contactList());
          setIsSuccessfullySubmittedForm(false);
          setSubmitedForm(false);
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          title: "Please check the data entered.",
          showConfirmButton: true,
          allowOutsideClick: false,
        });
        setIsSuccessfullySubmittedForm(false);
        // setSubmitedForm(false)
      });
  };

  // const { register, errors } = useContactForm();
  const defaultProps = {
    center: {
      lat: 13.3938125,
      lng: -16.7559375,
    },
    zoom: 13,
  };

  return (
    <div className="component-front-home">
      <Header />
      <div className="p-contact m-b-1">
        <div className="text-center border-bottom p-b-25 p-t-40">
          <h1 className="titre-page-content">Contact us</h1>
          <p className="sous-titre-page-content">We can be closer</p>
        </div>
        <div>
          <div className="container-taf p-t-25">
            <div
              className="row"
              data-testid={submitedForm === true ? "FormSubmittedDone" : ""}
            >
              <div className="col-xl-5 col-lg-5 col-md-12 pb-3">
                <div className="d-flex justify-content-center justify-content-lg-start">
                  <div>
                    <div className="">
                      <h2 className="titre-bloc-left-contact">Get in touch</h2>
                      <div className="w-50">
                        <p className="text-bloc-left-contact">
                          {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. */}
                        </p>
                      </div>
                    </div>
                    <div className="d-md-flex d-lg-block">
                      <div className="d-flex py-4">
                        <div>
                          <Home set="bold" primaryColor="#CC1B0B" />
                        </div>
                        <div className="pl-3">
                          <p className="text-theme-contact mb-2">Visit us</p>
                          <p className="text-contact-black">
                            15-17 Kaira Avenue,
                          </p>
                          <p className="text-contact-black">
                            Madiba Mall, Brufut
                          </p>
                          <p className="text-contact-black">
                            Gardens Estate, P.O
                          </p>
                          <p className="text-contact-black">
                            Box 121, the Gambia
                          </p>
                        </div>
                      </div>
                      <div className="d-flex py-4">
                        <div>
                          <Message set="bold" primaryColor="#CC1B0B" />
                        </div>
                        <div className="pl-3">
                          <p className="text-theme-contact mb-2">Email us</p>
                          <a
                            className="text-contact-black no-link"
                            href="mailto:info@tafafricaglobal.com"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            info@tafafricaglobal.com
                          </a>
                        </div>
                      </div>
                      <div className="d-flex py-4">
                        <div>
                          <Call set="bold" primaryColor="#CC1B0B" />
                        </div>
                        <div className="pl-3">
                          <p className="text-theme-contact mb-2">Call us</p>

                          <a
                            className="text-contact-black no-link"
                            href="tel:+220776-2333"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            +220776-2333
                          </a>
                          <br />
                          <a
                            className="text-contact-black no-link"
                            href="tel:+220376-2333"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            +220376-2333
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-7 col-lg-7 col-md-12 pb-3 px-0"
                data-testid={submitedForm === false ? "FormSubmittedFail" : ""}
              >
                {/* <form
                  id="login-form"
                  className=""
                  onSubmit={handleSubmit(onSubmitContact)}
                >
                  <div className="row">
                    <div className="col-md-6 pb-3 no-pb-form-mobile">
                      <div className="form-group">
                        <label className="label-form-contact">Name</label>
                        <input
                          ref={register}
                          type="text"
                          name="name"
                          id="name"
                          className="form-control input-form-contact text-dark"
                          aria-describedby="emailHelp"
                          data-testid="nameId"
                        />
                      </div>
                      {errors.name && (
                        <div className="alert alert-danger sia-alert-danger closer">
                          {errors.name?.message}
                        </div>
                      )}
                    </div>
                    <div className="col-md-6 pb-3 no-pb-form-mobile">
                      <div className="form-group">
                        <label className="label-form-contact">Email</label>
                        <input
                          ref={register}
                          type="email"
                          name="email"
                          id="email"
                          className="form-control input-form-contact text-dark"
                          aria-describedby="emailHelp"
                          data-testid="emailId"
                        />
                      </div>
                      {errors.email && (
                        <div className="alert alert-danger sia-alert-danger closer">
                          {errors.email?.message}
                        </div>
                      )}
                    </div>
                    <div className="col-md-12 pb-3 no-pb-form-mobile">
                      <div className="form-group">
                        <label className="label-form-contact">Subject</label>
                        <input
                          ref={register}
                          type="text"
                          name="subject"
                          id="subject"
                          className="form-control input-form-contact text-dark"
                          aria-describedby="emailHelp"
                          data-testid="subjectId"
                        />
                      </div>
                      {errors.subject && (
                        <div className="alert alert-danger sia-alert-danger closer">
                          {errors.subject?.message}
                        </div>
                      )}
                    </div>
                    <div className="col-md-12 pb-3 no-pb-form-mobile">
                      <div className="form-group">
                        <label className="label-form-contact">Message</label>
                        <textarea
                          ref={register}
                          className="form-control input-form-contact text-dark"
                          name="message"
                          id="messages "
                          data-testid="messagesId"
                        ></textarea>
                      </div>
                      {errors.message && (
                        <div className="alert alert-danger sia-alert-danger closer">
                          {errors.message?.message}
                        </div>
                      )}
                    </div>
                    <div className="col-12 mb-4">
                      <ReCAPTCHA
                        sitekey={recaptcha.key}
                        hl={recaptcha.lang}
                        onChange={onChange}
                      />
                      {errors.recaptcha && (
                        <div className="alert alert-danger sia-alert-danger closer">
                          {errors.recaptcha?.message}
                        </div>
                      )}
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 pb-3">
                      {!isSuccessfullySubmittedForm ? (
                        <button
                          className="btn btn-theme-submit"
                          type="submit"
                          data-testid="submitBtnId"
                        >
                          SUBMIT REQUEST
                        </button>
                      ) : (
                        <span className="btn btn-valider-profil trans-0-2">
                          <i className="fas fa-spin fa-spinner"></i>
                          &nbsp;&nbsp;&nbsp;In progress...
                        </span>
                      )}
                      {/* <button className="btn btn-theme-submit" type='submit' data-testid="submitBtnId">SUBMIT REQUEST</button> */}
                {/* </div>
                  </div>
                </form>  */}
                <SaleforceForm />
              </div>
            </div>
          </div>
          <div>
            <GoogleMap defaultProps={defaultProps} />
          </div>
        </div>
      </div>

      <FrontFooter />
    </div>
  );
};

export default Contact;

function SaleforceForm() {
  useSuccessMessage("Message send successfully!");
  const [valid, setValid] = useState(false);
  let retUrl = window.location.href;
  if (retUrl.includes("?")) retUrl = `${retUrl}&status=success`;
  else retUrl = `${retUrl}?status=success`;

  return (
    <form
      action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
      method="POST"
    >
      <input
        type="hidden"
        name="captcha_settings"
        value='{"keyname":"TAF_V2_Key_Pair","fallback":"true","orgId":"00DDn000008jLoQ","ts":""}'
      />
      <input type="hidden" name="oid" value="00DDn000008jLoQ" />
      <input type="hidden" name="country" value="gambia" />
      <input type="hidden" name="retURL" value={retUrl} />
      <input
        id="lead_source"
        name="lead_source"
        type="hidden"
        value="Website"
      />

      {/* <input type="hidden" name="debug" value={1} />
      <input type="hidden" name="debugEmail" value="test-taf@yopmail.com" /> */}

      <div className="row">
        <div className="col-md-6 pb-3 no-pb-form-mobile">
          <div className="form-group">
            <label className="label-form-contact" htmlFor="first_name">
              First Name
            </label>
            <input
              id="first_name"
              maxLength={40}
              required
              name="first_name"
              className="form-control input-form-contact text-dark"
              size={20}
              type="text"
            />
          </div>
        </div>
        <div className="col-md-6 pb-3 no-pb-form-mobile">
          <div className="form-group">
            <label className="label-form-contact" htmlFor="last_name">
              Last Name
            </label>
            <input
              id="last_name"
              maxLength={80}
              required
              name="last_name"
              className="form-control input-form-contact text-dark"
              size={20}
              type="text"
            />
          </div>
        </div>

        <div className="col-md-12 pb-3 no-pb-form-mobile">
          <div className="form-group">
            <label className="label-form-contact" htmlFor="email">
              Email
            </label>
            <input
              id="email"
              maxLength={80}
              name="email"
              className="form-control input-form-contact text-dark"
              size={20}
              required
              type="email"
            />
          </div>
        </div>

        <div className="col-md-12 pb-3 no-pb-form-mobile">
          <div className="form-group">
            <label className="label-form-contact" htmlFor="description">
              Description
            </label>
            <textarea
              className="form-control input-form-contact text-dark"
              required
              name="description"
            ></textarea>
          </div>
        </div>
        <div className="col-12 mb-4">
          <ReCAPTCHA
            sitekey={recaptcha.key}
            hl={recaptcha.lang}
            onChange={(value) => setValid(!!value)}
          />
          {!valid && (
            <small className="text-danger">Please validate the reCAPTCHA</small>
          )}
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 pb-3">
          <input
            disabled={!valid}
            onClick={(e) => {
              if (!valid) {
                e.preventDefault();
              }
            }}
            type="submit"
            className="btn btn-theme-submit"
            name="submit"
          />
        </div>
      </div>
    </form>
  );
}

export function useSuccessMessage(message: string) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const status = queryParams.get("status");

  useEffect(() => {
    if (status === "success") {
      Swal.fire({
        icon: "success",
        title: "Success",
        html: message,
        iconColor: "#df5656",
        showCancelButton: false,
        confirmButtonColor: "#df5656",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const url = new URL(window.location.href);
          url.search = "";
          window.history.replaceState(null, "", url);
        }
      });
    }
  }, [status]);

  return status;
}
