import React from "react";

interface Props {
  props: any;
}

const FormCustomerDetail: React.FC<Props> = ({ props }) => {
  const { navigation } = props;
  return (
    <>
      <div className="form-group-container row mb-3">
        <div className="form-group col-md-6">
          <label htmlFor="name" className="custom-label">
            Name
            <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control-perso px-3"
            placeholder="Shaurya preet"
            id="name"
          />
          <small className="text-danger"></small>
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="email" className="custom-label">
            Email
            <span className="text-danger">*</span>
          </label>
          <input
            type="email"
            className="form-control-perso px-3"
            placeholder="sharyap@gmail.com"
            id="email"
          />
          <small className="text-danger"></small>
        </div>
      </div>
      <div className="form-group-container row mb-3">
        <div className="form-group col-md-12">
          <label htmlFor="companyName" className="custom-label">
            Company Name
            <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control-perso px-3"
            placeholder="Gambia"
            id="companyName"
          />
        </div>
      </div>
      <div className="form-group-container row mb-3">
        <div className="form-group col-md-12">
          <label htmlFor="country" className="custom-label">
            Country
            <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control-perso px-3"
            placeholder="Gambia"
            id="country"
          />
        </div>
      </div>
      <div className="form-group-container row mb-3">
        <div className="form-group col-md-12">
          <label htmlFor="street" className="custom-label">
            Street
            <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control-perso px-3"
            placeholder="Gambia"
            id="street"
          />
        </div>
      </div>
      <div className="form-group-container row mb-3">
        <div className="form-group col-md-6">
          <label htmlFor="apartment" className="custom-label">
            Apartment
          </label>
          <input
            type="text"
            className="form-control-perso px-3"
            placeholder="I"
            id="apartment"
          />
          <small className="text-danger"></small>
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="email" className="custom-label">
            Town/City
            <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control-perso px-3"
            placeholder="I"
            id="email"
          />
          <small className="text-danger"></small>
        </div>
      </div>
      <div className="form-group-container row mb-3">
        <div className="form-group col-md-6">
          <label htmlFor="postalCode" className="custom-label">
            Postal code
          </label>
          <input
            type="text"
            className="form-control-perso px-3"
            placeholder="Shaurya preet"
            id="postalCode"
          />
          <small className="text-danger"></small>
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="phone" className="custom-label">
            Phone
            <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control-perso px-3"
            placeholder="sharyap@gmail.com"
            id="phone"
          />
          <small className="text-danger"></small>
        </div>
      </div>
      <div className="form-textarea-group-container row mb-3">
        <div className="form-group col-12">
          <label htmlFor="AdditionalInformation" className="custom-label">
            Additional information
          </label>
          <textarea
            id="AdditionalInformation"
            className="form-control-textarea px-3 pt-3"
            placeholder="I"
          />
          <small className="text-danger"></small>
        </div>
      </div>
      <div className="d-flex justify-content-start mt-5">
        <button className="btn-taf-primary" onClick={() => navigation.next()}>
          Following
        </button>
      </div>
    </>
  );
};

export default FormCustomerDetail;
