import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { FeaturesData } from "./ChecoutData";
import FeatureItem from "./FeatureItem";


const CustomerDetailSideLeft = () => {
    const [features, setFeatures] = useState<string[]>([])

    useEffect(() => {
		setFeatures(FeaturesData)
	},[])
	return (
		<>
			{/* Order */}
			<div className="check-order card-checkout p-3 mb-4">
				<h2 className="order-title">Your Order</h2>
				<form action="">
					<div className="form-dashed-group mb-4">
						<label
							htmlFor="total"
							className="form-dashed-label"
						>
							Total
						</label>
						<input
							type="text"
							className="form-dashed-control"
							placeholder="$319"
							id="total"
							name="total"
						/>
					</div>
					<div className="form-dashed-group mb-4">
						<label
							htmlFor="subTotal"
							className="form-dashed-label"
						>
							Subtotal
						</label>
						<input
							type="text"
							className="form-dashed-control"
							placeholder="$319"
							id="subTotal"
							name="subTotal"
						/>
					</div>
					<div className="form-dashed-group">
						<label
							htmlFor="tax"
							className="form-dashed-label"
						>
							Tax
						</label>
						<input
							type="text"
							className="form-dashed-control"
							placeholder="$319"
							id="tax"
							name="tax"
						/>
					</div>
				</form>
			</div>
			<div className="have-coupon mb-4">
				<span className="check-title text-red-200 mr-2">
					Have you a coupon?
				</span>
				<Link to="/" className="check-title">
					Click here
				</Link>
			</div>
			{/* platinium features */}
			<div className="card-checkout p-3">
				<div className="flex-sb bo-b-g pb-2 mb-3">
					<h2 className="check-title text-dark">
						Platinium
					</h2>
					<div className="platinium-price-container">
						<span className="platinium-price">
							$12/
						</span>
						<span className="platinium-mouth check-text text-gray">
							Mouth
						</span>
					</div>
				</div>
				<div className="flex-sb mb-2">
					<h2 className="check-title">Your features</h2>
					<h2 className="check-title text-green">
						Change Plan
					</h2>
				</div>
				<div className="feature-container mb-5">
					{features.map((feature, index) => (
						<FeatureItem
							key={index}
							feature={feature}
						/>
					))}
				</div>
				<div className="flex-c">
					<button className="btn-taf-primary">
						Proceed Payment
					</button>
				</div>
			</div>
		</>
	);
};

export default CustomerDetailSideLeft;
