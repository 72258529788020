import React from "react";

interface Props {
  props: any;
}

const FormPaymentInformation: React.FC<Props> = ({ props }) => {
  const { navigation } = props;
  return (
    <>
      <div className="form-group-container row mb-3">
        <div className="form-group col-md-6">
          <input
            type="radio"
            className="form-custom-check border-full"
            name="modePaiement"
            id="creditCard"
          />
          <label htmlFor="creditCard" className="custom-label label-check">
            Pay with credit card
          </label>
        </div>
        <div className="form-group col-md-6">
          <input
            type="radio"
            className="form-custom-check border-full"
            name="modePaiement"
            id="paypal"
          />
          <label htmlFor="paypal" className="custom-label label-check">
            Pay with paypal
          </label>
        </div>
      </div>
      <div className="form-group-container row mb-3">
        <div className="form-group col-md-6">
          <label htmlFor="cardHolderName" className="custom-label">
            Card holder name
            <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control-perso px-3"
            id="cardHolderName"
          />
          <small className="text-danger"></small>
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="cardNumber" className="custom-label">
            Card number
            <span className="text-danger">*</span>
          </label>
          <input
            type="number"
            className="form-control-perso px-3"
            id="cardNumber"
          />
          <small className="text-danger"></small>
        </div>
      </div>
      <div className="form-group-container row mb-3">
        <div className="form-group col-md-6">
          <input
            type="checkbox"
            id="agreeTerms"
            className="form-custom-check border-5 "
          />
          <label htmlFor="agreeTerms" className="custom-label label-check">
            By continuing, you are agree to conditions
          </label>
          <small className="text-danger"></small>
        </div>
      </div>
      <div className="d-flex justify-content-start mt-5">
        <button
          className="btn-taf-primary"
          onClick={() => navigation.go("coupon-confirm")}
        >
          CONFIRM BOOKING
        </button>
      </div>
    </>
  );
};

export default FormPaymentInformation;
