import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";

const Historique = () => {
  const [index, setIndex] = React.useState<number>(0);
  const timeline = useSelector(
    (state: RootStateOrAny) => state.timeline_front?.data ?? []
  );
  if (!timeline?.length) return <OldHistorique />;
  return (
    <div className="d-flex" style={{ gap: "3rem" }}>
      <div className="position-relative">
        <ul className="his-steps-container">
          {timeline?.map((step, i) => (
            <li
              key={i}
              className={`his-step ${i === index && "active"}`}
              onClick={() => setIndex(i)}
              role="button"
            >
              {step.title}
            </li>
          ))}
        </ul>
      </div>
      <div className="his-content pt-2">
        {timeline.map((c, i) => {
          if (i !== index) return <span key={i} />;
          return (
            <div key={i} className="his-text-container">
              <p
                className="his-text"
                dangerouslySetInnerHTML={{ __html: c.description }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Historique;

const steps = ["1992", "1998", "2000", "2011", "2015", "2017", "2018", "2021"];

const OldHistorique = () => {
  const [index, setIndex] = React.useState<number>(0);
  return (
    <div className="d-flex" style={{ gap: "3rem" }}>
      <div className="position-relative">
        <ul className="his-steps-container">
          {steps?.map((step, i) => (
            <li
              key={i}
              className={`his-step ${i === index && "active"}`}
              onClick={() => setIndex(i)}
              role="button"
            >
              {step}
            </li>
          ))}
        </ul>
      </div>
      <div className="his-content pt-2">
        {index === 0 && (
          <div className="his-text-container">
            <p className="his-text">
              responding to the increase in demand for building materials and
              also in order to expedite its own construction activities, the
              building product wing of TAF Holding Company was formed and
              registered as TAF Building Products (TBP).
            </p>
            <p className="his-text">
              In addition to the sale of quality building materials, this
              division serves as the regional agent for RIED STRUCTURAL STEEL
              SYSTEMS, a reputable supplier in the UK.
            </p>
          </div>
        )}
        {index === 1 && (
          <div className="his-text-container">
            <p className="his-text">
              Over time, TCL established different business entities, which
              compliment each other. Each entity within TAF occupies a prominent
              position in its respective sector of operation. In order to
              streamline the management structure of all these entities,{" "}
              <strong> TAF HOLDING COMPANY (THC) </strong> was incorporated
            </p>
          </div>
        )}
        {index === 2 && (
          <div className="his-text-container">
            <p className="his-text">
              Taf Holding Company Limited ventured into large-scale housing
              development code-named “OPERATION HOUSE THE NATION”. With this
              project, two sites were allocated to us for the development of
              low, middle and high-income houses.
            </p>
            <p className="his-text">
              The first site <strong> YARAMBAMBA HOUSING ESTATE </strong> in
              Yundum comprises of two hundred and ten (210), two and three
              bedroom houses being sold at $15,000 and $20,000 respectively,
              with all amenities such as roads, mosque, and market provided. The
              total project cost was $4.5 million and the company received $1.00
              million as co-financing from Shelter Afrique, a Pan African
              Housing Finance Institution based in Kenya. The project was
              finished early 2004.
            </p>
          </div>
        )}
        {index === 3 && (
          <div className="his-text-container">
            <p className="his-text">
              TAF Nigeria Homes as a subsidiary company to Taf Holding Company
              Limited in partnership with the Government of Rivers State,
              through a special purpose vehicle incorporated as RIVTAF Nigeria
              Limited, developed a middle to a high-income housing estate in
              Port Harcourt known as
              <strong> RIVTAF Golf Estate </strong>. While the Government
              provided 39.82 hectares of land for the project at Peter Odili
              area of Port Harcourt, Rivers State, Nigeria, TAF Nigeria Homes
              Limited provided the expertise and financing for the design,
              development and management of the RIVTAF Golf Estate project. The
              project consisted of the development, building, and management of
              middle to high-income housing estate as well as the development
              and delivery of infrastructures such as a clubhouse (with
              facilities such as gym, swimming pool), recreation centre, a
              9-hole golf course, shopping mall, 24 hours security (both armed
              and unarmed security operatives), central water, sewage and
              electricity systems.
            </p>
          </div>
        )}
        {index === 4 && (
          <div className="his-text-container">
            <p className="his-text">
              TAF Rwanda Homes, TAF Senegal Homes, TAF Cameroon Homes was
              registered in a vision to build 1 million homes in sub-regional
              Africa within 20 years.
            </p>
          </div>
        )}
        {index === 5 && (
          <div className="his-text-container">
            <p className="his-text">
              Taf Holding Company was renamed Taf Africa Global Ltd and
              registered in Dubai as the parent company of 8 subsidiary
              companies in 8 African countries namely Gambia, Nigeria, Senegal,
              Rwanda, Ivory Coast, Cameroon, Togo, Mozambique.
            </p>
          </div>
        )}
        {index === 6 && (
          <div className="his-text-container">
            <p className="his-text">
              Taf Africa Global completed the{" "}
              <strong> DALABA Housing Estate </strong>
              consisting of 374 affordable houses which were built and delivered
              in 2 years. The Dalaba housing estate is a beautiful gated
              community strategically located along the newly constructed Sukuta
              Jabang highway and it is 10 minutes’ drive from the Senegambia
              strip and 15 minutes’ drive from the Banjul International Airport.
              Once completed, the estate will have 374 housing units along with
              world class amenities including all weather roads, solar
              streetlights, water reticulation systems, proper drainage systems,
              all fenced and gated service plots/houses.
            </p>
            <p className="his-text">
              Two other residential estates namely{" "}
              <strong> Tulip Gardens </strong> and
              <strong> Murraya Gardens </strong> were launched in The Gambia in
              2020 comprising of 600 units and 20 units respectively.
            </p>
          </div>
        )}
        {index === 7 && (
          <div className="his-text-container">
            <p className="his-text">
              In order to further achieve the aforementioned vision, TAG is
              developing a city dubbed TAF City. <strong> TAF City </strong> is
              an eco-smart city designed to be a live, work and play city. TAF
              City is targeted to be developed on about 500 hectares of land
              which is twice the current size of Banjul the capital city of The
              Gambia. It is a phased city development located in Kombo South
              between the villages of Gunjur and Sifoe from the main road to
              Allahein river, which is the border between The Gambia and
              Senegal. It is a 30-minute drive from the Banjul International
              Airport.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
