import { useEffect, useState } from "react";
import { ArrowLeft } from "react-iconly";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import ChatImg from "../../../images/icons/chat-listing.png";
import PaperImg from "../../../images/icons/paper-listing.png";
import ShowImg from "../../../images/icons/show-listing.png";
import { Amineties } from "../../user/listing/AmnetieItem";
import { Gallery } from "../../user/projets/DetailsProjet";
import PlayerVideo from "../../user/shared/PlayerVideo";
import HeaderAdmin from "../headerAdmin/HeaderAdmin";
import { GoogleMap } from "../ListingAdmin/GoogleMap";
import NavbarVertical from "../nav/NavbarVertical";

const DetailsProjetsAdmin = (props) => {
  const [project, setProject] = useState<any>();
  const location = useLocation<any>();
  const history = useHistory();

  const project_data = location.state?.row;
  const reviews = location.state?.reviews?.data;
  const reservations = location.state?.reservations?.data;
  const review_datas =
    reviews && reviews.filter((x: any) => x.project_id === project_data?.id);
  const reservation_datas =
    reservations &&
    reservations.filter((x: any) => x.project_id?.id === project_data?.id);

  const defaultProps = {
    center: {
      lat: parseFloat(project_data?.latitude || 0),
      lng: parseFloat(project_data?.longitude || 0),
    },
    zoom: 11,
  };

  useEffect(() => {
    if (location?.state) {
      setProject(project_data);
    } else {
      history.goBack();
    }
  }, [location, history, project_data]);

  const pictures = project?.pictures?.length > 0 ? project?.pictures : [];
  return (
    <div className="container-fluid body-background-dashboard">
      <HeaderAdmin />
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavbarVertical />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header mt-4 px-0">
          <div className="projets-container bloc-principal-body-content">
            <div className="profile-containers">
              <div className="container-profile-edit">
                <div className="d-flex align-items-center">
                  <Link to="/admin/projets" className="btn btn-add-listing">
                    <ArrowLeft set="light" primaryColor="#B0B0B0" />
                  </Link>
                  <h1 className="mon-profil pl-2">
                    {project?.property_name}
                    {project?.property_type?.name
                      ? ", " + project?.property_type?.name
                      : ""}{" "}
                  </h1>
                </div>
              </div>
              <div className="bloc-principal-body-content p-0">
                <div className="dashboard-card-container d-flex flex-column flex-md-row">
                  <div className="dashboard-card card-details-listing">
                    <div className="">
                      <img src={ShowImg} alt="icone dashbord" />
                    </div>
                    <div className="dasboard-card-info">
                      <h1 className="dasboard-card-title">Total views</h1>
                      <h3 className="dasboard-card-montant">
                        {project?.vus}{" "}
                        <small className="dasboard-card-title">Views </small>
                      </h3>
                    </div>
                  </div>
                  <div className="dashboard-card card-details-listing">
                    <div className="">
                      <img src={ChatImg} alt="icone dashbord" />
                    </div>
                    <div className="dasboard-card-info">
                      <h1 className="dasboard-card-title">Total comment</h1>
                      <h3 className="dasboard-card-montant">
                        {review_datas.length}{" "}
                        <small className="dasboard-card-title">
                          {review_datas.length > 1 ? "Comments" : "Comment"}{" "}
                        </small>
                      </h3>
                    </div>
                  </div>
                  <NavLink
                    className="dashboard-card card-details-listing"
                    to={{
                      pathname: `/admin/project/reservations/${project_data?.slug}`,
                      state: { project: project_data },
                    }}
                  >
                    <div className="">
                      <img src={PaperImg} alt="icone dashbord" />
                    </div>
                    <div className="dasboard-card-info">
                      <h1 className="dasboard-card-title">Total reservation</h1>
                      <h3 className="dasboard-card-montant">
                        {reservation_datas.length}{" "}
                        <small className="dasboard-card-title">
                          {reservation_datas.length > 1
                            ? "Reservations"
                            : "Reservation"}{" "}
                        </small>
                      </h3>
                    </div>
                  </NavLink>
                </div>
                <div className="row my-3">
                  <Gallery
                    pictures={pictures}
                    image={project?.image}
                    name={project?.property_name}
                  />
                </div>
                <div className="rectangle-bottom mt-4"></div>
                <div className="card-listing p-3 mt-5">
                  <h2 className="mid-listing-title">Description</h2>
                  <div className="det-feat-container">
                    <div className="det-feat-ite">
                      <p
                        className="cla-text"
                        dangerouslySetInnerHTML={{
                          __html: project?.description,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="project-container mb-4 ">
                  <div className="card-listing mt-4 p-3">
                    <h2 className="mid-listing-title">Amenities</h2>
                    <Amineties amneties={project?.ameneties} />
                  </div>
                  {!!project?.video_presentation && (
                    <div className="card-listing mt-4 p-3">
                      <h2 className="mid-listing-title mb-3">Project video</h2>
                      <PlayerVideo
                        url={project?.video_presentation}
                        showControls={false}
                      />
                    </div>
                  )}
                  <div className="card-listing mt-4 p-3">
                    <h2 className="mid-listing-title mb-3">Map</h2>
                    <GoogleMap defaultProps={defaultProps} />
                  </div>
                  {!!project?.virtual_video && (
                    <div className="card-listing mt-3 p-3">
                      <div className="flex-sb">
                        <h2 className="mid-listing-title mb-3">
                          Virtual visit
                        </h2>
                      </div>
                      <PlayerVideo
                        url={project?.virtual_video}
                        showControls={false}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DetailsProjetsAdmin;
