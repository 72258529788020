import React, { useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import baseUrl from "../../../Http/backend-base-url";
import Group from "../../../images/icons/Group.png";
import "./CarouselClient.scss";
import "./MultiSliderClient.css";
import Carousel from "./TestCarousel";

const MultiSliderClient: React.FC = () => {
  const [selectedIndex, setSelectedIndex] = useState<any>(0);
  const testimonials_front = useSelector(
    (state: RootStateOrAny) => state.testimonials_front
  );

  const slides: any = [];

  testimonials_front.data &&
    testimonials_front.data.map((item: any) => {
      slides &&
        slides.push(
          <li
            id={`item${item.id}`}
            className={`caroussel-image-item-container ${
              item === 0 ? "active" : ""
            }`}
          >
            <img
              src={
                item.image === "" ||
                item.image === undefined ||
                item.image === null
                  ? `https://ui-avatars.com/api/?name=${item?.full_name}`
                  : `${baseUrl + item?.image}`
              }
              className="caroussel-img"
              alt="Carousel clients"
            />
          </li>
        );
    });

  const callback = function (index) {
    // console.log("callback", index);
    if (slides?.length < 3) {
      if (index === 2) {
        setSelectedIndex(0);
      } else if (index === 3) {
        setSelectedIndex(1);
      } else {
        setSelectedIndex(index);
      }
    } else {
      setSelectedIndex(index);
    }
    // setSelectedIndex(index);
  };

  return (
		<div className="bloc-carousel-client-container">
			<div className="row">
				<div className="col-md-8">
					{slides?.length ? (
						<Carousel
							autoplay={true}
							interval={10000}
							slides={slides}
							onSlideChange={callback}
						/>
					) : null}
				</div>
				<div className="col-md-4">
					<div className="caroussel-textual-container">
						<div className="caroussel-textual-item-container">
							{testimonials_front.data &&
								testimonials_front.data.map((item: any, index) => {
									return (
										<div
											data-target={`item${item.id}`}
											key={`tesimonial${index}`}
											className={`textual-item ${
												index === selectedIndex
													? "selected"
													: ""
											}`}
										>
											<div className="bloc-flex-img-text">
												<div className="div-inherit-position div-inherit-position-icon">
													<img
														src={Group}
														alt="Carousel Client"
														className="img-icon-carousel-item"
													/>
												</div>
												<div className="div-inherit-position div-inherit-position-text">
													<p
														className="text-temoignage-client"
														dangerouslySetInnerHTML={{
															__html: item?.content,
														}}
													></p>
												</div>
											</div>
											<p className="nom-client-carousel">
												{item?.full_name ?? ""}
											</p>
											<p className="libelle-client-carousel">
												{item?.function ?? ""}
											</p>
										</div>
									);
								})}
						</div>
					</div>
				</div>
			</div>
		</div>
  );
};

export default MultiSliderClient;
