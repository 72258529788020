import React from "react";
// import Reply from "../../../images/icons/reply.png";
import { ArrowDown } from "react-iconly";
import moment from "moment";
interface Props {
	comments: any;
	article: number;
}

const Comments: React.FC<Props> = ({ comments, article }) => {
	const commentLenght = comments?.data?.filter((x) => x.article === article).length;

	return (
		<div className="blog-comments-container card-blog mt-4 pt-3 pb-2">
			<div className="mb-2 px-3">
				<h2 className="number-of-comments">Comments({commentLenght})</h2>
			</div>
			{comments?.data?.filter((x) => x.article === article).map((comment, index) => (
				<div
					className={`blog-comment-item p-3 ${index < commentLenght - 1 &&
						"border-comment-item"
						}`}
					key={comment.id}
				>
					<div className="comment-details">
						<div className="author-comment-info-container">
							<div className="autor-comment-avatar-container">
								<img src={`https://ui-avatars.com/api/?name=${comment?.name}`} alt="avatar" height='50' width='50' />
							</div>
							<div className="author-comment-info">
								<h2 className="author-comment-name">
									{
										comment.name
									}
								</h2>
								<h6 className="date-comment text-green">
									{moment(comment?.created_at).format("DD MMMM YYYY")}
								</h6>
							</div>
						</div>
						<button className="reply-comment-container">
							{/* <div className="reply-icon-container">
								<img
									src={Reply}
									alt="reply-icon"
								/>
							</div> */}
							{/* <div className="reply-text-container">
								<h6 className="reply-comment-text">
									Reply
								</h6>
							</div> */}
						</button>
					</div>
					<div className="comment-text-container mt-2">
						<p className="comment-text">
							{comment?.message}
						</p>
					</div>
				</div>
			))}
			<div className="see-more-button-container">
				<button className="see-more-button">

					{
						commentLenght > 10 ? (
							<>
								<span className="arrow-down-container bg-green">
									<ArrowDown
										style={{ height: "12px" }}
										stroke="bold"
										primaryColor="#FFF"
									/>
								</span>
								<span className="see-more-container">
									<span className="see-more-comments text-green">
										See more comments
									</span>
								</span>
							</>
						) : (
							''
						)
					}

				</button>
			</div>
		</div>
	);
};

export default Comments;
