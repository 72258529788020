import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
// import rectangleslider from '../../../images/rectangleslider.png'
// import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import * as yup from "yup";
import baseUrl from "../../../Http/backend-base-url";
import { fetchSlider } from "../../../redux/Slider/SliderAction";
import { SliderData } from "../../../repositories/user/SliderData";
import SliderService from "../../../services/Slider";
import { ContentEditor } from "../ListingAdmin/ListingDetailsDeBaseSecondStep";
import "./Sliders.css";

const Sliders = ({ slide, onDone }: { slide: any; onDone: () => any }) => {
  const [isSuccessfullySubmittedForm, setIsSuccessfullySubmittedForm] =
    React.useState(false);
  const [image, setImage] = useState<any>();
  const [imageShow, setImageShow] = React.useState<any>(null);
  const handleChange = (e: any) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      setImageShow(URL.createObjectURL(file));
      setImage(file);
    }
  };
  const dispatch = useDispatch();

  const validationSchema = yup.object().shape({
		catch_phrase: yup.string(),
  });

  const { register, handleSubmit, formState, setValue, reset } =
    useForm<SliderData>({
      mode: "onBlur",
      resolver: yupResolver(validationSchema),
    });
  const { errors } = formState;

  const token = window.localStorage.getItem("userToken");
  const userID = window.localStorage.getItem("userID");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  useEffect(() => {
    register("catch_phrase");
    register("link");
    register("button_text");
    if (slide?.id) {
      setValue("catch_phrase", slide.catch_phrase);
      setValue("link", slide.link || "");
      setValue("button_text", slide.button_text || "");
      if (slide.image) {
        setImageShow(baseUrl + slide.image);
      }
    } else {
      setValue("catch_phrase", "");
      setValue("link", "");
      setValue("button_text", "");
      setImage(null);
      setImageShow(null);
      $("#image").val("");
    }
  }, [slide]);

  const submitAddSlider = (data: SliderData) => {
    setIsSuccessfullySubmittedForm(true);
    // setSubmitedForm(true)
    const fd = new FormData();
    fd.append("catch_phrase", data.catch_phrase);
    fd.append("link", data.link || "");
    fd.append("button_text", data.button_text || "");
    fd.append("created_by", userID ? userID : "null");
    if (image) {
      fd.append("image", image);
    } else if (!slide?.id) {
      Swal.fire({
        icon: "error",
        title: "Error",
        html: "Please select an image",
        iconColor: "#df5656",
        showCancelButton: false,
        confirmButtonColor: "#df5656",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
        allowOutsideClick: false,
      });
      setIsSuccessfullySubmittedForm(false);
      return;
    }

    const request = slide?.id
      ? SliderService.onEditSlider(slide.id, fd, config)
      : SliderService.onAddSlider(fd, config);
    request
      .then(async (res) => {
        await Swal.fire({
          icon: "success",
          title: "Success",
          html: slide?.id
            ? "Slider updated successfully!"
            : "Slider saved successfully!",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          allowOutsideClick: false,
        }).then((result) => {
          $(".modal").modal("hide");
          onDone();
          dispatch(fetchSlider());
          setImage(null);
          setImageShow(null);
          reset();
        });

        setIsSuccessfullySubmittedForm(false);
      })
      .catch((error) => {
        console.log("error ", error);
        Swal.fire({
          icon: "error",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          title: "Slider not saved successfully.Please Check your entered data",
          showConfirmButton: true,
          allowOutsideClick: false,
        });
        setIsSuccessfullySubmittedForm(false);
      });
  };

  return (
    <div className="modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content faq-modal-content">
        <div className="modal-header modal-faq-header">
          <h5 className="modal-title titre-question-reponse">
            {slide?.id ? "Update slide" : "Add slide"}
          </h5>
          <button
            type="button"
            className="close close-icon"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form
          className="py-2 px-2"
          onSubmit={handleSubmit(submitAddSlider)}
          id="formSlider"
        >
          <div className="form-group-add-project-group-container row">
            <div className="form-add-project-group form-group-add-project-dropzone-container pl-3 mb-4">
              <label htmlFor="projectImage" className="form-add-project-label">
                Add picture
              </label>
              <input
                type="file"
                className="form-control-profil"
                name="image"
                id="image"
                onChange={handleChange}
              />
              {imageShow && (
                <div>
                  <img
                    src={imageShow}
                    className="card-img-top img-listing-card-item mt-2"
                    alt="Image"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="infos-perso-input">
                <label className="form-label-text">Redirect Link</label>
                <input
                  type="link"
                  className="form-control-profil"
                  placeholder="Ex: https://youtube.com/"
                  name="link"
                  ref={register}
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="infos-perso-input">
                <label className="form-label-text">Button text</label>
                <input
                  type="link"
                  className="form-control-profil"
                  placeholder="Ex: Read more"
                  name="button_text"
                  ref={register}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="infos-perso-input">
                <label className="form-label-text">Catch phrase</label>
                <ContentEditor
                  data={slide?.catch_phrase}
                  setValue={setValue}
                  name="catch_phrase"
                />
                {errors.catch_phrase && (
                  <div className="alert alert-danger sia-alert-danger closer mt-2">
                    {errors.catch_phrase?.message}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end mt-2">
            {!isSuccessfullySubmittedForm ? (
              <button
                className="btn-taf-primary"
                type="submit"
                form="formSlider"
              >
                {slide?.id ? "Update" : "Save"}
              </button>
            ) : (
              <span className="btn-taf-primary">
                <i className="fa fa-spin fa-spinner"></i>&nbsp;&nbsp;In Progress
              </span>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Sliders;
