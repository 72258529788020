import { Delete, EditTwoTone } from "@material-ui/icons/";
import Axios from "axios";
import moment from "moment";
import React, { useEffect } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import baseUrl from "../../../../Http/backend-base-url";
import api from "../../../../Http/global-vars";
import { articleList } from "../../../../redux/Article/articleAction";
import ArticleSkeleton from "./ArticleSkeleton";

const ArticleTable = (props) => {
  const [isPageLoading, setIsPageLoading] = React.useState(false);

  const [articleData, setArticleData] = React.useState();
  const article_data = useSelector((state: RootStateOrAny) => state.article);
  const dispatch = useDispatch();

  const getArticle = (data: any) => {
    setArticleData(data);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsPageLoading(false);
    }, 3000);
  }, [article_data.data]);

  const options = {
    clearSearch: false,
    noDataText: "Aucune donnée disponible",
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-container">
        <NavLink
          to={{
            pathname: `/admin/show-article-detail/${row.id}`,
            state: { row },
          }}
          style={{ color: "#9B51E0" }}
          className="mr-2"
          onClick={() => {
            getArticle(row);
          }}
          title="Show this article"
        >
          <IconlyPack.Show />
        </NavLink>
        <NavLink
          to={{
            pathname: `/admin/article-detail-update/${row.id}`,
            state: { row },
          }}
          style={{ color: "#2D3748" }}
          className="mr-2"
          onClick={() => {
            getArticle(row);
          }}
          title="Edit this article"
        >
          <EditTwoTone style={{ fontSize: "20px" }} />
        </NavLink>

        <NavLink
          to="#"
          style={{ color: "#E53E3E" }}
          className="mr-2"
          onClick={() => onDelete(row.id)}
          title="Delete this article"
        >
          <Delete style={{ fontSize: "20px" }} />
        </NavLink>
      </div>
    );
  };

  const getDateRegister = (cell, row) => {
    return moment(row?.date_joined).format("YYYY-MM-DD");
  };
  const onDelete = (id) => {
    const token = window.localStorage.getItem("userToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    Swal.fire({
      title: "Do you want to delete this news?",
      iconColor: "#df5656",
      showCancelButton: true,
      confirmButtonColor: "#df5656",
      cancelButtonColor: "#bfc0c9",
      confirmButtonText: "YES",
      cancelButtonText: "NON",
      allowOutsideClick: false,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return Axios.delete(`${api}article/${id}/`, config)
          .then(async (response) => {
            console.log("res", response);
            await dispatch(articleList());
            return response;
          })
          .catch((error) => {
            // console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log("res", result);
        Swal.fire({
          icon: "success",
          title: "Success",
          html: "News deleted successfully!",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          // allowOutsideClick: false,
          timer: 3000,
        });
      }
    });
  };
  const photoFormatter = (cell, row) => {
    return (
      <img
        src={
          row?.image === null
            ? `https://ui-avatars.com/api/?name=${row?.title}`
            : `${baseUrl + row?.image}`
        }
        alt="avatar"
        height="50"
        width="50"
      />
    );
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container-activite">
        {/* Filtres */}

        <div className="row easypm-table-row">
          <div className="col-md-12 easypm-table-col">
            {/* {article_data.length ? ( */}
            <>
              {isPageLoading && <ArticleSkeleton />}
              {!isPageLoading && (
                <div className="table-container-activite">
                  <BootstrapTable
                    data={article_data?.data}
                    hover={true}
                    condensed={false}
                    multiColumnSort={2}
                    options={options}
                    search={false}
                    version="4"
                    bordered={false}
                    striped={true}
                    pagination
                  >
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2D3748",
                      }}
                      width="180"
                      dataField="id"
                      isKey={true}
                      hidden
                    ></TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2D3748",
                      }}
                      width="60"
                      dataField="title"
                      dataFormat={(cell, row) => photoFormatter(cell, row)}
                    >
                      Image
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2D3748",
                      }}
                      width="180"
                      dataField="title"
                      filter={{ type: "TextFilter" }}
                    >
                      Title
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2D3748",
                        fontFamily: "Raleway",
                      }}
                      width="100"
                      dataField="writen_by"
                      filter={{ type: "TextFilter" }}
                    >
                      Written by
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2D3748",
                        fontFamily: "Raleway",
                      }}
                      width="100"
                      dataField="created_at"
                      dataFormat={(cell, row) => getDateRegister(cell, row)}
                      filter={{ type: "TextFilter" }}
                    >
                      Date
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Raleway",
                      }}
                      dataField="id"
                      width="100"
                      dataFormat={(cell, row) => actionFormatter(cell, row)}
                    >
                      Actions
                    </TableHeaderColumn>
                  </BootstrapTable>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleTable;
