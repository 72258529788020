import React from "react";
import { useStep } from "react-hooks-helper";
import FrontFooter from "../../frontFooter/FrontFooter";
import Header from "../../header/Header";
import "./Checkout.css";
import CheckoutStepper from "./CheckoutStepper";
import CouponConfirmation from "./CouponConfirmation";
import CustomerDetailSideLeft from "./CustomerDetailSideLeft";
import FormCustomerDetail from "./FormCustomerDetail";
import FormPaymentInformation from "./FormPaymentInformation";
import PaymentInformationCard from "./PaymentInformationCard";

const steps = [
  { id: "customer-detail", Component: FormCustomerDetail },
  { id: "payement-information", Component: FormPaymentInformation },
  { id: "coupon-confirm", Component: CouponConfirmation },
];
const Checkout: React.FC = () => {
  const { step, index, navigation } = useStep({ initialStep: 0, steps });

  const { Component } = step;

  const props = { navigation };

  return (
    <div className="component-front-home">
      <Header />
      <div className="p-t-150 m-b-100 container-page-about-home">
        <div className="container-taf">
          <div className="row">
            <div className="col-12">
              <h1 className="checkout-title">Check out</h1>
              <h6 className="proceed-payement">Proceed for payment</h6>
            </div>
          </div>
        </div>
        <hr className="checkout-bar" />
        <div className="container-taf">
          {index < 2 ? (
            <div className="row mt-5">
              <div className="col-md-4 col-xl-3">
                {index === 0 && <CustomerDetailSideLeft />}
                {index === 1 && <PaymentInformationCard />}
              </div>
              <div className="col-md-8 col-xl-9">
                <CheckoutStepper index={index} />
                <div className="form-container card-checkout p-3 mt-4">
                  {index === 0 && (
                    <h1 className="form-check-title">Biling detail</h1>
                  )}
                  {index === 1 && (
                    <h1 className="form-check-title">Payment information</h1>
                  )}
                  <form className="mt-5">
                    <Component props={props} />
                  </form>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-12">
                <CheckoutStepper index={index} />
                <Component props={props} />
              </div>
            </div>
          )}
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default Checkout;
