// import ModalShowMessage from "./ModalContact/ModaleShowMessage";
import moment from "moment";
import React from "react";
import * as IconlyPack from "react-iconly";
import { RootStateOrAny, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import ModalContact from "../ContactAdmin/ModalContact/ModalContact";
import HeaderAdmin from "../headerAdmin/HeaderAdmin";
import NavbarVertical from "../nav/NavbarVertical";
import "./ContactAdmin.css";

function Contactadmin(props) {
  const contact_data = useSelector((state: RootStateOrAny) => state.contact);
  const [dataContact, setDataContact] = React.useState();

  const getMessage = (data) => {
    setDataContact(data);
  };

  return (
    <div className="container-fluid body-background-dashboard">
      <HeaderAdmin />

      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavbarVertical />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header mt-4 px-0">
          <div className="projets-container bloc-principal-body-content">
            <div className="profile-containers">
              <h3 className="admin-contacts-title">Contact messages</h3>
              <div className="card-contacts-main-content">
                {contact_data.data.length > 0 ? (
                  contact_data.data.map((item: any) => {
                    return (
                      <div>
                        <NavLink
                          to="#"
                          style={{ color: "#9B51E0" }}
                          className="mr-2 cursor-pointer"
                          data-toggle="modal"
                          data-target="#contactModal"
                          onClick={() => getMessage(item)}
                        >
                          <div className="row div-card">
                            <div className="col-6">
                              <h4 className="faq-card-title faq-card-title-couper">
                                {item?.subject}
                              </h4>
                              <p className="contact-card-para">
                                Le{" "}
                                {moment(item?.created_at).format(
                                  "DD MMM YYYY hh:mm"
                                )}{" "}
                              </p>
                            </div>
                            <div className="col-6">
                              <div className="div-nouveau-name">
                                <div>
                                  <button
                                    type="button"
                                    className={
                                      item?.statut === false
                                        ? `btn contact-btn-nouveau`
                                        : `btn contact-btn-treaty`
                                    }
                                    data-toggle="modal"
                                    // data-target="#contactModal"
                                    // onClick={() => getMessage(item)}
                                  >
                                    {item?.statut === false ? "NEW" : "TREATY"}
                                  </button>
                                </div>
                                <div className="div-icon">
                                  <div>
                                    <p className="contact-name-para">
                                      {item?.name}
                                    </p>
                                    <p className="contact-name-para">
                                      {item?.email}
                                    </p>
                                  </div>

                                  <div>
                                    <IconlyPack.Show />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </NavLink>
                      </div>
                    );
                  })
                ) : (
                  <div className="card-contacts-main-content">
                    <div className="row div-card">
                      <div className="col-12">
                        <h4 className="admin-contacts-title text-center">
                          No contacts available
                        </h4>
                      </div>
                    </div>
                  </div>
                )}

                <ModalContact datas_contact={dataContact} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contactadmin;
