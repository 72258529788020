import React from "react";
import { Calendar } from "react-iconly";
import { NavLink } from "react-router-dom";
import baseUrl from "../../../Http/backend-base-url";
interface Props {
  trendingPosts: any;
}

const TrendingPosts: React.FC<Props> = ({ trendingPosts }) => {
  return (
		<div className="trending-posts-container mt-4">
			<h2 className="category-title">Trending posts</h2>
			{trendingPosts?.data.length > 0 ? (
				trendingPosts?.data.map((article) => (
					<NavLink
						to={{
							pathname: `/blog/${article?.slug}`,
							state: { article },
						}}
						className="trending-post-card my-3 card-blog  p-3"
						key={article.id}
					>
						<div className="trending-post-img-container">
							<img
								height="100"
								width="100"
								src={
									article?.image === null
										? `https://ui-avatars.com/api/?name=${article?.title}`
										: `${baseUrl + article?.image}`
								}
								alt="avatar"
								className="trending-post-img"
							/>
						</div>
						<div className="trending-post-details">
							<h3 className="trending-post-title">
								{article?.title ?? ""}
							</h3>
							<div className="trending-post-date-container d-flex align-items-center">
								<Calendar
									primaryColor="#828282"
									style={{ height: "15px" }}
								/>
								<span
									className="trending-post-date ml-3"
									dangerouslySetInnerHTML={{
										__html:
											article?.content.substring(
												0,
												80
											) + "..." ?? "",
									}}
								/>
							</div>
						</div>
					</NavLink>
				))
			) : (
				<div className="trending-post-card mb-3">
					<div className="trending-post-img-container">
						No article available
					</div>
				</div>
			)}
		</div>
  );
};

export default TrendingPosts;
