import React from "react";
import ImgBackground from "../../images/background.png";
// import { NavLink } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import Axios from "axios";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import api from "../../Http/global-vars";

const ModifierPassword: React.FC = () => {
  const [isSuccessfullySubmittedForm, setIsSuccessfullySubmittedForm] =
    React.useState(false);

  const history = useHistory();

  const validationSchema = yup.object().shape({
    email: yup.string().email("Enter a valid email").required(),
  });

  const { register, handleSubmit, reset, formState } = useForm<any>({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  const { errors } = formState;

  const onSubmitResetPassword = async (data: any) => {
    setIsSuccessfullySubmittedForm(true);

    const request = Axios.post(`${api}request-password-reset/`, data);
    await request
      .then(async (resp) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          html: "Request sent successfully, we have sent an email to your inbox to reset your password!",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            history.push("reset-password");
            reset();
          }
        });

        setIsSuccessfullySubmittedForm(false);
      })
      .catch((error) => {
        // console.error("error", error?.response?.data?.message);
        if (
          error?.response?.data?.message ===
          "L'utilisateur avec cet email n'existe pas"
        ) {
          Swal.fire({
            icon: "error",
            title: "Errorr",
            html: "The user with this email does not exist!",
            iconColor: "#df5656",
            showCancelButton: false,
            confirmButtonColor: "#df5656",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            allowOutsideClick: false,
          });
        }
        setIsSuccessfullySubmittedForm(false);
      });
  };

  return (
    <div className="p-t-100">
      <div className="login-component-container">
        <div className="row">
          <div className="col-xl-5 col-lg-5 col-md-5">
            <div>
              <img
                src={ImgBackground}
                className="w-full"
                alt="background login"
                style={{
                  height: "100vh",
                }}
              />
            </div>
          </div>
          <div className="col-xl-7 col-lg-7 col-md-7">
            <div className="card-connexion py-5">
              <p className="titre-connexion p-t-20">Password Recovery</p>
              <form
                id="login-form"
                onSubmit={handleSubmit(onSubmitResetPassword)}
              >
                <div className=" my-4">
                  <p className="text-comment pb-3 text-center">
                    Please enter your email.We send you a code to change the
                    password
                  </p>
                </div>

                <div className="form-group">
                  <label className="label-input">Email</label>
                  <input
                    name="email"
                    id="email"
                    type="email"
                    className="form-control input-with-icon"
                    data-testid="emailId"
                    ref={register}
                  />
                  {errors.email && (
                    <div className="alert alert-danger sia-alert-danger closer mt-2">
                      {errors.email?.message}
                    </div>
                  )}
                </div>
                <div className="col-md-4 offset-md-4 py-3 px-0">
                  {!isSuccessfullySubmittedForm ? (
                    <button
                      type="submit"
                      className="btn-connexion"
                      data-testid="buttonId"
                    >
                      Send
                    </button>
                  ) : (
                    <span className="btn-connexion">
                      <i className="fa fa-spin fa-spinner"></i>&nbsp;&nbsp;In
                      Progress
                    </span>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModifierPassword;
