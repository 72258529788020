import { Delete, EditTwoTone } from "@material-ui/icons/";
import Axios from "axios";
import moment from "moment";
import React, { useEffect } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import baseUrl from "../../../Http/backend-base-url";
import api from "../../../Http/global-vars";
import { fetchAdmins } from "../../../redux/admins/adminsAction";
import UsersData from "../Users/UsersTable/UsersData";
import ModalShowAdmin from "./ModalParametre/ModalShowAdmin";
import ModalUpdateAdmin from "./ModalParametre/ModalUpdateAdmin";
import AdministratorSkeleton from "./skeletons/AdministratorSkeleton";

const Listeadmin = (props) => {
  const [isPageLoading, setIsPageLoading] = React.useState(true);
  const dispatch = useDispatch();

  const [usersData, setUsersData] = React.useState(UsersData);
  const [userData, setUserData] = React.useState();
  const adminuser = useSelector((state: RootStateOrAny) => state.adminuser);

  useEffect(() => {
    setTimeout(() => {
      setIsPageLoading(false);
    }, 3000);
  }, [usersData]);

  const options = {
    clearSearch: false,
    noDataText: "Aucune donnée disponible",
  };

  const photoFormatter = (cell, row) => {
    return (
      <img
        src={
          row.image === "" || row.image === undefined || row.image === null
            ? `https://ui-avatars.com/api/?name=${row?.fullname}`
            : `${baseUrl + row?.image}`
        }
        alt="avatar"
        className="user-img"
      />
    );
  };

  const nameFormatter = (cell, row) => {
    return (
      <div className="name-email-group">
        <span className="name">{row.fullname}</span>
      </div>
    );
  };

  const getDateRegister = (cell, row) => {
    return moment(row?.date_joined).format("YYYY-MM-DD");
  };

  const getUser = (data: any) => {
    setUserData(data);
  };

  const actionFormatter = (cell, row) => {
    if (row?.deleted === null) {
      return (
        <>
          <div className="table-actions-container">
            <NavLink
              to="#"
              style={{ color: "#9B51E0" }}
              className="mr-2"
              data-toggle="modal"
              data-target="#showAdminModal"
              onClick={() => getUser(row)}
              title="Show this admin"
            >
              <IconlyPack.Show />
            </NavLink>
            <NavLink
              to="#"
              style={{ color: "#2D3748" }}
              className="mr-2"
              data-toggle="modal"
              data-target="#updateAdminModal"
              onClick={() => getUser(row)}
              title="Edit this admin"
            >
              <EditTwoTone style={{ fontSize: "20px" }} />
            </NavLink>
            <NavLink
              to="#"
              style={{ color: "#E53E3E" }}
              className="mr-2"
              onClick={() => onDelete(row.id)}
              title="Delete this admin"
            >
              <Delete style={{ fontSize: "20px" }} />
            </NavLink>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="table-actions-container">
            <NavLink
              to="#"
              style={{ color: "#9B51E0" }}
              className="mr-2"
              onClick={() => unarchiveUser(row?.id)}
              title="Archived User"
            >
              <IconlyPack.Unlock />
            </NavLink>
          </div>
        </>
      );
    }
  };

  const token = window.localStorage.getItem("userToken");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const unarchiveUser = async (id: number) => {
    Swal.fire({
      title: "Do you want to unarchive this user ?",
      // showDenyButton: true,
      confirmButtonText: `YES`,
      cancelButtonText: `NO`,
      iconColor: "#df5656",
      showCancelButton: true,
      confirmButtonColor: "#df5656",
      cancelButtonColor: "#6c757d",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteRequest = Axios.get(
          `${api}restaurearchiveduser/${id}/`,
          config
        );
        deleteRequest
          .then((response) => {
            dispatch(fetchAdmins());
            Swal.fire({
              position: "center",
              icon: "success",
              title: "User unarchived successfully.",
              showConfirmButton: true,
              iconColor: "#df5656",
              confirmButtonColor: "#df5656",
              allowOutsideClick: false,
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Oops! An error occurred while deleting",
              showConfirmButton: true,
              iconColor: "#df5656",
              confirmButtonColor: "#df5656",
              allowOutsideClick: false,
            });
          });
      } else {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Unarchive cancels",
          showConfirmButton: true,
          iconColor: "#df5656",
          confirmButtonColor: "#df5656",
          allowOutsideClick: false,
        });
      }
    });
  };

  const onDelete = (id: number) => {
    Swal.fire({
      title: "Do you want to delete this user ?",
      // showDenyButton: true,
      confirmButtonText: `YES`,
      cancelButtonText: `NO`,
      iconColor: "#df5656",
      showCancelButton: true,
      confirmButtonColor: "#df5656",
      cancelButtonColor: "#6c757d",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteRequest = Axios.delete(`${api}user/${id}/`, config);

        deleteRequest
          .then((response) => {
            dispatch(fetchAdmins());
            Swal.fire({
              position: "center",
              icon: "success",
              title: "User deleted successfully",
              showConfirmButton: true,
              iconColor: "#df5656",
              confirmButtonColor: "#df5656",
              allowOutsideClick: false,
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Oops! An error occurred while deleting",
              showConfirmButton: true,
              iconColor: "#df5656",
              confirmButtonColor: "#df5656",
              allowOutsideClick: false,
            });
          });
      } else {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Deletion was successfully undone",
          showConfirmButton: true,
          iconColor: "#df5656",
          confirmButtonColor: "#df5656",
          allowOutsideClick: false,
        });
      }
    });
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container-activite">
        <div className="row easypm-table-row">
          <div className="col-md-12 easypm-table-col">
            {adminuser.data.length ? (
              <>
                {isPageLoading && <AdministratorSkeleton />}
                {!isPageLoading && (
                  <div className="table-container-activite">
                    <BootstrapTable
                      data={adminuser.data}
                      hover={true}
                      condensed={false}
                      multiColumnSort={2}
                      options={options}
                      search={false}
                      version="4"
                      bordered={false}
                      striped={true}
                      pagination
                    >
                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 12,
                          color: "#A0AEC0",
                          fontFamily: "Raleway",
                        }}
                        tdStyle={{
                          fontWeight: "normal",
                          fontSize: 14,
                          color: "#2D3748",
                        }}
                        width="50"
                        dataField="image"
                        isKey={true}
                        dataFormat={(cell, row) => photoFormatter(cell, row)}
                      >
                        Photos
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 12,
                          color: "#A0AEC0",
                          fontFamily: "Raleway",
                        }}
                        tdStyle={{
                          fontWeight: "normal",
                          fontSize: 14,
                          color: "#2D3748",
                          fontFamily: "Raleway",
                        }}
                        width="100"
                        dataField="id"
                        dataFormat={(cell, row) => nameFormatter(cell, row)}
                      >
                        Name
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 12,
                          color: "#A0AEC0",
                          fontFamily: "Raleway",
                        }}
                        tdStyle={{
                          fontWeight: "normal",
                          fontSize: 14,
                          color: "#2D3748",
                          fontFamily: "Raleway",
                        }}
                        width="100"
                        dataField="email"
                      >
                        Email
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 12,
                          color: "#A0AEC0",
                          fontFamily: "Raleway",
                        }}
                        tdStyle={{
                          fontWeight: "normal",
                          fontSize: 14,
                          color: "#2D3748",
                          fontFamily: "Raleway",
                        }}
                        width="100"
                        dataField="telephone"
                      >
                        Phone
                      </TableHeaderColumn>

                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 12,
                          color: "#A0AEC0",
                          fontFamily: "Raleway",
                        }}
                        tdStyle={{
                          fontWeight: "normal",
                          fontSize: 14,
                          color: "#2D3748",
                          fontFamily: "Raleway",
                        }}
                        width="100"
                        dataField="adresse"
                      >
                        Address
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 12,
                          color: "#A0AEC0",
                          fontFamily: "Raleway",
                        }}
                        tdStyle={{
                          fontWeight: "normal",
                          fontSize: 14,
                          color: "#2D3748",
                          fontFamily: "Raleway",
                        }}
                        width="100"
                        dataField="id"
                        dataFormat={(cell, row) => getDateRegister(cell, row)}
                      >
                        Registration date
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{
                          fontWeight: 600,
                          fontSize: 12,
                          color: "#A0AEC0",
                          fontFamily: "Raleway",
                        }}
                        dataField="id"
                        width="100"
                        dataFormat={(cell, row) => actionFormatter(cell, row)}
                      >
                        Actions
                      </TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                )}
              </>
            ) : (
              <>
                {isPageLoading && <AdministratorSkeleton />}
                {!isPageLoading && (
                  <h2 className="activite-table-title-no-finish">
                    No administrator
                  </h2>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div
        className="modal fade modal-faq modal-faq-contact"
        id="updateAdminModal"
        aria-labelledby="updateAdminModalLabel"
        aria-hidden="true"
      >
        <ModalUpdateAdmin user={userData} />
      </div>
      <div
        className="modal fade modal-faq modal-faq-contact"
        id="showAdminModal"
        aria-labelledby="showAdminModalLabel"
        aria-hidden="true"
      >
        <ModalShowAdmin datas_user={userData} />
      </div>
    </div>
  );
};

export default Listeadmin;
