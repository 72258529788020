import React from "react";
import HeaderAdmin from "../headerAdmin/HeaderAdmin";
import NavbarVertical from "../nav/NavbarVertical";
import "./ReservationAdmin.css";
// import { Link } from "react-router-dom";
// import ListingTable from './ListingTable/ListingTable';
// import { Plus } from 'react-iconly';
import ReservationsTable from "./Reservations/ReservationsTable";

const Reservations: React.FC = () => {
  return (
    <div className="container-fluid body-background-dashboard">
      <HeaderAdmin />
      <div className="row">
        <div className="nav-container no-view-mobile">
          <NavbarVertical />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header mt-4 px-0">
          <div className="projets-container bloc-principal-body-content">
            <div className="profile-containers">
              <div className="container-profile-edit">
                <h1 className="mon-profil">
                  List of reservations
                  <br />
                  <p className="sold-this-month"></p>
                </h1>
              </div>
              <div className="users-table pt-2">
                <ReservationsTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reservations;
