import { Delete, EditTwoTone } from "@material-ui/icons/";
import Axios from "axios";
import React, { useEffect } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
// import DatasPropertyTypes from "../../../../repositories/user/propertyTypes/DatasPropertyTypes";
import Swal from "sweetalert2";
import api, { displayPriceType } from "../../../../Http/global-vars";
import { listProperties } from "../../../../redux/ListProperty/listPropertyAction";
import DatasListings from "../../../../repositories/user/listings/DatasListings";
import ListingProperty from "../../../../services/ListingProperty";
import { formatAmout } from "../../../../utils/convert_currency";
// import ListingData from "./ListingData";
import ListingSkeleton from "./ListingSkeleton";
import "./ListingTable.css";

const ListingTable = (props) => {
  const dispatch = useDispatch();
  const list_properties = useSelector(
    (state: RootStateOrAny) => state.list_properties
  );
  const reviews = useSelector((state: RootStateOrAny) => state.reviews);
  const reservations = useSelector(
    (state: RootStateOrAny) => state.reservations
  );
  const ameneties = useSelector((state: RootStateOrAny) => state.ameneties);

  const property_types = useSelector(
    (state: RootStateOrAny) => state.property_types
  );

  const [isPageLoading, setIsPageLoading] = React.useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsPageLoading(false);
    }, 3000);
  }, [list_properties]);

  const token = window.localStorage.getItem("userToken");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const options = {
    clearSearch: false,
    noDataText: "No data available",
  };

  const statutFormatter = (cell, row) => {
    return (
      <span className="name">
        <button
          type="button"
          onClick={() => updateStatut(row?.id)}
          className={
            cell === "sale"
              ? `btn projet-btn-nouveau text-uppercase cursor-pointer`
              : `btn projet-btn-nouveau-rouge text-uppercase cursor-pointer`
          }
        >
          {cell}
        </button>
      </span>
    );
  };

  const statutPublicationFormatter = (cell, row) => {
    return (
      <span className="name">
        <button
          type="button"
          onClick={() => updateStatutPublication(row?.id)}
          className={
            cell === "publier"
              ? `btn projet-btn-nouveau text-uppercase cursor-pointer`
              : `btn projet-btn-nouveau-rouge text-uppercase cursor-pointer`
          }
        >
          {cell === "publier"
            ? "Publish"
            : cell === "non_publier"
            ? "No Publish"
            : ""}
        </button>
      </span>
    );
  };

  const updateStatutPublication = async (id: number) => {
    const { value } = await Swal.fire({
      title: "Change status",
      input: "select",
      confirmButtonText: `SAVE`,
      confirmButtonColor: "#df5656",
      cancelButtonColor: "#6c757d",
      color: "#6c757d",
      inputOptions: {
        publier: "Publish",
        non_publier: "No Publish",
      },
      inputPlaceholder: "Select a status",
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value) {
            Swal.showLoading();
            const fd = new FormData();
            fd.set("statut_publication", value);

            const request = Axios.put(`${api}listing/` + id + "/", fd, config);
            request
              .then((res) => {
                dispatch(listProperties());
                Swal.fire({
                  icon: "success",
                  iconColor: "#df5656",
                  showCancelButton: false,
                  confirmButtonColor: "#df5656",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "OK",
                  title: "Status changed successfully.",
                  showConfirmButton: true,
                  allowOutsideClick: false,
                });
              })
              .catch((error) => {
                console.log("error", error?.response);
                Swal.fire({
                  icon: "error",
                  iconColor: "#df5656",
                  showCancelButton: false,
                  confirmButtonColor: "#df5656",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "OK",
                  title: "Please check the data entered.",
                  showConfirmButton: true,
                  allowOutsideClick: false,
                });
              });
          } else {
            resolve("Please select a status:)");
          }
        });
      },
    });
  };
  const updateStatut = async (id: number) => {
    const { value } = await Swal.fire({
      title: "Change status",
      input: "select",
      confirmButtonText: `SAVE`,
      confirmButtonColor: "#df5656",
      cancelButtonColor: "#6c757d",
      color: "#6c757d",
      inputOptions: {
        rent: "Rent",
        sale: "Sale",
      },
      inputPlaceholder: "Select a status",
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value) {
            Swal.showLoading();
            const fd = new FormData();
            fd.set("statut_listing", value);

            const request = Axios.put(`${api}listing/` + id + "/", fd, config);
            request
              .then((res) => {
                dispatch(listProperties());
                Swal.fire({
                  icon: "success",
                  iconColor: "#df5656",
                  showCancelButton: false,
                  confirmButtonColor: "#df5656",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "OK",
                  title: "Status changed successfully.",
                  showConfirmButton: true,
                  allowOutsideClick: false,
                });
              })
              .catch((error) => {
                console.log("error", error?.response);
                Swal.fire({
                  icon: "error",
                  iconColor: "#df5656",
                  showCancelButton: false,
                  confirmButtonColor: "#df5656",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "OK",
                  title: "Please check the data entered.",
                  showConfirmButton: true,
                  allowOutsideClick: false,
                });
              });
          } else {
            resolve("Please select a status:)");
          }
        });
      },
    });
  };

  const onMiseAvant = (item: DatasListings) => {
    Swal.fire({
      title: `Do you want ${
        item?.en_avant ? "to unpromote" : "to promote"
      } this property ?`,
      // showDenyButton: true,
      confirmButtonText: `YES`,
      cancelButtonText: `NO`,
      iconColor: "#df5656",
      showCancelButton: true,
      confirmButtonColor: "#df5656",
      cancelButtonColor: "#6c757d",
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: () => {
        const fd = new FormData();
        const en_avant = item.en_avant ? "false" : "true";
        fd.append("en_avant", en_avant);

        let addRequest;
        if (item.en_avant) {
          addRequest = ListingProperty.onEditProperties(item?.id, fd, config);
        } else {
          addRequest = ListingProperty.onAvantPropretie(
            item?.id,
            "property",
            config
          );
        }
        return addRequest
          .then(async (response) => {
            await dispatch(listProperties());
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `${
            !item?.en_avant
              ? "property successfully promoted !"
              : "property unpromoted !"
          }`,
          showConfirmButton: true,
          iconColor: "#df5656",
          confirmButtonColor: "#df5656",
          allowOutsideClick: false,
        });
      }
    });
  };

  const onFeatured = (item: DatasListings) => {
    Swal.fire({
      title: `Do you want ${
        item?.is_featured ? "to delete the featured of" : "to feature"
      } this property ?`,
      // showDenyButton: true,
      confirmButtonText: `YES`,
      cancelButtonText: `NO`,
      iconColor: "#df5656",
      showCancelButton: true,
      confirmButtonColor: "#df5656",
      cancelButtonColor: "#6c757d",
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: () => {
        const fd = new FormData();
        const is_featured = item.is_featured ? "false" : "true";
        fd.append("is_featured", is_featured);
        const addRequest = ListingProperty.onEditProperties(
          item?.id,
          fd,
          config
        );
        return addRequest
          .then(async (response) => {
            await dispatch(listProperties());
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `${
            !item?.en_avant
              ? "Property successfully featured !"
              : "Featured of this property successfully deleted !"
          }`,
          showConfirmButton: true,
          iconColor: "#df5656",
          confirmButtonColor: "#df5656",
          allowOutsideClick: false,
        });
      }
    });
  };

  const propertyTypeFormatter = (cell, row) => {
    return cell?.name;
  };

  const actionFormatter = (cell, row) => {
    // let countReviewProperty =
    //   reviews.data && reviews.data.filter((x) => x.property_id === row.id);
    return (
      <div className="table-actions-container justify-content-right">
        {row?.statut_publication === "publier" ? (
          <>
            <NavLink
              to="#"
              title="Highlight this property "
              style={{ color: row?.is_featured ? "#9A160A" : "#A9A9A9" }}
              className="mr-2"
              onClick={() => onFeatured(row)}
            >
              <IconlyPack.Heart set="bold" />
            </NavLink>

            <NavLink
              to="#"
              title="Promote this property "
              style={{ color: row?.en_avant ? "#9A160A" : "#A9A9A9" }}
              className="mr-2"
              onClick={() => onMiseAvant(row)}
            >
              <IconlyPack.Swap set="bold" />
            </NavLink>
          </>
        ) : null}

        <NavLink
          // to={`/admin/details-listing/${}`}
          to={{
            pathname: `/admin/details-property/${row?.slug}`,
            state: { row, property_types, reviews, reservations, ameneties },
          }}
          style={{ color: "#A9A9A9" }}
          className="mr-2"
          title="View details "
        >
          <IconlyPack.Show set="bold" />
        </NavLink>
        {/* <NavLink
          to="#"
          style={{ color: "#A9A9A9" }}
          className="mr-2 number-chat-link"
        >
          <span className="number-chat">
            {countReviewProperty && countReviewProperty.length}
          </span>
          <IconlyPack.Chat set="bold" />
        </NavLink> */}
        <NavLink
          to={{
            pathname: `/admin/modifier-listing/${row.id}`,
            state: row,
          }}
          style={{ color: "#2D3748" }}
          className="mr-2"
          title="Edit this property"
        >
          <EditTwoTone style={{ fontSize: "20px" }} />
        </NavLink>
        <NavLink
          to="#"
          style={{ color: "#E53E3E" }}
          className=""
          onClick={() => onDelete(row.id)}
          title="Delete this property"
        >
          <Delete style={{ fontSize: "20px" }} />
        </NavLink>
      </div>
    );
  };
  const onDelete = (id: DatasListings) => {
    Swal.fire({
      title: "Do you want to delete this property ?",
      // showDenyButton: true,
      confirmButtonText: `YES`,
      cancelButtonText: `NO`,
      iconColor: "#df5656",
      showCancelButton: true,
      confirmButtonColor: "#df5656",
      cancelButtonColor: "#6c757d",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const addRequest = ListingProperty.onDeleteProperties(id, config);
        addRequest
          .then((response) => {
            dispatch(listProperties());
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Property deleted successfully",
              showConfirmButton: true,
              iconColor: "#df5656",
              confirmButtonColor: "#df5656",
              allowOutsideClick: false,
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Oops! An error occurred while deleting",
              showConfirmButton: true,
              iconColor: "#df5656",
              confirmButtonColor: "#df5656",
              allowOutsideClick: false,
            });
          });
      } else {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Deletion was successfully undone",
          showConfirmButton: true,
          iconColor: "#df5656",
          confirmButtonColor: "#df5656",
          allowOutsideClick: false,
        });
      }
    });
  };

  const locationFormat = (cell, row) => {
    return cell?.name;
  };

  const status = {
    rent: "rent",
    sale: "sale",
  };

  const status_publication = {
    publier: "Publish",
    non_publier: "Not publish",
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container-activite">
        {/* Filtres */}
        {/* <div className="filtres-container pb-4">
					<h6 className="filter-label">Filter</h6>
					<div className="filter-input-group">
						<input
							type="text"
							className="filter-input"
							placeholder="Entrer un nom"
						/>
					</div>
				</div> */}
        <div className="row easypm-table-row">
          <div className="col-md-12 easypm-table-col">
            {list_properties?.isLoading && <ListingSkeleton />}
            {!list_properties?.isLoading && (
              <div className="table-container-activite table-responsive">
                <BootstrapTable
                  data={list_properties?.data}
                  hover={true}
                  condensed={false}
                  multiColumnSort={2}
                  options={options}
                  search={false}
                  version="4"
                  bordered={false}
                  striped={true}
                  pagination
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Raleway",
                    }}
                    tdStyle={{
                      fontWeight: "normal",
                      fontSize: 14,
                      color: "#2D3748",
                      textTransform: "capitalize",
                    }}
                    width="150"
                    dataField="property_name"
                    isKey={true}
                    filter={{ type: "TextFilter" }}
                  >
                    Property Name
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Raleway",
                    }}
                    tdStyle={{
                      fontWeight: "normal",
                      fontSize: 14,
                      color: "#2D3748",
                      fontFamily: "Raleway",
                    }}
                    width="150"
                    dataField="location_id"
                    filter={{ type: "TextFilter" }}
                    filterValue={locationFormat}
                    dataFormat={(cell, row) => locationFormat(cell, row)}
                  >
                    Location
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Raleway",
                    }}
                    tdStyle={{
                      fontWeight: "normal",
                      fontSize: 14,
                      color: "#2D3748",
                      fontFamily: "Raleway",
                    }}
                    width="100"
                    dataField="property_type"
                    filter={{ type: "TextFilter" }}
                    filterValue={propertyTypeFormatter}
                    dataFormat={(cell, row) => propertyTypeFormatter(cell, row)}
                  >
                    Type
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Raleway",
                    }}
                    tdStyle={{
                      fontWeight: "normal",
                      fontSize: 12,
                      color: "#2D3748",
                      fontFamily: "Raleway",
                    }}
                    width="150"
                    dataField="price"
                    dataFormat={(cell, row) =>
                      `${formatAmout(row.price)}${displayPriceType(
                        row.type_price
                      )}`
                    }
                    filter={{ type: "TextFilter" }}
                  >
                    Amount requested
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Raleway",
                    }}
                    tdStyle={{
                      fontWeight: "normal",
                      fontSize: 14,
                      color: "#2D3748",
                      fontFamily: "Raleway",
                    }}
                    width="100"
                    dataField="statut_listing"
                    dataFormat={(cell, row) => statutFormatter(cell, row)}
                    filter={{ type: "SelectFilter", options: status }}
                  >
                    Statut
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Raleway",
                    }}
                    tdStyle={{
                      fontWeight: "normal",
                      fontSize: 14,
                      color: "#2D3748",
                      fontFamily: "Raleway",
                    }}
                    width="100"
                    dataField="statut_publication"
                    dataFormat={(cell, row) =>
                      statutPublicationFormatter(cell, row)
                    }
                    filter={{
                      type: "SelectFilter",
                      options: status_publication,
                      condition: "eq",
                    }}
                  >
                    Statut Publication
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: "#A0AEC0",
                      fontFamily: "Raleway",
                    }}
                    dataAlign="center"
                    dataField="id"
                    width="200"
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Actions
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingTable;
