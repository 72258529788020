import api from "../../Http/global-vars";
import { FETCH_ADD_CONTACT_LIST, FETCH_CONTACT_LIST } from "../types";

export const contactList = () => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const url = api + "contacts/?limit=1000";
    await fetch(url, config)
      .then(async (res) => {
        const data = await res.json();
        // console.log("data categories", data)
        dispatch({
          type: FETCH_CONTACT_LIST,
          payload: data.results?.reverse(),
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};

export const addContact = (id: number) => {
  return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const token = window.localStorage.getItem("userToken");
    const url = api + "contact/" + id;
    if (token) {
      await fetch(url, config)
        .then(async (res) => {
          const data = await res.json();
          // console.log("data results", data.results)
          dispatch({
            type: FETCH_ADD_CONTACT_LIST,
            payload: data.results,
            // ,
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  };
};

// export const updateArticle = (id: number) => {

//     return async (dispatch: (arg0: { type: string; payload: any }) => void) => {
//       const config = {
//         headers: {
//           Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
//         },
//       };
//       let token = window.localStorage.getItem("userToken");
//       const url = api + "contact/" + id;
//       if (token) {
//         await fetch(url, config)
//           .then(async (res) => {
//             const data = await res.json();
//             // console.log("data results", data.results)
//             dispatch({
//               type: FETCH_UPDATE_ARTICLE_LIST,
//               payload: data.results
//                 // ,
//             });
//           })
//           .catch((error) => console.log("error", error?.response));
//         }
//     };
// }
