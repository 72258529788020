import React, { useEffect, useState } from "react";
import { Chat, User } from "react-iconly";
import { RootStateOrAny, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
	FacebookIcon,
	FacebookShareButton,
	TwitterIcon,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton,
} from "react-share";
import baseUrl from "../../../Http/backend-base-url";
import CategoryInterface from "../../../repositories/user/blog/CategoryInterface";
import { getUrl } from "../../../utils/utils";
import FrontFooter from "../../frontFooter/FrontFooter";
import Header from "../../header/Header";
import { useScrollToTop } from "../projets/DetailsProjet";
import PlayerVideo from "../shared/PlayerVideo";
import AuthorBlogCard from "./AuthorBlogCard";
import { Categoriesata } from "./BlogData";
import CategoriesPost from "./CategoriesPost";
import Comments from "./Comments";
import FormAddComment from "./FormAddComment";
import TrendingPosts from "./TrendingPosts";

const iconStyle = {
	height: "18px",
	width: "18px",
	marginRight: "5px",
};

const DetailBlog: React.FC = () => {
	useScrollToTop();

	const reviews = useSelector((state: RootStateOrAny) => state.reviews);
	const trendingPosts = useSelector((state: RootStateOrAny) => state.articles_front);
	const [categories, setCategories] = useState<CategoryInterface[]>([]);
	const location = useLocation<any>();
	const article = location.state?.article;
	const [videoUrl, setVideoUrl] = useState<string>();

	useEffect(() => {
		setCategories(Categoriesata);
	}, []);

	useEffect(() => {
		if (article?.content) {
			const url = getUrl(article.content);
			console.log({ url });
			setVideoUrl(url);
		}
	}, [article]);

	return (
		<div className="component-front-home">
			<Header />
			<div className="p-t-150 m-b-100 container-page-about-home">
				<div className="container-taf">
					<div className="row mb-4 no-view-desktop filter-by-category-container">
						<div className="container-category-filtre col-12 ">
							<div className="collapse" id="categoryFilterCollapse">
								<CategoriesPost categories={article} />
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-8">
							<div className="card card-blog">
								<img
									height="330"
									width="811"
									src={
										article?.image === null
											? `https://ui-avatars.com/api/?name=${article?.title}`
											: `${baseUrl + article?.image}`
									}
									alt="avatar"
									className="card-img-top card-blog-img object-cover mb-4"
								/>
								<div className="card-body pt-0 px-4">
									<div className="card-statistique d-flex">
										<div className="author-container d-flex mr-3">
											<User
												set="light"
												style={iconStyle}
											/>
											<h6 className="card-statistique-text">
												By{" "}
												{article?.created_by
													?.fullname ?? ""}
											</h6>
										</div>
										<div className="comments-container d-flex">
											<Chat
												set="light"
												style={iconStyle}
											/>
											<h6 className="card-statistique-text">
												{reviews?.data.filter(
													(x: any) =>
														x?.article ===
														article?.id
												)?.length ?? 0}
												comments
											</h6>
										</div>
									</div>
									<h5 className="card-title text-light-dark card-blog-title my-4">
										{article?.title ?? ""}
									</h5>
									<div className="card-text-container article-content">
										<p
											className="card-text card-blog-text text-gray"
											dangerouslySetInnerHTML={{
												__html: article?.content,
											}}
										/>
									</div>
									{videoUrl && (
										<div className="video-player-container">
											<PlayerVideo
												url={videoUrl}
												showControls={false}
											/>
										</div>
									)}
									<div className="blog-social-icons-container d-flex mt-5">
										<h6 className="blog-social-card-title">
											Social share:
										</h6>
										<FacebookShareButton
											url={window.location.href}
											quote={"TAF"}
											about={article.title}
											hashtag={"#TAF"}
											className="blog-social-link"
										>
											<FacebookIcon size={20} round />
										</FacebookShareButton>

										<TwitterShareButton
											url={window.location.href}
											className="blog-social-link"
											about={article.title}
										>
											<TwitterIcon size={20} round />
										</TwitterShareButton>
										<WhatsappShareButton
											url={window.location.href}
											about={article.title}
											className="blog-social-link"
										>
											<WhatsappIcon size={20} round />
										</WhatsappShareButton>
									</div>
								</div>
							</div>
							{/* Author blog card */}
							<AuthorBlogCard
								authorBlogCardData={article?.created_by}
							/>
							{/* List Comments */}
							<div className="no-view-mobile">
								<Comments
									comments={reviews}
									article={article?.id}
								/>
							</div>
							{/* Form Add comment */}
							<FormAddComment article={article} />
						</div>
						<div className="col-md-4">
							{/* Categories Posts */}
							<CategoriesPost categories={categories} />
							{/* Trending Posts */}
							<TrendingPosts trendingPosts={trendingPosts} />
						</div>
					</div>
				</div>
			</div>
			<FrontFooter />
		</div>
	);
};;;

export default DetailBlog;
