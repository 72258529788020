import React from "react";
// import Dropzone from 'react-dropzone-uploader';
// import groupphoto from '../../../images/Groupphoto.png'
import { AiOutlinePlusCircle } from "react-icons/ai";
import Aboutpersonnalisation from "./AboutPersonnalisarionTable";
import MeetourteamTable from "./MeetourteamTable";
import Modalaboutpersonnalisation, {
  ContentField,
} from "./ModalParametre/ModalAboutPersonnalisation";
import ModalAddMember from "./ModalParametre/ModalAddMember";
import ModalTimeLinePersonnalisation from "./ModalParametre/ModalTimeLinePersonnalisation";
import Timelinepersonnalisation from "./TimelinePersonnalisarionTable";

const Personnalisation: React.FC = () => {
  const [typeForm, setTypeForm] = React.useState("");

  return (
    <div className="profile-containers">
      <form className="form-input-parametre">
        <div className="row">
          <div className="col">
            <div className="container-profile-edit-reglage d-flex justify-content-between mt-2 mb-2">
              <h1 className="meet-team-title">About us</h1>
              <button
                type="button"
                className="btn btn-add-amin-setting"
                data-toggle="modal"
                data-target="#AddAboutModal"
                onClick={() => setTypeForm("create")}
              >
                <AiOutlinePlusCircle className="btn-addadmin-icon" />
                <span className="">Add about</span>
              </button>
            </div>
            <Aboutpersonnalisation />
            <div
              className="modal fade modal-faq modal-faq-contact"
              id="AddAboutModal"
              aria-labelledby="updateLocationModalLabel"
              aria-hidden="true"
            >
              <Modalaboutpersonnalisation type={typeForm} />
            </div>
          </div>
        </div>
        <ContentField
          fieldName={"taf_africa_global"}
          title={"Taf africa description"}
        />
        <div className="row">
          <div className="col">
            <div className="container-profile-edit-reglage d-flex justify-content-between mt-2 mb-2">
              <h1 className="meet-team-title">Timelines</h1>
              <button
                type="button"
                className="btn btn-add-amin-setting"
                data-toggle="modal"
                data-target="#AddTimelineModal"
              >
                <AiOutlinePlusCircle className="btn-addadmin-icon" />
                <span className="">Add Timeline</span>
              </button>
            </div>
            <Timelinepersonnalisation />
            <div
              className="modal fade modal-faq modal-faq-contact"
              id="AddTimelineModal"
              aria-labelledby="updateLocationModalLabel"
              aria-hidden="true"
            >
              <ModalTimeLinePersonnalisation type={"create"} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="container-profile-edit-reglage d-flex justify-content-between mt-2 mb-2">
              <h1 className="meet-team-title">Meet our team</h1>
              <button
                type="button"
                className="btn btn-add-amin-setting"
                data-toggle="modal"
                data-target="#addmemberModal"
              >
                <AiOutlinePlusCircle className="btn-addadmin-icon" />
                <span className="">Add a member</span>
              </button>
            </div>
            <MeetourteamTable />
          </div>
        </div>
        <ContentField
          fieldName={"taf_fundation"}
          title={"TAF AFRICA FOUNDATION"}
        />

        <ContentField fieldName={"estate_agency"} title={"ESTATE AGENCY"} />
        <ContentField
          fieldName={"real_estate_rentals"}
          title={"REAL ESTATE RENTALS"}
        />
        <ContentField fieldName={"mortgage"} title={"MORTGAGE"} />
        <ContentField
          fieldName={"public_private_partnership"}
          title={"PUBLIC–PRIVATE PARTNERSHIP"}
        />
      </form>

      <div
        className="modal fade modal-faq modal-faq-contact"
        id="addmemberModal"
        aria-labelledby="addAdminModalLabel"
        aria-hidden="true"
      >
        <ModalAddMember />
      </div>
    </div>
  );
};

export default Personnalisation;
